<template>
    <div ref="scrollTarget"></div>

    <MainNav />
    <section class="laptop-section">
        <section class="background-container" style="padding-bottom: 20px; height: 300px; margin-top: 70px"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%; padding-left: 150px;">
                <div class="row gx-5">
                    <div class="col-md-6">
                        <h1 style="color: #E5097F; font-weight: bold; font-size: 1.5em;"><img
                                src="../assets/img/reviewIcon.svg"> {{ translate('give_review') }}</h1>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 10px;">
            <div class="container" style="margin-bottom: 30px;">
                <div class="row justify-content-center">
                    <div class="col-7"
                        style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{ translate('reviews')
                                }} </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star"></i> <span
                                    style="color: #E5097F; font-weight: 600; font-size: 23px;">{{ reviews_star_totals
                                    }}</span>
                            </span>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> </span>
                            <span style="color: #FFAC00; font-size: 18px; cursor: pointer;">
                                <span style="color: #E5097F;">{{ reviews_totals }} totale</span>
                            </span>
                        </div>
                        <div style="margin-top: 20px;">
                            <div v-for="stars in sortedStars" :key="stars"
                                style="display: flex; align-items: center; margin-bottom: 10px;">
                                <span style="width: 70px; color: #E5097F; font-size: 18px; font-weight: 400;">{{ stars
                                    }} {{ translate('stars') }}</span>
                                <div
                                    style="flex-grow: 1; background-color: white; height: 10px; margin: 0 10px; position: relative; border-radius: 20px;">
                                    <div style="border-radius: 20px;"
                                        :style="{ width: getPercentage(starCounts[stars]) + '%', backgroundColor: '#FFAC00', height: '100%' }">
                                    </div>
                                </div>
                                <span style="color: #E5097F; font-size: 18px; font-weight: 400;">{{ starCounts[stars]
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 10px;">
                <div class="row justify-content-center">
                    <div class="col-7"
                        style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                translate('write_review') }} </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <input style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                :placeholder="translate('nom')" v-model="name" />
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <textarea style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                        </div>

                        <div style="display: flex; justify-content: flex-end;">
                            <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                translate('give_review') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                {{ translate('clients_reviews_text1') }}<br>
                {{ translate('clients_reviews_text2') }}
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                <div class="row justify-content-center">
                    <div class="col-7"
                        style="text-align: center; background-color: #FCF5D1; padding: 30px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                    src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                            </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star"></i>
                                </template>
                                <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star-o"></i>
                                </template>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{ review.comment
                                }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <section class="background-container" style="padding-bottom: 20px; height: 300px; margin-top: 70px"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%; padding-left: 50px;">
                <div class="row gx-5">
                    <div class="col-md-6">
                        <h1 style="color: #E5097F; font-weight: bold; font-size: 1.5em;"><img
                                src="../assets/img/reviewIcon.svg"> {{ translate('give_review') }}</h1>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 10px;">
            <div class="container" style="margin-bottom: 30px;">
                <div class="row justify-content-center">
                    <div class="col-11"
                        style="text-align: center; background-color: #FFE3F2; padding: 20px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{ translate('reviews')
                                }}
                            </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star"></i> <span
                                    style="color: #E5097F; font-weight: 600; font-size: 23px;">{{ reviews_star_totals
                                    }}</span>
                            </span>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> </span>
                            <span style="color: #FFAC00; font-size: 18px; cursor: pointer;">
                                <span style="color: #E5097F;">{{ reviews_totals }} totale</span>
                            </span>
                        </div>
                        <div style="margin-top: 20px;">
                            <div v-for="stars in sortedStars" :key="stars"
                                style="display: flex; align-items: center; margin-bottom: 10px;">
                                <span style="width: 70px; color: #E5097F; font-size: 18px; font-weight: 400;">{{ stars
                                    }} {{
                                    translate('stars') }}</span>
                                <div
                                    style="flex-grow: 1; background-color: white; height: 10px; margin: 0 10px; position: relative; border-radius: 20px;">
                                    <div style="border-radius: 20px;"
                                        :style="{ width: getPercentage(starCounts[stars]) + '%', backgroundColor: '#FFAC00', height: '100%' }">
                                    </div>
                                </div>
                                <span style="color: #E5097F; font-size: 18px; font-weight: 400;">{{ starCounts[stars]
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 10px;">
                <div class="row justify-content-center">
                    <div class="col-11"
                        style="text-align: center; background-color: #FFE3F2; padding: 20px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                translate('write_review')
                                }} </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <input style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                :placeholder="translate('nom')" v-model="name" />
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <textarea style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                        </div>

                        <div style="display: flex; justify-content: flex-end;">
                            <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                translate('give_review') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                {{ translate('clients_reviews_text1') }}<br>
                {{ translate('clients_reviews_text2') }}
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                <div class="row justify-content-center">
                    <div class="col-11"
                        style="text-align: center; background-color: #FCF5D1; padding: 20px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                    src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                            </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star"></i>
                                </template>
                                <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star-o"></i>
                                </template>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{ review.comment
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </section>

    <section class="ipad-section">
        <section class="background-container" style="padding-bottom: 20px; height: 300px; margin-top: 70px"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%; padding-left: 150px;">
                <div class="row gx-5">
                    <div class="col-md-6">
                        <h1 style="color: #E5097F; font-weight: bold; font-size: 1.5em;"><img
                                src="../assets/img/reviewIcon.svg"> {{ translate('give_review') }}</h1>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 10px;">
            <div class="container" style="margin-bottom: 30px;">
                <div class="row justify-content-center">
                    <div class="col-10"
                        style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{ translate('reviews')
                                }}
                            </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star"></i> <span
                                    style="color: #E5097F; font-weight: 600; font-size: 23px;">{{ reviews_star_totals
                                    }}</span>
                            </span>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> </span>
                            <span style="color: #FFAC00; font-size: 18px; cursor: pointer;">
                                <span style="color: #E5097F;">{{ reviews_totals }} totale</span>
                            </span>
                        </div>
                        <div style="margin-top: 20px;">
                            <div v-for="stars in sortedStars" :key="stars"
                                style="display: flex; align-items: center; margin-bottom: 10px;">
                                <span style="width: 70px; color: #E5097F; font-size: 18px; font-weight: 400;">{{ stars
                                    }} {{
                                    translate('stars') }}</span>
                                <div
                                    style="flex-grow: 1; background-color: white; height: 10px; margin: 0 10px; position: relative; border-radius: 20px;">
                                    <div style="border-radius: 20px;"
                                        :style="{ width: getPercentage(starCounts[stars]) + '%', backgroundColor: '#FFAC00', height: '100%' }">
                                    </div>
                                </div>
                                <span style="color: #E5097F; font-size: 18px; font-weight: 400;">{{ starCounts[stars]
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 10px;">
                <div class="row justify-content-center">
                    <div class="col-10"
                        style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                translate('write_review')
                                }} </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <input style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                :placeholder="translate('nom')" v-model="name" />
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label"></label>
                            <textarea style="border-radius: 15px;" class="form-control"
                                :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                        </div>

                        <div style="display: flex; justify-content: flex-end;">
                            <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                translate('give_review') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                {{ translate('clients_reviews_text1') }}<br>
                {{ translate('clients_reviews_text2') }}
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                <div class="row justify-content-center">
                    <div class="col-10"
                        style="text-align: center; background-color: #FCF5D1; padding: 30px; border-radius: 20px;">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                    src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                            </span>
                            <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star"></i>
                                </template>
                                <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                    <i class="fa fa-star-o"></i>
                                </template>
                            </span>
                        </div>

                        <div class="form-group mb-3">
                            <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{ review.comment
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'bulma-toast';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/give_review_header.jpg';
import BackgroundImage2 from '../assets/img/give_review_header.jpg';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            stars_number: 0,
            feedback: "",
            name: "",
            reviews_star_totals: "",
            reviews_totals: "",
            starCounts: {},
            totalReviews: 30,
            reviews: []

        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
        sortedStars() {
            return Object.keys(this.starCounts).sort((a, b) => b - a);
        }
    },
    mounted() {
        document.title = 'Eurekali | Reviews';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.getReviewsStat()
        this.getreviews()
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        showAlertSuccess() {
            Swal.fire({
                title: this.translate('thanks_feedback'),
                text: "",
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#4BB543',
            }).then(() => {
                // Redirect to /new_order
                //this.$router.push('/new_order/');
                this.feedback = "",
                    this.name = "",
                    this.stars_number = 0
            });
        },
        async sendfeedback() {
            if (!this.name) {
                toast({
                    message: this.translate('give_review_alert1'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                if (this.stars_number == 0) {
                    toast({
                        message: this.translate('give_review_alert2'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-right',
                    })
                } else {
                    if (this.feedback) {
                        this.$store.commit('setIsLoading', true)

                        const data = {
                            "name": this.name,
                            "comment": this.feedback,
                            "stars_number": this.stars_number
                        }
                        await axios.post(`/api/v1/create-user-review/`, data)
                            .then(response => {
                                this.showAlertSuccess()
                            })
                            .catch(error => {
                                console.log(error)
                            })

                        this.$store.commit('setIsLoading', false)
                    } else {
                        toast({
                            message: this.translate('give_feedback'),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    }
                }
            }
        },
        async getreviews() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/get-reviews/')
                .then(response => {
                    this.reviews = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getReviewsStat() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/get-reviews-stat/')
                .then(response => {
                    this.reviews_star_totals = response.data['avg_stars']
                    this.reviews_totals = response.data['total_reviews']
                    this.starCounts = {
                        5: response.data['total_starts5'],
                        4: response.data['total_starts4'],
                        3: response.data['total_starts3'],
                        2: response.data['total_starts2'],
                        1: response.data['total_starts1']
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        set_stars_number_1() {
            this.stars_number = 1
        },
        set_stars_number_2() {
            this.stars_number = 2
        },
        set_stars_number_3() {
            this.stars_number = 3
        },
        set_stars_number_4() {
            this.stars_number = 4
        },
        set_stars_number_5() {
            this.stars_number = 5
        },
        getPercentage(count) {
            return (count / this.totalReviews) * 100;
        }
    }
}
</script>

<style>
.btn-send-review {
    color: white;
    background-color: #E5097F;
    font-weight: bold;
    border-radius: 20px;
}

.btn-send-review:hover {
    background-color: #9B0656;
    color: white;
}
</style>