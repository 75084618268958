<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section
        :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
        <template v-if="complete">
            <div
                class="container d-flex flex-column justify-content-center align-items-center  vh-100 text-center text-md-start">
                <p style="font-weight: 600; font-size: 2.5em; color: black; text-align: center;">{{
                    translate('thanks_message1') }}</p>
                <p style="font-weight: 600; font-size: 1.3em; color: black; text-align: center;">{{
                    translate('thanks_message2') }}</p>
            </div>
        </template>

        <template v-else>
            <div class="container d-flex flex-column justify-content-center  vh-100 text-center text-md-start">
                <template v-if="!question1">
                    <div class="question-box">
                        <p>1. {{ translate('question1') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <p class="choice-item" @click="setQuestion1(translate('question1_choice1'))">{{
                                translate('question1_choice1') }}</p>
                            <p class="choice-item" @click="setQuestion1(translate('question1_choice2'))">{{
                                translate('question1_choice2') }}</p>
                            <p class="choice-item" @click="setQuestion1(translate('question1_choice3'))">{{
                                translate('question1_choice3') }}</p>
                            <p class="choice-item" @click="setQuestion1(translate('question1_choice4'))">{{
                                translate('question1_choice4') }}</p>
                        </div>
                    </div>
                </template>

                <template v-if="!question2_yes && question1">
                    <div class="question-box">
                        <p>2. {{ translate('question2') }}</p>
                        <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                            <input type="number" class="form-control" :placeholder="translate('repondez_ici')"
                                v-model="question2">
                            <template v-if="question2">
                                <button class="active-next" @click="changeQuestion6">{{ translate('suivant') }}</button>
                            </template>
                            <template v-else>
                                <button class="deactivate-next">{{ translate('suivant') }}</button>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="!question3 && question2_yes">
                    <div class="question-box">
                        <p>3. {{ translate('question3') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <p class="choice-item" @click="setQuestion3(translate('question3_choice1'))">{{
                                translate('question3_choice1') }}</p>
                            <p class="choice-item" @click="setQuestion3(translate('question3_choice2'))">{{
                                translate('question3_choice2') }}</p>
                            <p class="choice-item" @click="setQuestion3(translate('question3_choice3'))">{{
                                translate('question3_choice3') }}</p>
                        </div>
                    </div>
                </template>

                <template v-if="!question4 && question3">
                    <div class="question-box">
                        <p>4. {{ translate('question4') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <div class="form-check custom-from">
                                <input class="form-check-input" type="checkbox" value="" id="question4_choice1"
                                    v-model="question4_choice1">
                                <label class="form-check-label" for="question4_choice1">
                                    {{ translate('question4_choice1') }}
                                </label>
                            </div>
                            <div class="form-check custom-from">
                                <input class="form-check-input" type="checkbox" value="" id="question4_choice2"
                                    v-model="question4_choice2">
                                <label class="form-check-label" for="question4_choice2">
                                    {{ translate('question4_choice2') }}
                                </label>
                            </div>
                            <div class="form-check custom-from">
                                <input class="form-check-input" type="checkbox" value="" id="question4_choice3"
                                    v-model="question4_choice3">
                                <label class="form-check-label" for="question4_choice3">
                                    {{ translate('question4_choice3') }}
                                </label>
                            </div>
                            <div class="form-check custom-from">
                                <input class="form-check-input" type="checkbox" value="" id="question4_choice4"
                                    v-model="question4_choice4">
                                <label class="form-check-label" for="question4_choice4">
                                    {{ translate('question4_choice4') }}
                                </label>
                            </div>
                        </div>
                        <template
                            v-if="question4_choice1 || question4_choice2 || question4_choice3 || question4_choice4">
                            <button class="active-next" @click="setQuestion4">{{ translate('suivant') }}</button>
                        </template>
                        <template v-else>
                            <button class="deactivate-next">{{ translate('suivant') }}</button>
                        </template>

                    </div>
                </template>

                <template v-if="!question5 && question4">
                    <div class="question-box">
                        <p>5. {{ translate('question5') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <p class="choice-item" @click="setQuestion5(translate('question5_choice1'))">{{
                                translate('question5_choice1') }}</p>
                            <p class="choice-item" @click="setQuestion5(translate('question5_choice2'))">{{
                                translate('question5_choice2') }}</p>
                        </div>
                    </div>
                </template>

                <template v-if="!question6 && question5">
                    <div class="question-box">
                        <p>6. {{ translate('question6') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <p class="choice-item" @click="setQuestion6(translate('question6_choice1'))">{{
                                translate('question6_choice1') }}</p>
                            <p class="choice-item" @click="setQuestion6(translate('question6_choice2'))">{{
                                translate('question6_choice2') }}</p>
                            <p class="choice-item" @click="setQuestion6(translate('question6_choice3'))">{{
                                translate('question6_choice3') }}</p>
                        </div>
                    </div>
                </template>

                <template v-if="!question7 && question6">
                    <div class="question-box">
                        <p>7. {{ translate('question7') }}</p>
                        <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                            <p class="choice-item" @click="setQuestion7(translate('question7_choice1'))">{{
                                translate('question7_choice1') }}</p>
                            <p class="choice-item" @click="setQuestion7(translate('question7_choice2'))">{{
                                translate('question7_choice2') }}</p>
                        </div>
                    </div>
                </template>

                <template v-if="!question8_yes && question7">
                    <div class="question-box">
                        <p>8. {{ translate('question8') }}</p>
                        <template v-if="non_transporter">
                            <div class="col-lg-3" style="margin-top: 8px; margin-left: 20px;">
                                <p class="choice-item" @click="changeStatut()">{{ translate('question8_choice1') }}</p>
                                <p class="choice-item" @click="setQuestion8(translate('question8_choice2'))">{{
                                    translate('question8_choice2') }}</p>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                                <input type="text" class="form-control" :placeholder="translate('repondez_ici')"
                                    v-model="question8">
                                <template v-if="question8">
                                    <button class="active-next" @click="changeQuestion8">{{ translate('suivant')
                                        }}</button>
                                </template>
                                <template v-else>
                                    <button class="deactivate-next">{{ translate('suivant') }}</button>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>

                <template v-if="!company_name_yes && question8_yes">
                    <div class="question-box">
                        <p>9. {{ translate('nom_entreprise') }}</p>
                        <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                            <input type="text" class="form-control" :placeholder="translate('repondez_ici')"
                                v-model="company_name">
                            <template v-if="company_name">
                                <button class="active-next" @click="changeQuestion1">{{ translate('suivant') }}</button>
                            </template>
                            <template v-else>
                                <button class="deactivate-next">{{ translate('suivant') }}</button>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="!contact_principale_yes && company_name_yes">
                    <div class="question-box">
                        <p>10. {{ translate('contact_principale') }}</p>
                        <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                            <input type="text" class="form-control" :placeholder="translate('repondez_ici')"
                                v-model="contact_principale">
                            <template v-if="contact_principale">
                                <button class="active-next" @click="changeQuestion3">{{ translate('suivant') }}</button>
                            </template>
                            <template v-else>
                                <button class="deactivate-next">{{ translate('suivant') }}</button>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="!address_email_yes && contact_principale_yes">
                    <div class="question-box">
                        <p>11. {{ translate('address_email') }}</p>
                        <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                            <input type="email" class="form-control" placeholder="example@email.com"
                                v-model="address_email">
                            <template v-if="address_email">
                                <button class="active-next" @click="changeQuestion4">{{ translate('suivant') }}</button>
                            </template>
                            <template v-else>
                                <button class="deactivate-next">{{ translate('suivant') }}</button>
                            </template>
                        </div>
                    </div>
                </template>


                <div class="question-box"
                    :class="{ 'show-class': !phone_number_yes && address_email_yes, 'not-show-class': !(!phone_number_yes && address_email_yes) }">
                    <p>12. {{ translate('phone_number') }}</p>
                    <div class="col-md-4" style="margin-top: 8px; margin-left: 20px;">
                        <div style="font-size: medium;">
                            <input type="tel" ref="phoneInput" class="form-control" placeholder="02 808 15 44"
                                v-model="phone_number">
                        </div>
                        <template v-if="phone_number">
                            <button class="active-next" @click="changeQuestion5">{{ translate('suivant') }}</button>
                        </template>
                        <template v-else>
                            <button class="deactivate-next">{{ translate('suivant') }}</button>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>


<script>
import axios from 'axios';
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import BackgroundImage1 from '../assets/img/background_form.png';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            lang: this.$store.state.lang,
            company_name: "",
            company_name_yes: false,
            contact_principale: "",
            contact_principale_yes: false,
            address_email: "",
            address_email_yes: false,
            phone_number: "",
            phone_number_yes: false,
            question1: "",
            question2: "",
            question2_yes: false,
            question3: "",
            question4: "",
            question5: "",
            question6: "",
            question7: "",
            question8: "",
            question8_yes: false,
            non_transporter: true,
            complete: false,
            backgroundImage1: BackgroundImage1,
            question4_choice1: false,
            question4_choice2: false,
            question4_choice3: false,
            question4_choice4: false
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        }
    },
    watch: {
        question2(newValue, oldValue) {
            if (this.question2 <= 0) {
                this.question2 = 0
            }
        },
    },
    mounted() {
        document.title = 'Eurekali | Quote';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        //---------Country code phone
        const phoneInput = this.$refs.phoneInput;
        intlTelInput(phoneInput, {
            separateDialCode: true,
            preferredCountries: ["be"],
        });
        //------------------------
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        changeQuestion1() {
            this.company_name_yes = true;
        },
        changeQuestion2() {
            this.secteur_activite_yes = true;
        },
        changeQuestion3() {
            this.contact_principale_yes = true;
        },
        changeQuestion4() {
            this.address_email_yes = true;
        },
        changeQuestion5() {
            this.phone_number_yes = true;
            this.complete = true;
            this.sendQuote();
        },
        changeQuestion6() {
            this.question2_yes = true;
        },
        changeQuestion8() {
            this.question8_yes = true;
        },
        setQuestion1(answer) {
            this.question1 = answer
        },
        setQuestion3(answer) {
            this.question3 = answer
        },
        setQuestion4() {
            let selectedChoices = [];

            if (this.question4_choice1) {
                selectedChoices.push(this.translate('question4_choice1'));
            }

            if (this.question4_choice2) {
                selectedChoices.push(this.translate('question4_choice2'));
            }

            if (this.question4_choice3) {
                selectedChoices.push(this.translate('question4_choice3'));
            }

            if (this.question4_choice4) {
                selectedChoices.push(this.translate('question4_choice4'));
            }

            this.question4 = selectedChoices.join(', ');

        },
        setQuestion5(answer) {
            this.question5 = answer
        },
        setQuestion6(answer) {
            this.question6 = answer
        },
        setQuestion7(answer) {
            this.question7 = answer
        },
        setQuestion8(answer) {
            this.question8 = answer
            this.question8_yes = true
        },
        changeStatut() {
            this.non_transporter = false
        },
        async sendQuote() {

            const phoneInput = this.$refs.phoneInput;
            const iti = intlTelInputGlobals.getInstance(phoneInput);
            const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;

            const extention = selectedCountryCode
            const phone = extention + this.phone_number


            const formData = {

                company_name: this.company_name,
                contact_principale: this.contact_principale,
                address_email: this.address_email,
                phone_number: phone,
                question1: this.question1,
                question2: this.question2,
                question3: this.question3,
                question4: this.question4,
                question5: this.question5,
                question6: this.question6,
                question7: this.question7,
                question8: this.question8,
            }

            await axios
                .post('/api/v1/quote-request/', formData)
                .then(response => {

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>



<style>
.question-box {
    color: black;
    font-size: x-large;
    font-weight: 600;
}

.question-box input {
    border-radius: 20px;
    border: 1px solid #E5097F;
}

.question-box input:focus {
    border-radius: 20px;
    border: 2px solid #E5097F;
    box-shadow: none;
}

.active-next {
    margin-top: 10px;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    background-color: #E5097F;
    color: white;
    border: none;
    font-weight: 600;
}

.active-next:hover {
    background-color: #9B0656;
    color: white;
}

.deactivate-next {
    margin-top: 10px;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    background-color: grey;
    color: white;
    border: none;
    font-weight: 600;
}

.deactivate-next:hover {
    cursor: not-allowed;
}

.choice-item {
    margin-top: 7px;
    font-size: large;
    border: 1px solid #E5097F;
    text-align: center;
    background-color: #E5097F;
    color: white;
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.choice-item:hover {
    background-color: #9B0656;
    cursor: pointer;
}

.show-class {
    display: block;
}

.not-show-class {
    display: none;
}

.custom-from input {
    cursor: pointer;
}

.custom-from input:checked {
    background-color: #E5097F;
    border: none
}

.custom-from label {
    cursor: pointer;
}
</style>