export default {
    data() {
        return {
            en: {
                shipping_route: "Shipping route",
                ship_from: "Ship from",
                ship_to: "Ship to",
                postal_code: "Postal code",
                choose_country: "Choose a country",
                city: "City",
                shipping_date: "Shipping date",
                choose_package: "Choose your package size",
                belgium: "Belgium",
                other_size: "Other size",
                weight: "Weight (KG)",
                width: "Width (cm)",
                height: "Height (cm)",
                length: "Length (cm)",
                rates_times: "Rates and Times",
                delivery_date: "Delivery date",
                package_type: "Package Type",
                price: "Price (EUR)",
                order: "Order",

                alert1: 'Please choose the delivery country',
                alert2: 'Please choose the delivery postal code',
                alert3: 'Please choose the delivery city',
                alert4: 'Please choose the pick-up postal code',
                alert5: 'Please choose the pick-up city',
                alert6: 'Please choose the shipping date',
                alert7: 'does not have a postal code system',
                alert8: 'Please fill the First Name',
                alert9: 'Please fill the last name',
                alert10: 'Please fill the number address',
                alert11: 'Please fill the street',
                alert12: 'Please fill the postal code',
                alert13: 'Please fill the city',
                alert14: 'Please fill the state',
                alert15: 'Please fill the email',
                alert16: 'Please enter a valid email',
                alert17: 'Please fill the phone',
                alert18: 'Please fill the descirption of your package',
                alert19: 'Please choose a service point',
                alert20: 'Please confirm secure packaging before proceeding',
                alert21: 'Please fill in the necessary fields for customs!',
                alert22: 'Please fill the necessary fields!',
                alert23: 'Promo code is valide, amount : ',
                alert24: 'The promo code is not valide!',
                alert25: 'Could not get statistics !!',
                alert26: 'The dimensions are too big!',
                alert27: 'Choose your pickup option',

                summary: "Summary",
                next: "Next",
                total_amount: "Total amount",
                including_vat: "including VAT",

                sender_information: 'Sender Information',
                first_name: "First Name",
                last_name: "Last Name",
                email: "Email",
                phone: "Phone",
                search_address: "Search for address",
                number: "Number",
                street: "Street",
                state: "State",
                shipping_route_2: "Shipping route (To",
                no_insurance: "No insurance",
                assurance: "Assurance",


                receiver_information: "Receiver Information",


                //shipment Information
                shipment_info: " Shipment Information ",
                address: "Address",
                search: "Validate",
                enter_address: "Please enter your address",
                choose_service_point: "The service points nearest to you ",
                placeholder_service_point: "Choose a service point to drop-off your parcel",
                description: "Description",
                placeholder_description: "Write a description of your package here...",
                text: "Attention: By checking this, I confirm having secured the packaging for safe transit and I acknowledge my responsibility.",
                assurance_text1: "Parcel insurance",
                assurance_text2: " (Please note that the maximum insurable value for shipments is 1000€)",
                commercial_text: "Do you have anything to declare to customs?",
                nature: "Nature",
                n_items: "N. items",
                unit_price: "Unit price (€)",
                hs_code: "HS code (optional)",
                remark: "Remark",


                //validation
                validate_info: "Validate information",
                max_weight: "Max weight",
                service_point: "Service Point",
                pickup_address: "Pick-up Address",
                insure_my_parcel: "Insure my parcel",
                commercial_value: "Commercial value",
                shipment_price: "Shipment Price",
                nature_package: "Nature of package",
                edit: "Edit",
                number_items: 'Number of items',
                country: "Country",
                vat: "VAT",
                proceed_payment: "Proceed to Payment",

                //payment
                payment_method: "Payment method",
                pay_credit_card: "Pay with Credit Card",
                pay_bancontact: "Pay with Bancontact",
                pay_cash: "Pay with Cash",
                payment_success: "Payment Successful",
                payment_success_cong: "Congratulations! Your payment has been successfully processed",
                payment_failed: "Payment Failed",
                payment_failed_txt: "Oops! There was an issue processing your payment. Please try again later",

                //payment credit card
                credit_card: "Credit Card Payment",
                enter_promotion_code: "Enter promotion code",
                promo_code: "Promo Code",
                enter_promotion_code_place: "Please enter the promo code",
                validate_code: "Validate code",
                card_inf: "Card information",
                discount: "Discount",

                bancontact: "Bancontact Payment",


                //Cash Payment
                cash: "Cash Payment",
                verification_code: "Verification Code",
                verification_code_place: "Please Enter the verification code",
                validate_payment: "Validate Payment",
                verification_code_failed: "The verification code is not valide!",
                verification_code_txt: 'The code has been sent to the email of the service point you chose. Please contact them to receive the code!',


                //Main nav
                home: "Home",
                new_shipment: "New shipment",
                track_shipment: "Track shipment",
                login: "Login",
                sign_up: "Sign-up",
                myprofile: "My profile",
                sign_out: "Sign-out",
                english: "English",
                french: "French",
                dutch: "Dutch",

                //agent nav
                dashboard: "Dashboard",
                //part nav
                my_orders: "My Orders",



                //Dashboard
                hello: "Hello",
                welcome_back: "Welcome back !",
                tracking_number: "Tracking number",
                order_date: "Order date",
                without_vat: "without VAT",
                payment: "Payment",
                commissions: "Commissions",
                status: "Status",
                not_paid: "Not paid",
                paid: "Paid",
                collected: "Collected",
                cashed: "Cashed",
                credit_card_s: "Credit Card",
                cash_s: "Cash",
                bancontact_s: "Bancontact",
                orders: "Orders",
                my_company: "My Company",
                support: "Support and assistance",
                company_name: "Company Name",
                vat_number: "VAT Number",
                commission: "Commission",
                today_shipment: "Today's shipments",
                shipment_progress: "Shipments in progress",
                shipment_deleivred: "Shipments delivered",
                all_shipment: "All shipments",
                shipments: "Shipments",
                orders_overview: " Orders overview : ",
                profit_this_mounth: "Your profit this month",
                shipments_amount: "Money collected",
                total: "Total",
                pay_bank: "Pay with bank transfer",
                pay_cash: "Pay with cash",
                see_more: "See more",
                withdraw_money: "Withdraw money",
                in_progress: "In progress",
                delivered: "Delivered",
                my_commissions: "My Commissions",
                bank_alert: "Bank transfer payment",
                bank_alert_txt: "The request has been sent successfully to the administration. We will contact you soon !",
                cash_alert: "Cash payment",
                cash_alert_txt: 'The request has been sent successfully to the administration. We will contact you soon !',
                get_money: "Withdraw money",
                get_money_txt: 'The request has been sent successfully to the administration. We will contact you soon !',


                //login page
                forgot_password: "Forgot your Password ?",
                dont_have_account: "You don't have an account ?",
                have_account: "Already have an account ?",
                reset_password: "Reset password",
                login_error: "Email or Password is Incorrect",
                password: "Password",
                confirm_password: "Confirm password",

                no_orders: "Currently, there are no orders on your account",

                Yes: "Yes",
                No: "No",

                //profile
                title: "Title",
                last_login: "Last login",
                mr: "Mr",
                mrs: "Mrs",
                ms: "MS",
                update: "Update",
                update_alert: "Updated successfully!",
                error: "Something went wrong. Please try again",
                update_title: "Update Information",
                update_txt: 'Are you sure you want to update your information ?',
                update_now: "Update now",
                cancel: "Cancel",

                sender: "Sender",
                shipping_label: "Shipping label",

                new_password: "New password",
                confirm_new_password: "Confirm New password",

                reset_password_alert: "Password Updated!",

                send_email: "Send email",
                send_email_alert: 'Email Reset Password sent!',

                track: 'Track',
                nom: "Name",
                shipping_history: "Shipping history",
                receiver: "Receiver",
                date: 'Date',
                location: "Location",
                track_title: 'Incorrect Tracking number',
                track_txt: 'Invalid tracking number. Please try again. Thank you!',


                sender_information2: "Sender",
                receiver_information2: "Receiver",
                shipment_info2: "Shipment",


                register_mssg: "Account created, please log in!",


                //alert assurance
                important_notice: "Important Notice",
                alert_assurance_message: "If you do not choose assurance, you will not be eligible for any refund in case of loss or damage.",
                add_assurance: "Add insurance",
                without_assurance: "Continue without insurance",


                //alert sending instance shipment
                alert_shipmentinstance: "We have sent the payment link to the client",
                alert_shipmentinstance_message: "Please check the following email to proceed to payment : ",
                send_payment_link: "Send payment link ",

                create_new_meeting: "Create new meeting",
                join_your_meeting: "Join your meeting",

                //success
                cong: "Congratulations!",
                package_safe_hands: "Your package is now in good hands.",
                once_your_package: "You can track your package by clicking the button below:",
                track_your_package: "Track your package with three convenient options:",
                thr_whatsapp: "Through WhatsApp",
                thr_sms: "Through SMS",
                thr_website: "On our website",
                whts_desc: "Please send us your tracking number by WhatsApp to:",
                provide_infor: "We will provide you with real-time updates on the status of your package",
                sms_desc: "Please send us your tracking number by text message to:",
                website_desc: "To track your package, please enter the tracking number on our tracking page",
                your_satistfaction: "Do not hesitate to contact us if you need further assistance. Your satisfaction is our priority !",

                business_day: "business day",
                delivery_time: "Delivery time",

                your_address: "Your address",
                service_point_address: "Service point address",
                open_google_maps: "Open in Google Maps",

                welcome_message: "The world at your doorstep in one click!",
                sous_welcome_message: "Welcome to the new era of delivery with Eurêkali",

                change_myAddresse: "No service point nearby? Change your address",

                //footer
                about_us: "About us",
                terms_of_use: "Terms of use",
                privacy_policy: "Legal Notice",
                accessibility_statement: "Accessibility Statement",
                all_rights_resereved: "All rights reserved",

                home_collection: "Home collection",
                servicepoint_collection: "Eurekali service point",
                servicepoint_collection_text: "Drop off at an Eurekali service point",
                home_collection_text: "Collect the parcel from me",
                drop_off_option: "Choose the pickup location :",

                your_feedback: "Please write your feedback...",
                send: "Send",
                give_feedback: "Please write us a feedback",
                thanks_feedback: "Thank you for your feedback",
                how_was_your_experience: "How was your experience with the new Eurêkali system?",

                call_us: "Call us",
                click_here: "Click here if you need a help",

                //agent home page
                join_us: "Join Us",
                become_agent: "Become a Eurekali agent today and discover how your partnership can propel your business to success!",
                why_agent: "Why are our agents happy to be a Eurekali Point ?",
                parg1_title: "1 - Attract more customers",
                parg1: "Become a Eurekali service point and transform your store into a place of activity for those who need to send. This is an incredible chance to increase traffic and introduce new people to your business.",
                parg2_title: "2 - Increase your income",
                parg2: " As a Eurêkali partner, for each package you manage, you could obtain a commission of 10% (€1.8 to €32 per shipment). It's a simple and effective way to add a new source of income to your business.",
                parg3_title: "3 – Improve your customer service",
                parg3: "Offering Eurêkali services means making your customers' lives easier. Whether they want to send or receive packages nationally or internationally, they will be able to do it without leaving your store. Quality customer service often means loyal customers!",
                parg4_title: "4 – Boost your reputation",
                parg4: "Partnering with Eurekali, a respected brand in the shipping industry, can increase your store's credibility and instill trust in your customers. Proudly display this partnership and enjoy the benefits it can bring to your image.",
                register: "Register",

                //home page
                with_our_network: "With our extensive network of service points, you can ship your packages yourself with ease",
                become_success_agnt: "Become a Eurekali agent today and discover how your partnership can propel your business to success!",
                company_text: "With Eurekali Optimize the performance of your parcel logistics with a unique service dedicated to your activity",
                particular: "Particular",
                particular_box: "Send your package anywhere in the world in seconds",
                service_point_box: "Become Eurekali Point and propel your business towards success",
                business: "Business",
                business_box: "Optimize your parcel logistics with a dedicated service",
                start: "Start",
                track_your_shipment: "Track your shipment",
                eurekali_resume: "Eurekali, as delivery experts, is committed to providing a tailor-made service for all profiles (individual, point of service, business). Our goal is to select the ideal solution for shipping your packages by taking into account the following criteria",
                unbeatable_low_prices: "Unbeatable low prices",
                fast_delivery_times: "Fast delivery times",
                privileged_contact: "Privileged contact",
                guarantee_against_delays: "Guarantee against delays",
                eurekali_reliable: "Eurekali: Your reliable and affordable shipping solution",
                eurekali_resume2: "Eurekali is the ideal partner to meet all your shipping needs. Whether you are a business looking for efficient logistics solutions or an individual wishing to send a package, we are here for you. Our delivery service offers cost-effective and reliable shipping solutions suitable for all types of shipments.",
                users_review_text1: "Our customers’ opinions on Eurekali",
                users_review_text2: "Confidence and satisfaction!",

                contact: "Contact",
                text1_home: "Professional or personal",
                text2_home: "Don't waste any more time",
                text3_home: "searching for a carrier",
                text4_home: "Choose Eurêkali for a reliable alliance of carriers and simplified logistics management",
                make_shipment: "SEND A SHIPMENT",
                ask_quote: "ASK FOR A QUOTE",
                become_partner: "BECOME A PARTNER",
                header_text1: "Eurekali transforms your expectations into reality,",
                header_text2: "adapting its solutions to your requirements",
                element_title1: "Precious Time",
                element_contenu1: "Save time by finding the delivery that suits your needs, with the freedom to change at any time...",
                button_text: "See more",
                element_title2: "Demanded Quality",
                element_contenu2: "Strength in unity. By pooling the needs of our clients, we influence carriers toward high-quality services...",
                element_title3: "Negotiated Rates",
                element_contenu3: "Grouping orders for advantageous rates. Benefit from the power of the group to reduce your expenses...",
                element_title4: "Multiservice Flexi",
                element_contenu4: "Choosing from over 10,000 carriers in Belgium is complex. We select the best for you...",
                header_text3: "Unlock the Power with Eurêkali",
                header_text4: "Your All-in-One Solution",
                solution_title1: "A contract",
                solution_contenu1: "Offer a simple and revocable contract to collaborate with all our logistics partners.",
                solution_title2: "A Platform",
                solution_contenu2: "Centralize your orders, shipments, and tracking on a single user-friendly platform.",
                solution_title3: "A contact",
                solution_contenu3: "Benefit from a dedicated VIP contact to guide and oversee all your shipments.",
                solution_title4: "An invoice",
                solution_contenu4: "Simplify your accounting with consolidated invoicing for all your transactions.",
                eurekali_name: "Eurêkali",
                eurekali_name_description: "Leader in Belgium since 2013",
                eurekali_presentation: "Eurêkali, a leader in Belgium since 2013, provides tailored logistic solutions to meet your shipping needs, whether you are an individual, e-commerce seller, small business, or multinational.",
                satisfied_clients: "Thousands of Satisfied Clients",
                satisfied_clients_description: "Many of them, we asked how satisfied they were with our services.",
                satisfied_clients_description2: "Here are their statements",
                button_text2: "GIVE A REVIEW",
                user_name1: "Marc B. B2B",
                user_review1: "Previously, tracking was complicated with a single carrier. Today, thanks to Eurêkali managing all our carriers, everything is handled smoothly and efficiently.",
                user_name2: "Amina R. B2B",
                user_review2: "Finding a carrier up to our standards is almost impossible; Eurêkali has succeeded in doing so.",
                user_name3: "Alan D. C2C",
                user_review3: "In a delicate situation, Eurêkali ensured a fast and secure delivery of important documents, saving me. A professional service that I recommend.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Collaborating with multiple carriers involves numerous contacts and various invoices. With Eurêkali, we save valuable time by consolidating these processes.",

                suivant: "Next",
                nom_entreprise: "Company Name?",
                secteur_activite: "Business Sector?",
                contact_principale: "Primary Contact (Name and Position)?",
                address_email: "Email Address?",
                phone_number: "Phone Number",
                repondez_ici: "Reply here...",
                question1: "What is the frequency of your delivery needs?",
                question1_choice1: "Daily",
                question1_choice2: "Weekly",
                question1_choice3: "Monthly",
                question1_choice4: "Other",
                question2: "What is the average volume of your shipments? (Number of packages or approximate weight per shipment)",
                question3: "What are the main destinations of your shipments?",
                question3_choice1: "National",
                question3_choice2: "International",
                question3_choice3: "Both",
                question4: "What type of goods do you ship?",
                question4_choice1: "Documents",
                question4_choice2: "Packages",
                question4_choice3: "Pallets",
                question4_choice4: "Special Goods",
                question5: "Are there any specific requirements for your goods? (Fragile, temperature-controlled, hazardous, etc.)",
                question5_choice1: "Yes",
                question5_choice2: "No",
                question6: "What delivery services are you looking for?",
                question6_choice1: "Standard Delivery",
                question6_choice2: "Express",
                question6_choice3: "Same Day",
                question7: "Do you need additional services? (Insurance, real-time tracking, delivery with signature, etc.)",
                question7_choice1: "Yes",
                question7_choice2: "No",
                question8: "Have you ever used express delivery services?",
                question8_choice1: "Yes",
                question8_choice2: "No",
                question9: "What are your main criteria for choosing a delivery service? (Cost, reliability, speed, customer service, etc.)",
                question10: "Do you have any specific expectations or concerns regarding the delivery service?",
                question11: "What is your approximate budget for delivery services?",
                question12: "What are your preferences for billing and payment?",
                thanks_message1: "Thank you for requesting a quote.",
                thanks_message2: "One of our industry experts is processing your request. You will be contacted shortly to discuss your delivery needs and our solutions.",
                agent: "Agent",
                transporter: "Transporter",
                why_question1: "Why do",
                why_question2: "choose to use Eurekali?",

                //B2B page
                b2b_title1: "Don't waste your time searching for a carrier for your business.",
                b2b_title2: "Choose Eurêkali for a reliable alliance of carriers and simplified logistics management.",
                b2b_title3: "Click, Compare, Send and Smile!",
                who_are_we: "Who Are We?",
                b2b_title4: "Approved by Leading Companies",
                b2b_title5: "Discover the Secrets of their Satisfaction",
                sender: "Sender",
                sender_title: "Simplified Shipping: Find the Ideal Carrier",
                sender_contenu: "Our clients appreciate easy access to a wide range of carriers through our Eurekali platform. By offering personalized options for each shipment and ensuring timely delivery, we make the shipping experience hassle-free, building customer loyalty through our flexibility and reliability.",
                read_more: "Read more",
                mail_room: "Receptionist / Mailroom",
                mail_room_title: "Optimal Shipment Management: Choice and Efficiency",
                mail_room_contenu: "Shipment management is streamlined with Eurekali. Our receptionists and mailroom staff use our platform to choose from a variety of carriers, effectively meeting specific requests from each client. This personalized approach leads to increased customer satisfaction.",
                accountant: "Accountant",
                accountant_title: "Financial Management of Transport: Clarity and Flexibility",
                accountant_contenu: "Our Eurekali platform helps accounting departments effectively manage transportation costs. With clear visibility into different carrier options and transparent billing, we help clients maintain rigorous financial control, reinforcing their confidence and satisfaction.",
                ceo: "CEO",
                ceo_title: "Innovative Transport Strategy: Preferred Partner",
                ceo_contenu: "Leaders find in Eurekali a strategic partner to optimize transportation services. By offering a wide choice of carriers and focusing on operational efficiency and cost management, our platform ensures an exceptional experience, strengthening customer loyalty and satisfaction.",
                partners_trust_us: "Partners who trust in our services.",
                say_about_us: "What do our partners say about us?",
                b2b_title6: "Your domain, your success. Transport, our expertise.",
                b2b_title7: "Join us today and transform your shipping experience!",

                company_info: "Company Information",
                company_name_trans: "Transport Company Name:",
                company_address: "Headquarters Address:",
                company_year: "Foundation Year:",
                company_contact: "Primary Contact Name and Position:",
                company_address_trans: "Contact Email Address:",
                company_phone_trans: "Contact Phone Number:",
                company_structure: "Company Structure:",
                company_capacity: "Capacities and Resources",
                vehicles_number: "Number of Transport Vehicles:",
                vehicles_type: "Available Vehicle Types:",
                max_load: "Maximum Load Capacity per Vehicle:",
                service_zone: "Geographical Service Radius:",
                service_availability: "Availability for National/International Services:",
                placeholder1: "Independent, Company...",
                placeholder2: "Truck, Van, etc...",
                experience_certification: "Experience and Certification",
                pricipe_sectors: "Primary Sectors Served:",
                placeholder3: "e.g., medical, e-commerce, industrial",
                experience_transport: "Experience in Express or Standard Transport:",
                certifications: "Specific Certifications or Accreditations:",
                placeholder4: "e.g., ISO, ADR for hazardous materials transport",
                references: "Previous Client or Partner References:",
                services_offers: "Specific Services and Offers",
                propossed_services: "Proposed Special Services:",
                placeholder5: "e.g., same-day delivery, temperature-controlled",
                flexibility: "Flexibility and Service Adaptability:",
                pricing_cost: "Pricing and Cost Structure:",
                other_information: "Other Information and Comments",
                why_choose_us_trans: "Why do you want to join our transport company consortium?",
                what_your_expectation: "What are your expectations in terms of collaboration?",
                thanks_transporter: "Thank you for submitting the form. We have received your request, and our team will contact you soon.",

                coworking_header: "Expand the horizons of your coworking space with Eurêkali",
                coworking_sheader: "Streamlined Shipping for the Entire Community",
                coworking_section1_title: "How can Eurekali enhance your coworking space experience?",
                coworking_section1_par1_title: "Take Your Coworking Space to the Next Level",
                coworking_section1_par1_desc: "With Eurekali as a natural extension of your office space rental services, you offer your community much more than just a workplace. With our integrated shipping platform, you can provide your customers with the convenience of managing their shipments directly from your space, adding invaluable value to your service offering.",
                coworking_section1_par2_title: "Exclusive Partnership with Transport Leaders",
                coworking_section1_par2_desc: "By choosing Eurekali as your shipping partner, you automatically gain access to our network of world-renowned transport partners. Our strategic relationships with the largest transport companies ensure reliable and efficient shipping solutions for your customers, wherever they are in the world.",
                coworking_section2_title: "Benefits for Your Coworking Community",
                coworking_advn1_title: "Comfort and Accessibility",
                coworking_advn1_desc: "Offer your members the convenience of shipping their parcels directly from their workspace, eliminating the hassle of traveling to external shipping centers.",
                coworking_advn2_title: "Preferred Rates",
                coworking_advn2_desc: "As a partner of Eurekali, your community enjoys preferred rates on shipping services, encouraging them to use this facility regularly.",
                coworking_advn3_title: "Simplified Tracking",
                coworking_advn3_desc: "With our user-friendly platform, your members can track their parcels in real-time, providing complete peace of mind about the security of their shipments.",
                coworking_advn4_title: "Flexibility and Choice",
                coworking_advn4_desc: "Whether it's domestic or international shipments, important documents, or bulky parcels, Eurekali offers a comprehensive range of shipping services to meet all the needs of your community.",
                coworking_joinus_par1: "Join us now!",
                coworking_joinus_par2: "Incorporate Eurekali into your service offering and provide your community with an unparalleled coworking experience.",
                coworking_joinus_par3: "Our team is ready to collaborate with you to take your coworking space to the next level.",
                coworking: "Coworking",

                contactus_header: "Contact us",
                contactus_sheader1: "Welcome to Eurekali, experts in express delivery",
                contactus_sheader2: "Need help? Contact us!",
                contactus_form_input1: "Name",
                contactus_form_input2: "Company",
                contactus_form_input3: "Phone",
                contactus_form_input4: "Email",
                contactus_form_input5: "Subject",
                contactus_form_input6: "Message",
                contactus_form_button: "Send",
                contactus_text1: "Dedicated phone number for immediate assistance:",
                contactus_text2: "Our team is available",
                contactus_text3: "To quickly address your urgent concerns.",
                contactus_message: "Thank you, we will contact you soon",

                about_us_title: "Company History",
                about_us_sous_title: "Since our creation in 2013, we have reached many important milestones:",
                about_us_2013: "Foundation of Eurêkali, with a vision to revolutionize logistics in Belgium.",
                about_us_2015: "Introduction of our integrated platform for ordering, tracking, and billing.",
                about_us_2018: "Expansion of our network of carriers to offer more service choices.",
                about_us_2020: "Launch of our mobile app for even more accessible logistics management.",
                about_us_2023: "Recognition as a leader in the Belgian and European logistics market.",
                about_us_2024: "Strategic expansion of our presence in the African market.",
                mission_and_vision: "Mission and Vision",
                mission: "Mission",
                mission_desc: "Provide tailored logistics solutions to meet your shipping needs in the B2B, B2C, and C2C world, while ensuring quality service and total satisfaction.",
                vision: "Vision",
                vision_desc: "Become the essential reference for logistics solutions in Europe and other continents, constantly innovating to meet our clients' evolving needs.",
                about_eurekali: "About Eurêkali",
                about_eurekali_title: "Eurêkali, your integrated solution since 2013",
                about_eurekali_title_desc: "Welcome to Eurêkali, the logistics leader in Belgium and Europe since 2013. We are an innovative marketplace bringing together the main players in logistics on a single platform. Our goal is to simplify and optimize your logistics operations, whether you are an individual, an e-merchant, a small business, or a multinational.",
                about_us_question: "Contact us if you need help or answers to your questions",

                element_drop_contenu1: "Save time by finding the delivery that suits your needs, with the freedom to change at any time. Searching for a carrier, negotiating, and getting started takes considerable time, not to mention the risk of being disappointed by the service provided. With our solution, avoid these hassles and enjoy efficient and reliable delivery, benefiting from a wide range of carefully selected carriers to meet your needs.",
                element_drop_contenu2: "Strength is unity. By pooling the needs of our clients, we influence carriers towards high-quality services. The mass effect allows us to have direct access to the operators of various carriers. They consider us as VIPs when we contact them, whether for specific requests or problem resolution. You fully benefit from this mass effect.",
                element_drop_contenu3: "Grouping orders for advantageous rates. Benefit from the power of the group to reduce your expenses. Thanks to our order volume, we negotiate preferential rates with carriers, allowing you to benefit from reduced prices without compromising on service quality.",
                element_drop_contenu4: "Choosing from over 10,000 carriers in Belgium is complex. We select the best for you, taking into account your specific needs. With our flexible service, you have access to a wide range of options and the freedom to change carriers at any time, ensuring a tailored and worry-free solution.",

                give_review: "Give a review",
                write_review: "Write a review",
                reviews: "Reviews",
                stars: "Stars",
                clients_reviews_text1: "Our clients' reviews on Eurêkali",
                clients_reviews_text2: "Trust and satisfaction!",

                give_review_alert1: "Please enter your name",
                give_review_alert2: "Please choose at least one star",

                contact_us_alert1: "Please enter the name",
                contact_us_alert2: "Please enter the company",
                contact_us_alert3: "Please enter the phone number",
                contact_us_alert4: "Please enter the email",
                contact_us_alert5: "Please enter the subject",
                contact_us_alert6: "Please enter the message",

                transporters_title: "Welcome to our platform dedicated to transporters!",
                transporters_sub_title: "Whether you specialize in the transport of goods, people, or delivery services, our marketplace is designed for you. By joining our network, you gain access to a large customer base looking for your services.",
                transporters_partners: "Our trusted partnerships:",
                join_our_marketplace: "Join Our Transporters Marketplace",
                new_opport: "Take Advantage of New Opportunities and Grow Your Business",
                opp1_title: "1 - Increased Visibility:",
                opp1_contenu: "Increase your online visibility and reach more potential customers.",
                opp2_title: "2 - Simplified Management:",
                opp2_contenu: "Use our intuitive interface to manage your bookings and schedule.",
                opp3_title: "3 - Support and Assistance:",
                opp3_contenu: "Benefit from our technical and commercial support to optimize your activity.",
                transporter_join_now: "Sign up now and start receiving transport requests!",

                faqs_title: "Frequently Asked Questions",
                faq_question1: "How and when will I receive my shipping documents?",
                faq_answer1: "Once the order is validated and paid for on our website www.eurekali.com, the required shipment documents will be available for download directly in PDF format from your order confirmation email. This includes one delivery note for European shipments, and for international ex-EU shipments with commercial value, one delivery note and a pro forma invoice. Additionally, an Ad Valorem insurance certificate can be purchased if selected during the order process. You can also download the transport invoice in PDF format from your order confirmation email. Finally, if you selected the appropriate options, notifications will be sent by email or text messages to the person designated in the destination address section.",
                faq_question2: "Why are your rates lower than on the transporters’ own websites?",
                faq_answer2: "Eurêkali can offer its customers better deals because it pools transport flows from its transporters. And the more we ship, the better the rates that we offer will be.",
                faq_question3: "My parcel has not shipped, my parcel is damaged, how do I make a claim?",
                faq_answer3: "If the parcel has not shipped, you must contact the telephone number indicated in the order confirmation email as soon as possible, explain the situation, and wait for feedback. If the response is unsatisfactory, fill in the claim form by clicking here. We will open an investigation with the transporter you selected to ensure all necessary actions are taken to satisfy you. As a precaution, send a registered letter with your complaint to the transporter on the 15th day after the initial delivery was planned, attaching the original invoice of the goods shipped or their replacement or repair invoice to your complaint letter. In case of partial delivery or damage to the parcel, control the number of items in the parcel and inside the package while still in front of the delivery person, and sign the bill of lading only after describing any potential damage or loss of items. Contact the telephone number indicated in the order confirmation email as soon as possible, explain the situation, and wait for feedback. If the response is unsatisfactory, fill in the claim form by clicking here. Send a registered letter to the transporter within 48 hours after delivery, attaching the original invoice of the goods shipped or their replacement or repair invoice to your complaint letter. As a precaution, keep the parcel and its packaging.",
                faq_question4: "How do I claim for compensation in case of delivery delays to my shipment?",
                faq_answer4: "As a precaution, send a registered letter to the transporter. The compensation exclusively applies to the transport cost and does not cover the cost of the goods. We kindly advise you to refer to the chosen transporter’s terms and conditions of sale, to know the precise requirements to claim compensation for any delays incurred.",
                faq_question5: "Can we move to our new house with Eurêkali?",
                faq_answer5: "You can move to a new place with Eurêkali, provided the shipment is correctly documented with the exact number of items ready to be collected by the mover.",
                faq_question6: "How can I order several shipments simultaneously?",
                faq_answer6: "If you have to send several parcels to the same destination, you can group your parcels. On the start page, you will have to simply create the necessary additional lines. If the destination addresses of your parcels are different, you will have to place separate orders.",
                faq_question7: "Is it better to send one large parcel or several small ones?",
                faq_answer7: "It is always better to let the transporters take care of several smaller parcels of average weight, than one larger parcel whose handling would require two people or the use of a trolley. The price is often equivalent but the risk of damage to the parcels is lower if you split them, as it makes them easier to handle.",
                faq_question8: "What are the three things I should do at pickup and delivery before handing a parcel over to a transporter or collecting it from him?",
                faq_answer8: "Verify that the weight and dimensions of the parcel are correct. If any information is incorrect, the shipment might be blocked at the transporter’s warehouse, and its recall will be at your charge without any compensation for the service paid. Verify that the contents of your parcel are authorized for shipment and tightly wrapped. The contents must not be prohibited; check the “What can’t I send?” list as well as Eurêkali’s and the transporter’s terms and conditions. The parcel must be firmly packed to ensure its handling will not damage the contents. If the sender does not respect the packing instructions, the parcel will be blocked at the transporter’s warehouse, and its recall will be at your charge without any compensation for the service paid. Verify that the pickup and destination addresses are complete and correct. They must be communicated comprehensively. If the transporter cannot easily access the mentioned addresses, it can consider that its delivery mission was accomplished, and your parcel will be kept in a warehouse with the recall charged to the sender. Delays incurred due to a missing person at the destination or an incomplete address will not be considered the responsibility of the transporter. To avoid any delays or missed deliveries, the user must indicate the pickup and destination contact persons' phone numbers and communicate any useful information for collection or delivery.",
                faq_question9: "What are the accepted payment methods?",
                faq_answer9: "You can pay your shipments with VISA or MasterCard through the Ogone online payment system. If you create a user account, your billing data will be saved and you can use the direct debit payment. As a company, you can have access to a fully integrated single monthly billing system that connects to your own payment system and accounts, provided that our financial department approves your application.",
                faq_question10: "Which transporters does Eurêkali work with?",
                faq_answer10: "We give utmost importance to our customers’ satisfaction. With this objective in mind, we have selected transporters renowned for their quality service: TNT, GLS, IPS, DHL, FEDEX, Alpha, PMC, Number One, Ecopostale. The list is constantly being updated. Eurêkali’s quality control department carefully analyzes any new possibilities of doing business with companies from other sectors, to offer you comparative conditions in as many specialized areas of expertise as possible."








            }
        }
    }
}
