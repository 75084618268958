<template>
    <div ref="scrollTarget"></div>
    <MainNav />
    <section style="padding-top: 65px; padding-bottom: 100px;">
      <div class="container faq-container">
        <h1 class="text-center mb-5" style="font-size: xx-large; font-weight: bold; color: #E5097F;">{{ translate('faqs_title') }}</h1>
        <div style="margin-top: 80px;" data-ui-tablist class="ui-accordion ui-accordion--outlined" data-ui-transition="collapse-fade">
          <div class="ui-accordion-item mb-4" v-for="(faq, index) in faqs" :key="index">
            <button @click="toggleAccordion(index)" data-ui-tablist-tab class="ui-accordion-header btn btn-light btn-block text-start shadow-sm">
              <span>{{ faq.question }}</span>
              <span class="accordion-icon">
                <i style="color: #E5097F;" :class="{'fa fa-chevron-up': activeIndex === index, 'fa fa-chevron-down': activeIndex !== index}"></i>
              </span>
            </button>
            <div :data-ui-tablist-tabpanel="true" v-if="activeIndex === index" class="border-0">
              <div class="ui-accordion-body p-4 border rounded shadow-sm">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import MainNav from "@/components/MainNav2.vue";
  import en from "../en.js";
  import fr from "../fr.js";
  import nl from "../nl.js";
  
  export default {
    mixins: [en, fr, nl],
    components: {
      MainNav,
    },
    data() {
      return {
        activeIndex: null,
        faqs: []
      };
    },
    computed: {
      lang() {
        return this.$store.state.lang;
      },
    },
    watch: {
      lang(newLang) {
        this.updateFaqs(newLang);
      }
    },
    mounted() {
      document.title = "FAQS | Eurekali";
      const element = this.$refs.scrollTarget;
      element.scrollIntoView({ behavior: "smooth" });
      
      // Initialize Jolty UI Tablist after Vue component is mounted
      if (window.Tablist) {
        window.Tablist.initAll();
      }
  
      this.updateFaqs(this.lang);
    },
    methods: {
      toggleAccordion(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      },
      translate(prop) {
        return this[this.lang][prop];
      },
      updateFaqs(lang) {
        this.faqs = [];
        for(let i = 1; i <= 10; i++) {
          this.faqs.push({
            question: this.translate('faq_question' + i),
            answer: this.translate('faq_answer' + i)
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .faq-container {
    max-width: 800px;
    margin: auto;
    padding: 2rem 0;
  }
  .ui-accordion {
    font-size: 1.1rem;
  }
  .ui-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    color: black;
    width: 100%;
  }
  .ui-accordion-header:hover {
    background-color: #e9ecef;
  }
  .accordion-icon {
    font-size: 1.2rem;
  }
  .ui-accordion-body {
    background-color: #F6D9EA;
    color: black;
    width: 100%;
  }
  </style>
  
