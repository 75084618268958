<template>
    <div ref="scrollTarget"></div>

    <MainNav />
    <section class="laptop-section">
        <section class="background-container" style="padding-bottom: 20px; height: 550px; margin-top: 70px"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%; padding-left: 150px;">
                <div class="row gx-5">
                    <div class="col-md-6">
                        <h1 style="color: #E5097F; font-weight: bold; font-size: 1.5em;">{{ translate('about_eurekali')
                            }}</h1>
                        <h1 style="color: white; font-weight: bold; font-size: 1.5em; margin-top: 50px;">{{
                            translate('about_eurekali_title') }}</h1>
                        <br>
                        <p style="color: white; font-size: 400; font-size: 1.3em;">{{
                            translate('about_eurekali_title_desc') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.7em; font-weight: bold;">{{ translate('about_us_title') }}</h1>
                <br>
                <h2 style="color: black; font-size: 1.2em; font-weight: 400;">{{ translate('about_us_sous_title') }}
                </h2>
            </div>
        </section>


        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2013_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2013') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2015_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2015') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2018_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2018') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2020_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2020') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2023_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2023') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2024_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2024') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.7em; font-weight: bold;">{{ translate('mission_and_vision') }}
                </h1>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-3 d-flex" style="margin-right: 10px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/target_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('mission')
                                    }}</h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('mission_desc') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 d-flex" style="margin-left: 10px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/vision_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('vision') }}
                                </h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('vision_desc') }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 40px; margin-bottom: 80px;">
            <div class="container text-center">
                <div class="mx-auto d-flex justify-content-center align-items-center"
                    style="background-color: #FEEEF6; width: 550px; padding: 25px; border-radius: 15px;">
                    <div class="d-inline-block" style="margin-right: 20px;">
                        <img style="width: 80px;" src="../assets/img/question_icon.svg" class="img-fluid">
                    </div>
                    <router-link to="/contact" class="d-inline-block align-middle"
                        style="width: 350px; color: #E5097F; text-decoration: underline; font-weight: 600;">
                        {{ translate('about_us_question') }}
                    </router-link>
                    <div class="d-inline-block" style="margin-left: 20px;">
                        <img src="../assets/img/great_icon.svg" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
    </section>


    <section class="mobile-section">
        <div class="laptop-header">
            <header class="position-relative" style="margin-top: 70px;">
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/about_header_phone.jpg" class="d-block w-100" alt="Background Image">
                </transition>
            </header>
        </div>

        <section style="background-color: black; margin-top: -12px;">
            <div class="container text-center" style="padding: 30px;">
                <h1 style="color: #E5097F; font-weight: bold; font-size: 1.3em;">{{ translate('about_eurekali') }}</h1>
                <h1 style="color: white; font-weight: bold; font-size: 1.3em; margin-top: 30px;">{{
                    translate('about_eurekali_title') }}</h1>
                <p style="color: white; font-weight: 400; margin-top: 30px;">{{ translate('about_eurekali_title_desc')
                    }}</p>
            </div>
        </section>

        <section style="margin-top: 30px;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.3em; font-weight: bold;">{{ translate('about_us_title') }}</h1>
                <br>
                <h2 style="color: black; font-weight: 400;">{{ translate('about_us_sous_title') }}</h2>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-11 mx-auto">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2013_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2013') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-11 mx-auto" style="margin-top: 20px;">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2015_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2015') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-11 mx-auto" style="margin-top: 20px;">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2018_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2018') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-11 mx-auto" style="margin-top: 20px;">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2020_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2020') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-11 mx-auto" style="margin-top: 20px;">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2023_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2023') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-11 mx-auto" style="margin-top: 20px;">
                        <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #FEEEF6;">
                            <div>
                                <img src="../assets/img/2024_icon.svg">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <p style="color: black;">{{ translate('about_us_2024') }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.3em; font-weight: bold;">{{ translate('mission_and_vision') }}
                </h1>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-3 d-flex" style="margin-top: 15px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/target_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('mission')
                                    }}</h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('mission_desc') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 d-flex" style="margin-top: 60px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/vision_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('vision') }}
                                </h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('vision_desc') }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 40px; margin-bottom: 80px;">
            <div class="container text-center">
                <div class="col-11 mx-auto" style="margin-top: 20px;">
                    <div class="border d-flex flex-column"
                        style="border-radius: 10px; background-color: #FEEEF6; padding: 20px;">
                        <div>
                            <img style="width: 70px;" src="../assets/img/question_icon.svg">
                        </div>
                        <router-link to="/contact" class="d-inline-block align-middle"
                            style="color: #E5097F; text-decoration: underline; font-weight: 600;">
                            {{ translate('about_us_question') }}
                        </router-link>

                    </div>
                </div>
            </div>
        </section>

    </section>

    <section class="ipad-section">
        <section class="background-container" style="height: 400px; margin-top: 70px"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
        </section>

        <section style="background-color: black; margin-top: -2px;">
            <div class="container text-center" style="padding: 30px;">
                <h1 style="color: #E5097F; font-weight: bold; font-size: 1.3em;">{{ translate('about_eurekali') }}</h1>
                <h1 style="color: white; font-weight: bold; font-size: 1.3em; margin-top: 30px;">{{
                    translate('about_eurekali_title') }}</h1>
                <p style="color: white; font-weight: 400; margin-top: 30px;">{{ translate('about_eurekali_title_desc')
                    }}</p>
            </div>
        </section>



        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.7em; font-weight: bold;">{{ translate('about_us_title') }}</h1>
                <br>
                <h2 style="color: black; font-size: 1.2em; font-weight: 400;">{{ translate('about_us_sous_title') }}
                </h2>
            </div>
        </section>


        <section style="margin-top: 20px; padding-top: 20px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2013_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2013') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2015_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2015') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2018_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2018') }}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 15px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2020_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2020') }}
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2023_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2023') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #FEEEF6; width: 100%;">
                            <div style="padding: 0;">
                                <img style="width: 130px;" src="../assets/img/2024_icon.svg">
                            </div>
                            <div style="padding: 20px 15px 30px 15px; color: black;">
                                {{ translate('about_us_2024') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container text-center">
                <h1 style="color: black; font-size: 1.7em; font-weight: bold;">{{ translate('mission_and_vision') }}
                </h1>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-5 d-flex" style="margin-right: 10px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/target_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('mission')
                                    }}</h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('mission_desc') }}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5 d-flex" style="margin-left: 10px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; width: 100%; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                            <div style="margin-top: -70px;">
                                <img style="width: 130px;" src="../assets/img/vision_icon.svg">
                            </div>
                            <div style="padding: 20px 30px 30px 30px; color: black;">
                                <h1 style="color: black; font-weight: bold; font-size: 1.3em;">{{ translate('vision') }}
                                </h1>
                                <p style="color: black; margin-top: 20px;">{{ translate('vision_desc') }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 40px; margin-bottom: 80px;">
            <div class="container text-center">
                <div class="mx-auto d-flex justify-content-center align-items-center"
                    style="background-color: #FEEEF6; width: 550px; padding: 25px; border-radius: 15px;">
                    <div class="d-inline-block" style="margin-right: 20px;">
                        <img style="width: 80px;" src="../assets/img/question_icon.svg" class="img-fluid">
                    </div>
                    <router-link to="/contact" class="d-inline-block align-middle"
                        style="width: 350px; color: #E5097F; text-decoration: underline; font-weight: 600;">
                        {{ translate('about_us_question') }}
                    </router-link>
                    <div class="d-inline-block" style="margin-left: 20px;">
                        <img src="../assets/img/great_icon.svg" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/about_header.jpg';
import BackgroundImage2 from '../assets/img/about_header_ipad.svg';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,

        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | About us';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>
