export default {
    data() {
        return {
            nl: {
                shipping_route: "Verzendroute",
                ship_from: "Verzenden vanuit",
                ship_to: "Verzenden naar",
                postal_code: "Postcode",
                choose_country: "Kies een land",
                city: "Stad",
                shipping_date: "Verzenddatum",
                choose_package: "Kies uw maat",
                belgium: "België",
                other_size: "Andere grootte",
                weight: "Gewicht (KG)",
                width: "Breedte (cm)",
                height: "Hoogte (cm)",
                length: "Lengte (cm)",
                rates_times: "Tarieven en Tijden",
                delivery_date: "Leveringsdatum",
                package_type: "Pakkettype",
                price: "Prijs (EUR)",
                order: "Bestelling",

                alert1: 'Kies alstublieft het leveringsland',
                alert2: 'Kies alstublieft de leveringspostcode',
                alert3: 'Kies alstublieft de leveringsstad',
                alert4: 'Kies alstublieft de ophaalpostcode',
                alert5: 'Kies alstublieft de ophaalstad',
                alert6: 'Kies alstublieft de verzenddatum',
                alert7: 'heeft geen postcodesysteem',
                alert8: 'Vul alstublieft de voornaam in',
                alert9: 'Vul alstublieft de achternaam in',
                alert10: 'Vul alstublieft het huisnummer in',
                alert11: 'Vul alstublieft de straat in',
                alert12: 'Vul alstublieft de postcode in',
                alert13: 'Vul alstublieft de stad in',
                alert14: 'Vul alstublieft de provincie in',
                alert15: 'Vul alstublieft het e-mailadres in',
                alert16: 'Voer alstublieft een geldig e-mailadres in',
                alert17: 'Vul alstublieft het telefoonnummer in',
                alert18: 'Vul alstublieft de beschrijving van uw pakket in',
                alert19: 'Kies een servicepunt alstublieft',
                alert20: 'Bevestig alstublieft de veilige verpakking voordat u verder gaat',
                alert21: 'Vul de benodigde velden voor de douane in!',
                alert22: 'Vul alstublieft de noodzakelijke velden in!',
                alert23: 'Promocode is geldig, bedrag: ',
                alert24: 'De promocode is niet geldig!',
                alert25: 'Kon geen statistieken ophalen !!',
                alert26: "De afmetingen zijn te groot!",
                alert27: 'Kies uw ophaal locatie',

                summary: "Samenvatting",
                next: "Volgende",
                total_amount: "Totaalbedrag",
                including_vat: "inclusief BTW",

                sender_information: 'Afzendergegevens',
                first_name: "Voornaam",
                last_name: "Achternaam",
                email: "E-mail",
                phone: "Telefoon",
                search_address: "Zoek adres",
                number: "Nummer",
                street: "Straat",
                state: "Staat",
                shipping_route_2: "Verzendroute (Naar",
                no_insurance: "Geen verzekering",
                assurance: "Verzekering",

                receiver_information: "Ontvanger gegevens",

                //zending Informatie
                shipment_info: "Zending Informatie",
                address: "Adres",
                search: "Valideren",
                enter_address: "Voer alstublieft uw adres in",
                choose_service_point: "De dichtstbijzijnde servicepunten voor u",
                placeholder_service_point: "Kies een servicepunt om uw pakket af te geven",
                description: "Beschrijving",
                placeholder_description: "Schrijf hier een beschrijving van uw pakket...",
                text: "Let op: Door dit aan te vinken, bevestig ik dat de verpakking veilig is voor een veilig transport en erken ik mijn verantwoordelijkheid.",
                assurance_text1: "Verzeker mijn pakket.",
                assurance_text2: " (Houd er rekening mee dat de maximale verzekerde waarde voor zendingen 1000€ is)",
                commercial_text: "Heeft u iets om aan te geven bij de douane?",
                nature: "Aard",
                n_items: "Aantal items",
                unit_price: "Eenheidsprijs (€)",
                hs_code: "HS code (optioneel)",
                remark: "Opmerking",

                //validatie
                validate_info: "Informatie valideren",
                max_weight: "Maximaal gewicht",
                service_point: "Servicepunt",
                pickup_address: "Ophaaladres",
                insure_my_parcel: "Pakket verzekering",
                commercial_value: "Commerciële waarde",
                shipment_price: "Verzendprijs",
                nature_package: "Aard van het pakket",
                edit: "Bewerken",
                number_items: 'Aantal items',
                country: "Land",
                vat: "BTW",
                proceed_payment: "Doorgaan naar betaling",

                //betaling
                payment_method: "Betalingsmethode",
                pay_credit_card: "Betalen met creditcard",
                pay_bancontact: "Betalen met Bancontact",
                pay_cash: "Betalen met contant geld",
                payment_success: "Betaling geslaagd",
                payment_success_cong: "Gefeliciteerd! Uw betaling is succesvol verwerkt",
                payment_failed: "Betaling mislukt",
                payment_failed_txt: "Oeps! Er was een probleem bij het verwerken van uw betaling. Probeer het later opnieuw",

                //betaling creditcard
                credit_card: "Creditcardbetaling",
                enter_promotion_code: "Voer promotiecode in",
                promo_code: "Promotiecode",
                enter_promotion_code_place: "Voer de promotiecode in",
                validate_code: "Code valideren",
                card_inf: "Kaartinformatie",
                discount: "Korting",

                bancontact: "Bancontact Betaling",

                //Contante betaling
                cash: "Contante betaling",
                verification_code: "Verificatiecode",
                verification_code_place: "Voer de verificatiecode in",
                validate_payment: "Betaling valideren",
                verification_code_failed: "De verificatiecode is niet geldig!",
                verification_code_txt: 'De code is verzonden naar het e-mailadres van het servicepunt dat u heeft gekozen. Neem contact met hen op om de code te ontvangen!',

                //Hoofdmenu
                home: "Startpagina",
                new_shipment: "Nieuwe zending",
                track_shipment: "Zending volgen",
                login: "Inloggen",
                sign_up: "Anmelden",
                myprofile: "Mijn profiel",
                sign_out: "Uitloggen",
                english: "Engels",
                french: "Frans",
                dutch: "Nederlands",

                //Agentmenu
                dashboard: "Dashboard",
                //Deelmenu
                my_orders: "Mijn bestellingen",

                //Dashboard
                hello: "Hallo",
                welcome_back: "Welkom terug!",
                tracking_number: "Volgnummer",
                order_date: "Besteldatum",
                without_vat: "zonder BTW",
                payment: "Betaling",
                commissions: "Commissies",
                status: "Status",
                not_paid: "Niet betaald",
                paid: "Betaald",
                collected: "Geïnd",
                cashed: "In contanten",
                credit_card_s: "Creditcard",
                cash_s: "Contant geld",
                bancontact_s: "Bancontact",
                orders: "Bestellingen",
                my_company: "Mijn bedrijf",
                support: "Ondersteuning en assistentie",
                company_name: "Bedrijfsnaam",
                vat_number: "BTW-nummer",
                commission: "Commissie",
                today_shipment: "Zendingen van vandaag",
                shipment_progress: "Zendingen in uitvoering",
                shipment_deleivred: "Afgeleverde zendingen",
                all_shipment: "Alle zendingen",
                shipments: "Zendingen",
                orders_overview: "Bestellingsoverzicht:",
                profit_this_mounth: "Uw winst deze maand",
                shipments_amount: "Geld verzameld",
                total: "Totaal",
                pay_bank: "Betalen via bankoverschrijving",
                pay_cash: "Betalen met contant geld",
                see_more: "Meer bekijken",
                withdraw_money: "Geld opnemen",
                in_progress: "In uitvoering",
                delivered: "Afgeleverd",
                my_commissions: "Mijn commissies",
                bank_alert: "Betaling via bankoverschrijving",
                bank_alert_txt: "Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!",
                cash_alert: "Betaling met contant geld",
                cash_alert_txt: 'Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!',
                get_money: "Geld opnemen",
                get_money_txt: 'Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!',

                //Inlogpagina
                forgot_password: "Wachtwoord vergeten?",
                dont_have_account: "Heeft u nog geen account?",
                have_account: "Heeft u al een account?",
                reset_password: "Wachtwoord opnieuw instellen",
                login_error: "E-mail of wachtwoord is onjuist",
                password: "Wachtwoord",
                confirm_password: "Bevestig wachtwoord",

                no_orders: "Op dit moment zijn er geen bestellingen op uw account",

                Yes: "Ja",
                No: "Nee",

                //Profiel
                title: "Titel",
                last_login: "Laatste login",
                mr: "Dhr",
                mrs: "Mevr",
                ms: "Mevr",
                update: "Bijwerken",
                update_alert: "Succesvol bijgewerkt!",
                error: "Er is iets misgegaan. Probeer het opnieuw",
                update_title: "Informatie bijwerken",
                update_txt: 'Weet u zeker dat u uw informatie wilt bijwerken?',
                update_now: "Nu bijwerken",
                cancel: "Annuleren",

                sender: "Afzender",
                shipping_label: "Verzendlabel",

                new_password: "Nieuw wachtwoord",
                confirm_new_password: "Bevestig nieuw wachtwoord",

                reset_password_alert: "Wachtwoord bijgewerkt!",

                send_email: "E-mail verzenden",
                send_email_alert: 'E-mail voor het opnieuw instellen van het wachtwoord verzonden!',

                track: 'Volgen',
                nom: "Naam",
                shipping_history: "Verzendgeschiedenis",
                receiver: "Ontvanger",
                date: 'Datum',
                location: "Locatie",
                track_title: 'Ongeldig volgnummer',
                track_txt: 'Ongeldig volgnummer. Probeer het opnieuw. Bedankt!',

                sender_information2: "Afzender",
                receiver_information2: "Ontvanger",
                shipment_info2: "Zending",


                register_mssg: "Account aangemaakt, log in!",


                // waarschuwing verzekering
                important_notice: "Belangrijke Mededeling",
                alert_assurance_message: "Als u geen verzekering kiest, komt u niet in aanmerking voor een terugbetaling bij verlies of schade.",
                add_assurance: "Verzekering toevoegen",
                without_assurance: "Doorgaan zonder verzekering",











                //alerte envoi instance d'expédition
                alert_shipmentinstance: "We hebben de betalingslink naar de klant gestuurd",
                alert_shipmentinstance_message: "Controleer de volgende e-mail om door te gaan met de betaling : ",
                send_payment_link: "Betaallink versturen",

                create_new_meeting: "Nieuwe vergadering maken",
                join_your_meeting: "Neem deel aan uw vergadering",

                //succes
                cong: "Gefeliciteerd!",
                package_safe_hands: "Uw pakket is nu in goede handen.",
                once_your_package: "U kunt uw pakket volgen door op de knop hieronder te klikken:",
                track_your_package: "Volg uw pakket via drie handige opties:",
                thr_whatsapp: "Via WhatsApp",
                thr_sms: "Via SMS",
                thr_website: "Op onze website",
                whts_desc: "Stuur ons alstublieft uw volgnummer via WhatsApp naar:",
                provide_infor: "Wij zullen u real-time updates verstrekken over de status van uw pakket.",
                sms_desc: "Stuur ons alstublieft uw volgnummer via een sms-bericht naar:",
                website_desc: "Om uw pakket te volgen, voer alstublieft het volgnummer in op onze volgpagina.",
                your_satistfaction: "Aarzel niet om contact met ons op te nemen als u verdere assistentie nodig heeft. Uw tevredenheid is onze prioriteit!",

                business_day: "werkdag",
                delivery_time: "Levertijd",

                your_address: "Jouw adres",
                service_point_address: "Adres servicepunt",
                open_google_maps: "Openen in Google Maps",

                welcome_message: "De wereld ligt aan uw deur met een klik!",
                sous_welcome_message: "Welkom in het nieuwe tijdperk van bezorging met Eurêkali",

                change_myAddresse: "Geen servicepunt in de buurt? Wijzig uw adres",

                //footer
                about_us: "Over ons",
                terms_of_use: "Gebruiksvoorwaarden",
                privacy_policy: "Privacybeleid",
                accessibility_statement: "Toegankelijkheidsverklaring",
                all_rights_resereved: "Alle rechten voorbehouden",

                home_collection: "Thuis ophalen",
                servicepoint_collection: "Eurekali servicepunt",
                servicepoint_collection_text: "Lever het in bij een Eurekali-punt",
                home_collection_text: "Haal het pakketje bij mij op",
                drop_off_option: "Kies de ophaallocatie :",

                your_feedback: "Schrijf alstublieft uw feedback...",
                send: "Verzenden",
                give_feedback: "Geef ons alstublieft feedback",
                thanks_feedback: "Dank u voor uw feedback",
                how_was_your_experience: "Hoe was uw ervaring met het nieuwe Eurêkali-systeem?",

                call_us: "Bel ons",
                click_here: "Klik hier als u hulp nodig heeft",

                // Startpagina agent
                join_us: "Doe mee",
                become_agent: "Word vandaag een Eurekali-agent en ontdek hoe uw partnerschap uw bedrijf naar succes kan brengen!",
                why_agent: "Waarom zijn onze agenten blij om een Eurekali-punt te zijn?",
                parg1_title: "1 - Trek meer klanten aan",
                parg1: "Word een Eurekali-servicepunt en verander uw winkel in een activiteitencentrum voor degenen die iets moeten verzenden. Dit is een geweldige kans om het verkeer te vergroten en nieuwe mensen kennis te laten maken met uw bedrijf.",
                parg2_title: "2 - Verhoog uw inkomen",
                parg2: "Als partner van Eurêkali kunt u voor elk pakket dat u beheert, een commissie van 10% verdienen (€1,8 tot €32 per zending). Het is een eenvoudige en effectieve manier om een nieuwe inkomstenbron aan uw bedrijf toe te voegen.",
                parg3_title: "3 - Verbeter uw klantenservice",
                parg3: "Het aanbieden van Eurêkali-diensten betekent het leven van uw klanten gemakkelijker maken. Of ze nu nationaal of internationaal pakketten willen verzenden of ontvangen, ze kunnen het doen zonder uw winkel te verlaten. Een goede klantenservice betekent vaak loyale klanten!",
                parg4_title: "4 - Versterk uw reputatie",
                parg4: "Samenwerken met Eurekali, een gerespecteerd merk in de verzendindustrie, kan de geloofwaardigheid van uw winkel vergroten en het vertrouwen van uw klanten versterken. Toon dit partnerschap met trots en geniet van de voordelen die het kan bieden voor uw imago.",
                register: "Registreer",

                //home page
                with_our_network: "Met ons uitgebreide netwerk van servicepunten kunt u uw pakketten zelf eenvoudig verzenden",
                become_success_agnt: "Word vandaag nog een Eurekali-agent en ontdek hoe uw partnerschap uw bedrijf naar succes kan stuwen!",
                company_text: "Met Eurekali optimaliseert u de prestaties van uw pakketlogistiek met een unieke service die is toegewijd aan uw activiteit",
                particular: "Particulier",
                particular_box: "Verstuur uw pakket in enkele seconden overal ter wereld",
                service_point_box: "Word een Eurekali Point en stuw uw bedrijf naar succes",
                business: "Bedrijf",
                business_box: "Optimaliseer uw pakketlogistiek met een toegewijde service",
                start: "Start",
                track_your_shipment: "Volg uw zending",
                eurekali_resume: "Eurekali, als bezorgexperts, zet zich in voor het leveren van een op maat gemaakte service voor alle profielen (individuen, servicepunten, bedrijven). Ons doel is om de ideale oplossing te selecteren voor het verzenden van uw pakketten, rekening houdend met de volgende criteria",
                unbeatable_low_prices: "Onverslaanbare lage prijzen",
                fast_delivery_times: "Snelle leveringstijden",
                privileged_contact: "Bevoorrecht contact",
                guarantee_against_delays: "Garantie tegen vertragingen",
                eurekali_reliable: "Eurekali: uw betrouwbare en betaalbare verzendoplossing",
                eurekali_resume2: "Eurekali is de ideale partner om aan al uw verzendbehoeften te voldoen. Of u nu een bedrijf bent dat op zoek is naar efficiënte logistieke oplossingen of een particulier die een pakket wil versturen, wij staan voor u klaar. Onze bezorgdienst biedt kosteneffectieve en betrouwbare verzendoplossingen die geschikt zijn voor alle soorten zendingen.",
                users_review_text1: "De meningen van onze klanten over Eurekali",
                users_review_text2: "Vertrouwen en tevredenheid!",

                contact: "Contact",
                text1_home: "Professioneel of persoonlijk",
                text2_home: "Verspil geen tijd meer",
                text3_home: "met het zoeken naar een vervoerder",
                text4_home: "Kies Eurêkali voor een betrouwbare alliantie van vervoerders en vereenvoudigd logistiek beheer",
                make_shipment: "STUUR EEN ZENDING",
                ask_quote: "VRAAG EEN OFFERTE",
                become_partner: "EEN PARTNER WORDEN",
                header_text1: "Eurekali transformeert uw verwachtingen in werkelijkheid,",
                header_text2: "door zijn oplossingen aan te passen aan uw vereisten",
                element_title1: "Kostbare Tijd",
                element_contenu1: "Bespaar tijd door de levering te vinden die aan uw behoeften voldoet, met de vrijheid om op elk moment te veranderen...",
                button_text: "Meer zien",
                element_title2: "Gevraagde Kwaliteit",
                element_contenu2: "Eendracht maakt macht. Door de behoeften van onze klanten te bundelen, beïnvloeden we vervoerders richting hoogwaardige diensten...",
                element_title3: "Onderhandelde Tarieven",
                element_contenu3: "Samenvoegen van bestellingen voor voordelige tarieven. Profiteer van de kracht van de groep om uw uitgaven te verminderen...",
                element_title4: "Multiservice Flexi",
                element_contenu4: "Kiezen uit meer dan 10.000 vervoerders in België is complex. Wij selecteren de beste voor u...",
                header_text3: "Ontketen de Kracht met Eurêkali",
                header_text4: "Uw Alles-in-één Oplossing",
                solution_title1: "Een contract",
                solution_contenu1: "Bied een eenvoudig en herroepbaar contract aan om samen te werken met al onze logistieke partners.",
                solution_title2: "Een platform",
                solution_contenu2: "Centraliseer uw bestellingen, verzendingen en tracking op één gebruiksvriendelijk platform.",
                solution_title3: "Een contact",
                solution_contenu3: "Profiteer van een toegewijd VIP-contactpersoon om al uw zendingen te begeleiden en te superviseren.",
                solution_title4: "Een factuur",
                solution_contenu4: "Vereenvoudig uw boekhouding met geconsolideerde facturatie voor al uw transacties.",
                eurekali_name: "Eurêkali",
                eurekali_name_description: "Leider in België sinds 2013",
                eurekali_presentation: "Eurêkali, leider in België sinds 2013, biedt op maat gemaakte logistieke oplossingen om te voldoen aan uw verzendbehoeften, of u nu een particulier, e-commerce verkoper, klein bedrijf of multinational bent.",
                satisfied_clients: "Duizenden Tevreden Klanten",
                satisfied_clients_description: "Velen van hen hebben we gevraagd hoe tevreden ze waren met onze diensten.",
                satisfied_clients_description2: "Hier zijn hun verklaringen",
                button_text2: "EEN REVIEW GEVEN",
                user_name1: "Marc B. B2B",
                user_review1: "Vroeger was het volgen van zendingen ingewikkeld met slechts één vervoerder. Vandaag, dankzij Eurêkali die al onze vervoerders beheert, verloopt alles soepel en efficiënt.",
                user_name2: "Amina R. B2B",
                user_review2: "Een vervoerder vinden die aan onze eisen voldoet, is bijna onmogelijk; Eurêkali is erin geslaagd.",
                user_name3: "Alan D. C2C",
                user_review3: "In een lastige situatie zorgde Eurêkali voor een snelle en veilige levering van belangrijke documenten, waardoor ik werd gered. Een professionele service die ik aanbeveel.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Samenwerken met meerdere vervoerders betekent veel contacten en verschillende facturen. Met Eurêkali besparen we kostbare tijd door deze processen te consolideren.",

                suivant: "Volgende",
                nom_entreprise: "Bedrijfsnaam?",
                secteur_activite: "Bedrijfssector?",
                contact_principale: "Primaire contactpersoon (Naam en functie)?",
                address_email: "E-mailadres?",
                phone_number: "Telefoonnummer",
                repondez_ici: "Antwoord hier...",
                question1: "Wat is de frequentie van uw behoeften op het gebied van levering?",
                question1_choice1: "Dagelijks",
                question1_choice2: "Wekelijks",
                question1_choice3: "Maandelijks",
                question1_choice4: "Anders",
                question2: "Wat is het gemiddelde volume van uw zendingen? (Aantal pakketten of geschat gewicht per zending)",
                question3: "Wat zijn de belangrijkste bestemmingen van uw zendingen?",
                question3_choice1: "Nationaal",
                question3_choice2: "Internationaal",
                question3_choice3: "Beide",
                question4: "Welk type goederen verzendt u?",
                question4_choice1: "Documenten",
                question4_choice2: "Pakketten",
                question4_choice3: "Pallets",
                question4_choice4: "Speciale Goederen",
                question5: "Zijn er specifieke eisen voor uw goederen? (Breekbaar, temperatuurgecontroleerd, gevaarlijk, etc.)",
                question5_choice1: "Ja",
                question5_choice2: "Nee",
                question6: "Naar welke bezorgdiensten bent u op zoek?",
                question6_choice1: "Standaard Levering",
                question6_choice2: "Express",
                question6_choice3: "Zelfde Dag",
                question7: "Heeft u aanvullende diensten nodig? (Verzekering, real-time tracking, levering met handtekening, etc.)",
                question7_choice1: "Ja",
                question7_choice2: "Nee",
                question8: "Heeft u ooit gebruik gemaakt van express leveringsdiensten?",
                question8_choice1: "Ja",
                question8_choice2: "Nee",
                question9: "Wat zijn uw belangrijkste criteria bij het kiezen van een bezorgdienst? (Kosten, betrouwbaarheid, snelheid, klantenservice, etc.)",
                question10: "Heeft u specifieke verwachtingen of zorgen met betrekking tot de bezorgdienst?",
                question11: "Wat is uw geschatte budget voor bezorgdiensten?",
                question12: "Wat zijn uw voorkeuren voor facturering en betaling?",
                thanks_message1: "Bedankt voor het aanvragen van een offerte.",
                thanks_message2: "Een van onze branchedeskundigen verwerkt uw aanvraag. U wordt binnenkort gecontacteerd om uw bezorgbehoeften en onze oplossingen te bespreken.",
                agent: "Agent",
                transporter: "Vervoerder",
                why_question1: "Waarom kiest",
                why_question2: "voor Eurekali?",

                //B2B-pagina
                b2b_title1: "Verspil geen tijd met het zoeken naar een vervoerder voor uw bedrijf.",
                b2b_title2: "Kies Eurêkali voor een betrouwbare alliantie van vervoerders en vereenvoudigd logistiek beheer.",
                b2b_title3: "Klik, Vergelijk, Verstuur en Glimlach!",
                who_are_we: "Wie zijn wij?",
                b2b_title4: "Goedgekeurd door Toonaangevende Bedrijven",
                b2b_title5: "Ontdek de Geheimen van hun Tevredenheid",
                sender: "Verzender",
                sender_title: "Vereenvoudigde Verzending: Vind de Ideale Vervoerder",
                sender_contenu: "Onze klanten waarderen de gemakkelijke toegang tot een breed scala aan vervoerders via ons Eurekali-platform. Door gepersonaliseerde opties te bieden voor elke zending en zorg te dragen voor tijdige levering, maken we de verzendervaring moeiteloos, waarbij klantenloyaliteit wordt opgebouwd door onze flexibiliteit en betrouwbaarheid.",
                read_more: "Lees meer",
                mail_room: "Receptionist / Postkamer",
                mail_room_title: "Optimaal Beheer van Zendingen: Keuze en Efficiëntie",
                mail_room_contenu: "Zendingbeheer is gestroomlijnd met Eurekali. Onze receptionisten en postkamermedewerkers gebruiken ons platform om te kiezen uit een verscheidenheid aan vervoerders en voldoen effectief aan specifieke verzoeken van elke klant. Deze persoonlijke aanpak leidt tot een verhoogde klanttevredenheid.",
                accountant: "Accountant",
                accountant_title: "Financieel Beheer van Transport: Duidelijkheid en Flexibiliteit",
                accountant_contenu: "Ons Eurekali-platform helpt boekhoudafdelingen transportkosten effectief te beheren. Met duidelijk zicht op verschillende vervoersopties en transparante facturering helpen we klanten strenge financiële controle te behouden, wat hun vertrouwen en tevredenheid versterkt.",
                ceo: "CEO",
                ceo_title: "Innovatieve Transportstrategie: Voorkeurspartner",
                ceo_contenu: "Leiders vinden in Eurekali een strategische partner om transportdiensten te optimaliseren. Door een ruime keuze aan vervoerders aan te bieden en te focussen op operationele efficiëntie en kostenbeheer, zorgt ons platform voor een uitzonderlijke ervaring, versterkt klantenloyaliteit en tevredenheid.",
                partners_trust_us: "Partners die vertrouwen op onze diensten.",
                say_about_us: "Wat zeggen onze partners over ons?",
                b2b_title6: "Uw domein, uw succes. Transport, onze expertise.",
                b2b_title7: "Sluit u vandaag bij ons aan en transformeer uw verzendervaring!",

                company_info: "Bedrijfsinformatie",
                company_name_trans: "Naam van het Transportbedrijf:",
                company_address: "Adres van het Hoofdkantoor:",
                company_year: "Oprichtingsjaar:",
                company_contact: "Naam en Positie van Hoofdcontactpersoon:",
                company_address_trans: "E-mailadres van Contactpersoon:",
                company_phone_trans: "Telefoonnummer van Contactpersoon:",
                company_structure: "Bedrijfsstructuur:",
                company_capacity: "Capaciteiten en Middelen",
                vehicles_number: "Aantal Transportvoertuigen:",
                vehicles_type: "Beschikbare Voertuigtypen:",
                max_load: "Maximale Laadcapaciteit per Voertuig:",
                service_zone: "Geografisch Servicegebied:",
                service_availability: "Beschikbaarheid voor Nationale/Internationale Diensten:",
                placeholder1: "Onafhankelijk, Bedrijf...",
                placeholder2: "Vrachtwagen, Bestelwagen, enz...",
                experience_certification: "Ervaring en Certificering",
                pricipe_sectors: "Belangrijkste Bediende Sectoren:",
                placeholder3: "bijv., medisch, e-commerce, industrieel",
                experience_transport: "Ervaring in Express- of Standaardtransport:",
                certifications: "Specifieke Certificeringen of Accreditaties:",
                placeholder4: "bijv., ISO, ADR voor transport van gevaarlijke stoffen",
                references: "Referenties van Vorige Klanten of Partners:",
                services_offers: "Specifieke Diensten en Aanbiedingen",
                propossed_services: "Voorgestelde Speciale Diensten:",
                placeholder5: "bijv., levering op dezelfde dag, temperatuur gecontroleerd",
                flexibility: "Flexibiliteit en Aanpasbaarheid van Diensten:",
                pricing_cost: "Tarieven en Kostenstructuur:",
                other_information: "Overige Informatie en Opmerkingen",
                why_choose_us_trans: "Waarom wilt u zich aansluiten bij ons consortium van transportbedrijven?",
                what_your_expectation: "Wat zijn uw verwachtingen qua samenwerking?",
                thanks_transporter: "Dank je wel voor het invullen van het formulier. We hebben jouw aanvraag ontvangen, en een van onze teamleden zal binnenkort contact met je opnemen.",

                coworking_header: "Vergroot de horizon van je coworkingruimte met Eurêkali",
                coworking_sheader: "Efficiënte Verzending voor de Gehele Gemeenschap",
                coworking_section1_title: "Hoe kan Eurekali de ervaring van je coworkingruimte verbeteren?",
                coworking_section1_par1_title: "Breng je coworkingruimte naar een hoger niveau",
                coworking_section1_par1_desc: "Met Eurekali als een natuurlijke uitbreiding van je kantoorruimte verhuurservices, bied je je gemeenschap veel meer dan alleen een werkplek. Met ons geïntegreerde verzendplatform kunnen je klanten het gemak hebben om hun zendingen rechtstreeks vanuit jouw ruimte te beheren, wat onschatbare waarde toevoegt aan je dienstverlening.",
                coworking_section1_par2_title: "Exclusief Partnerschap met Leiders in Transport",
                coworking_section1_par2_desc: "Door Eurekali als je verzendpartner te kiezen, krijg je automatisch toegang tot ons netwerk van wereldwijd gerenommeerde transportpartners. Onze strategische relaties met de grootste transportbedrijven garanderen betrouwbare en efficiënte verzendoplossingen voor je klanten, waar ze zich ook ter wereld bevinden.",
                coworking_section2_title: "Voordelen voor je coworkinggemeenschap",
                coworking_advn1_title: "Comfort en Toegankelijkheid",
                coworking_advn1_desc: "Bied je leden het gemak om hun pakketten rechtstreeks vanuit hun werkruimte te verzenden, waardoor de moeite van reizen naar externe verzendcentra wordt geëlimineerd.",
                coworking_advn2_title: "Voorkeurstarieven",
                coworking_advn2_desc: "Als partner van Eurekali profiteert je gemeenschap van voorkeurstarieven voor verzendservices, waardoor ze worden aangemoedigd om deze faciliteit regelmatig te gebruiken.",
                coworking_advn3_title: "Vereenvoudigd Volgen",
                coworking_advn3_desc: "Met ons gebruiksvriendelijke platform kunnen je leden hun pakketten in realtime volgen, wat totale gemoedsrust biedt over de veiligheid van hun zendingen.",
                coworking_advn4_title: "Flexibiliteit en Keuze",
                coworking_advn4_desc: "Of het nu gaat om binnenlandse of internationale zendingen, belangrijke documenten of omvangrijke pakketten, Eurekali biedt een uitgebreid scala aan verzendservices om aan alle behoeften van je gemeenschap te voldoen.",
                coworking_joinus_par1: "Doe nu mee!",
                coworking_joinus_par2: "Integreer Eurekali in je dienstenaanbod en bied je gemeenschap een ongeëvenaarde coworkingervaring.",
                coworking_joinus_par3: "Ons team staat klaar om met je samen te werken om je coworkingruimte naar een hoger niveau te tillen.",
                coworking: "Coworking",

                contactus_header: "Neem contact op",
                contactus_sheader1: "Welkom bij Eurekali, experts in snelle levering",
                contactus_sheader2: "Hulp nodig? Neem contact met ons op!",
                contactus_form_input1: "Naam",
                contactus_form_input2: "Bedrijf",
                contactus_form_input3: "Telefoon",
                contactus_form_input4: "E-mail",
                contactus_form_input5: "Onderwerp",
                contactus_form_input6: "Bericht",
                contactus_form_button: "Verzenden",
                contactus_text1: "Dedicated telefoonnummer voor onmiddellijke hulp:",
                contactus_text2: "Ons team is beschikbaar",
                contactus_text3: "Om snel op uw dringende zorgen te reageren.",
                contactus_message: "Bedankt, we zullen binnenkort contact met u opnemen",

                about_us_title: "Bedrijfsgeschiedenis",
                about_us_sous_title: "Sinds onze oprichting in 2013 hebben we vele belangrijke mijlpalen bereikt:",
                about_us_2013: "Oprichting van Eurêkali, met een visie om de logistiek in België te revolutioneren.",
                about_us_2015: "Introductie van ons geïntegreerde platform voor bestellingen, tracking en facturatie.",
                about_us_2018: "Uitbreiding van ons netwerk van vervoerders om meer servicekeuzes te bieden.",
                about_us_2020: "Lancering van onze mobiele app voor nog toegankelijker logistiek beheer.",
                about_us_2023: "Erkenning als leider op de Belgische en Europese logistieke markt.",
                about_us_2024: "Strategische uitbreiding van onze aanwezigheid op de Afrikaanse markt.",
                mission_and_vision: "Missie en visie",
                mission: "Missie",
                mission_desc: "Op maat gemaakte logistieke oplossingen bieden om aan uw verzendbehoeften te voldoen in de B2B-, B2C- en C2C-wereld, terwijl we een kwaliteitsservice en totale tevredenheid garanderen.",
                vision: "Visie",
                vision_desc: "De essentiële referentie worden voor logistieke oplossingen in Europa en andere continenten, door voortdurend te innoveren om aan de veranderende behoeften van onze klanten te voldoen.",
                about_eurekali: "Over Eurêkali",
                about_eurekali_title: "Eurêkali, uw geïntegreerde oplossing sinds 2013",
                about_eurekali_title_desc: "Welkom bij Eurêkali, de logistieke leider in België en Europa sinds 2013. Wij zijn een innovatieve marktplaats die de belangrijkste spelers in de logistiek op één platform samenbrengt. Ons doel is uw logistieke operaties te vereenvoudigen en te optimaliseren, of u nu een particulier, een e-commerce handelaar, een klein bedrijf of een multinational bent.",
                about_us_question: "Neem contact met ons op als u hulp of antwoorden op uw vragen nodig heeft",

                element_drop_contenu1: "Bespaar tijd door de levering te vinden die aan uw behoeften voldoet, met de vrijheid om op elk moment te veranderen. Het zoeken naar een vervoerder, onderhandelen en opstarten kost aanzienlijk veel tijd, om nog maar te zwijgen van het risico om teleurgesteld te worden door de geleverde service. Met onze oplossing vermijdt u deze problemen en geniet u van een efficiënte en betrouwbare levering, met het voordeel van een breed scala aan zorgvuldig geselecteerde vervoerders om aan uw behoeften te voldoen.",
                element_drop_contenu2: "Eendracht maakt macht. Door de behoeften van onze klanten te bundelen, beïnvloeden we vervoerders naar hoogwaardige diensten. Het massa-effect stelt ons in staat directe toegang te hebben tot de operators van verschillende vervoerders. Ze beschouwen ons als VIP's wanneer we contact met hen opnemen, of het nu gaat om specifieke verzoeken of probleemoplossing. U profiteert volledig van dit massa-effect.",
                element_drop_contenu3: "Groepsbestellingen voor voordelige tarieven. Profiteer van de kracht van de groep om uw uitgaven te verminderen. Dankzij ons bestellingsvolume onderhandelen we over voorkeurstarieven met vervoerders, zodat u kunt profiteren van verlaagde prijzen zonder concessies te doen aan de kwaliteit van de service.",
                element_drop_contenu4: "Kiezen uit meer dan 10.000 vervoerders in België is complex. Wij selecteren de beste voor u, rekening houdend met uw specifieke behoeften. Met onze flexibele service heeft u toegang tot een breed scala aan opties en de vrijheid om op elk moment van vervoerder te veranderen, waardoor u een op maat gemaakte en zorgeloze oplossing krijgt.",

                give_review: "Geef een beoordeling",
                write_review: "Schrijf een beoordeling",
                reviews: "Beoordelingen",
                stars: "Stars",
                clients_reviews_text1: "De beoordelingen van onze klanten over Eurêkali",
                clients_reviews_text2: "Vertrouwen en tevredenheid!",

                give_review_alert1: "Bitte geben Sie Ihren Namen ein",
                give_review_alert2: "Bitte wählen Sie mindestens einen Stern",

                contact_us_alert1: "Voer de naam in",
                contact_us_alert2: "Voer het bedrijf in",
                contact_us_alert3: "Voer het telefoonnummer in",
                contact_us_alert4: "Voer het e-mailadres in",
                contact_us_alert5: "Voer het onderwerp in",
                contact_us_alert6: "Voer het bericht in",

                transporters_title: "Welkom op ons platform voor transporteurs!",
                transporters_sub_title: "Of u nu gespecialiseerd bent in het vervoer van goederen, personen, of bezorgdiensten, onze marktplaats is voor u ontworpen. Door lid te worden van ons netwerk krijgt u toegang tot een grote klantenbasis die op zoek is naar uw diensten.",
                transporters_partners: "Onze vertrouwde partnerschappen:",
                join_our_marketplace: "Word lid van onze transporteurs marktplaats",
                new_opport: "Profiteer van nieuwe kansen en groei uw bedrijf",
                opp1_title: "1 - Verhoogde zichtbaarheid:",
                opp1_contenu: "Vergroot uw online zichtbaarheid en bereik meer potentiële klanten.",
                opp2_title: "2 - Vereenvoudigd beheer:",
                opp2_contenu: "Gebruik onze intuïtieve interface om uw boekingen en planning te beheren.",
                opp3_title: "3 - Ondersteuning en hulp:",
                opp3_contenu: "Profiteer van onze technische en commerciële ondersteuning om uw activiteiten te optimaliseren.",
                transporter_join_now: "Meld u nu aan en begin transportverzoeken te ontvangen!",

                faqs_title: "Veelgestelde vragen",
                faq_question1: "Hoe en wanneer ontvang ik mijn verzenddocumenten?",
                faq_answer1: "Zodra de bestelling is bevestigd en betaald op onze website www.eurekali.com, zijn de vereiste verzenddocumenten direct beschikbaar om te downloaden in PDF-formaat vanuit uw orderbevestigingsmail. Dit omvat een afleveringsbon voor Europese zendingen, en voor internationale zendingen buiten de EU met commerciële waarde, een afleveringsbon en een pro forma factuur. Daarnaast kan een Ad Valorem verzekeringscertificaat worden gekocht als dit tijdens het bestelproces is geselecteerd. U kunt ook de transportfactuur in PDF-formaat downloaden vanuit uw orderbevestigingsmail. Ten slotte, als u de juiste opties hebt geselecteerd, worden er meldingen per e-mail of sms gestuurd naar de persoon die is opgegeven in het gedeelte met de bestemmingsadres.",
                faq_question2: "Waarom zijn uw tarieven lager dan op de websites van de vervoerders zelf?",
                faq_answer2: "Eurêkali kan haar klanten betere deals aanbieden omdat het de transportstromen van haar vervoerders bundelt. En hoe meer we verzenden, hoe beter de tarieven die we kunnen aanbieden.",
                faq_question3: "Mijn pakket is niet verzonden, mijn pakket is beschadigd, hoe maak ik een claim?",
                faq_answer3: "Als het pakket niet is verzonden, moet u zo snel mogelijk contact opnemen met het telefoonnummer dat in de orderbevestigingsmail is aangegeven, de situatie uitleggen en wachten op feedback. Als de reactie onbevredigend is, vul dan het claimformulier in door hier te klikken. We zullen een onderzoek openen met de door u geselecteerde vervoerder om ervoor te zorgen dat alle nodige stappen worden ondernomen om u tevreden te stellen. Stuur uit voorzorg een aangetekende brief met uw klacht naar de vervoerder op de 15e dag na de oorspronkelijke geplande leverdatum, met bij uw klachtbrief de originele factuur van de verzonden goederen of hun vervangings- of reparatiefactuur. In geval van gedeeltelijke levering of beschadiging van het pakket, controleer het aantal artikelen in het pakket en in de verpakking terwijl u nog bij de bezorger bent, en onderteken de vrachtbrief alleen na het beschrijven van eventuele schade of verlies van artikelen. Neem zo snel mogelijk contact op met het telefoonnummer dat in de orderbevestigingsmail is aangegeven, leg de situatie uit en wacht op feedback. Als de reactie onbevredigend is, vul dan het claimformulier in door hier te klikken. Stuur binnen 48 uur na levering een aangetekende brief naar de vervoerder, met bij uw klachtbrief de originele factuur van de verzonden goederen of hun vervangings- of reparatiefactuur. Bewaar uit voorzorg het pakket en de verpakking.",
                faq_question4: "Hoe kan ik een vergoeding claimen bij vertraging van mijn zending?",
                faq_answer4: "Stuur uit voorzorg een aangetekende brief naar de vervoerder. De vergoeding geldt uitsluitend voor de transportkosten en dekt niet de kosten van de goederen. We raden u aan de algemene voorwaarden van de gekozen vervoerder te raadplegen om te weten wat de precieze vereisten zijn om compensatie te claimen voor eventuele vertragingen.",
                faq_question5: "Kunnen we verhuizen naar ons nieuwe huis met Eurêkali?",
                faq_answer5: "U kunt verhuizen naar een nieuwe plaats met Eurêkali, mits de zending correct is gedocumenteerd met het exacte aantal items dat door de verhuizer moet worden opgehaald.",
                faq_question6: "Hoe kan ik meerdere zendingen tegelijkertijd bestellen?",
                faq_answer6: "Als u meerdere pakketten naar dezelfde bestemming moet verzenden, kunt u uw pakketten groeperen. Op de startpagina moet u eenvoudigweg de benodigde extra regels maken. Als de bestemmingsadressen van uw pakketten verschillend zijn, moet u afzonderlijke bestellingen plaatsen.",
                faq_question7: "Is het beter om één groot pakket of meerdere kleine pakketten te verzenden?",
                faq_answer7: "Het is altijd beter om de vervoerders meerdere kleinere pakketten van gemiddeld gewicht te laten verzorgen dan één groot pakket waarvoor twee personen of een trolley nodig zijn voor de hantering. De prijs is vaak gelijkwaardig, maar het risico op beschadiging van de pakketten is lager als u ze splitst, omdat ze gemakkelijker te hanteren zijn.",
                faq_question8: "Wat zijn de drie dingen die ik moet doen bij ophalen en levering voordat ik een pakket aan een vervoerder overhandig of het van hem in ontvangst neem?",
                faq_answer8: "Controleer of het gewicht en de afmetingen van het pakket correct zijn. Als er verkeerde informatie is, kan de zending worden geblokkeerd in het magazijn van de vervoerder en wordt het terugroepen op uw kosten zonder compensatie voor de betaalde dienst. Controleer of de inhoud van uw pakket is toegestaan voor verzending en goed is verpakt. De inhoud mag niet verboden zijn; raadpleeg de lijst 'Wat kan ik niet verzenden?' evenals de algemene voorwaarden van Eurêkali en de vervoerder. Het pakket moet stevig zijn verpakt om ervoor te zorgen dat de inhoud niet beschadigd raakt tijdens de hantering. Als de afzender de verpakkingsinstructies niet respecteert, wordt het pakket geblokkeerd in het magazijn van de vervoerder en wordt het terugroepen op uw kosten zonder compensatie voor de betaalde dienst. Controleer of de ophaal- en bestemmingsadressen volledig en correct zijn. Deze moeten volledig worden gecommuniceerd. Als de vervoerder niet gemakkelijk toegang heeft tot de vermelde adressen, kan hij ervan uitgaan dat zijn leveringsopdracht is voltooid en wordt uw pakket bewaard in een magazijn, waarbij het terugroepen wordt gefactureerd aan de afzender. Vertragingen als gevolg van een ontbrekende persoon op de bestemming of een onvolledig adres worden niet beschouwd als de verantwoordelijkheid van de vervoerder. Om vertragingen of gemiste leveringen te voorkomen, moet de gebruiker de telefoonnummers van de contactpersonen voor ophalen en bestemming opgeven en alle nuttige informatie voor ophalen of levering doorgeven.",
                faq_question9: "Wat zijn de geaccepteerde betaalmethoden?",
                faq_answer9: "U kunt uw zendingen betalen met VISA of MasterCard via het online betalingssysteem Ogone. Als u een gebruikersaccount aanmaakt, worden uw factuurgegevens opgeslagen en kunt u gebruik maken van automatische incasso. Als bedrijf kunt u toegang krijgen tot een volledig geïntegreerd maandelijks facturatiesysteem dat aansluit op uw eigen betalingssysteem en accounts, mits onze financiële afdeling uw aanvraag goedkeurt.",
                faq_question10: "Met welke vervoerders werkt Eurêkali samen?",
                faq_answer10: "Wij hechten het grootste belang aan de tevredenheid van onze klanten. Met dit doel voor ogen hebben we vervoerders geselecteerd die bekend staan om hun kwaliteitsservice: TNT, GLS, IPS, DHL, FEDEX, Alpha, PMC, Number One, Ecopostale. De lijst wordt voortdurend bijgewerkt. De kwaliteitscontroleafdeling van Eurêkali analyseert zorgvuldig alle nieuwe mogelijkheden om samen te werken met bedrijven uit andere sectoren, zodat we u vergelijkbare voorwaarden kunnen bieden in zoveel mogelijk gespecialiseerde vakgebieden."










            }
        }
    }
}
