<template>
    <div ref="scrollTarget"></div>

    <section class="laptop-section">
        <section style="padding-bottom: 50px; height: 750px;"
            :style="{ backgroundImage: 'url(' + backgroundImage11 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <MainNav />

            <div class="container"
                style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 350px;">
                    <div class="col-md-11">
                        <span
                            style="color: white; font-size: 2.7em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                            {{ translate('text1_home') }}
                        </span><br>
                        <span
                            style="color: white; font-size: 2.7em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                            {{ translate('text2_home') }}
                        </span><br>
                        <span
                            style="color: white; font-size: 2.7em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                            {{ translate('text3_home') }}
                        </span><br>
                        <span
                            style="color: white; font-size: 1.5em; font-weight: 500; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                            {{ translate('text4_home') }}
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center text-center"
                    style="margin-bottom: -220px; margin-top: 120px; width: 100%;">
                    <div class="col-md-3 position-relative">
                        <div class="service-box">
                            <img src="../assets/img/home_icon3.svg">
                            <p style="margin-top: 5px;">{{ translate('make_shipment') }}</p>
                            <div class="dropdown-menu-custom">
                                <ul>
                                    <li><router-link to="/b2b" style="color: white; font-weight: 600;">{{
                                        translate('business') }}</router-link></li>
                                    <li><a href="https://demo.eurekali-point.com"
                                            style="color: white; font-weight: 600;">{{
                                                translate('particular') }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <router-link to="/quote">
                            <div class="service-box">
                                <img src="../assets/img/home_icon2.svg">
                                <p style="margin-top: 5px;">{{ translate('ask_quote') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 position-relative">
                        <div class="service-box">
                            <img src="../assets/img/home_icon1.svg">
                            <p style="margin-top: 5px;">{{ translate('become_partner') }}</p>
                            <div class="dropdown-menu-custom">
                                <ul>
                                    <li><router-link to="/coworking" style="color: white; font-weight: 600;">{{
                                        translate('coworking') }}</router-link></li>
                                    <li><router-link to="/transporters" style="color: white; font-weight: 600;">{{
                                        translate('transporter') }}</router-link></li>
                                    <li><a href="https://demo.eurekali-point.com/agent"
                                            style="color: white; font-weight: 600;">{{ translate('agent') }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 20px; padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="color: black; font-weight: 600; font-size: x-large;">
                        <div class="text-center">
                            {{ translate('why_question1') }}
                        </div>
                        <div class="text-center" style="height: 100px;">
                            <img :src="currentImage" style="height: 100%; width: 200px; object-fit: contain;">
                        </div>
                        <div class="text-center">
                            {{ translate('why_question2') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item"
                                style="width: 560px; height: 300px; border-radius: 20px;"
                                src="https://www.youtube.com/embed/nrstTmxF1io?si=SB3-o57N2d8l59sL&amp;controls=0"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
            <div class="container" style="color: black; text-align: center; font-size: x-large; font-weight: 600;">
                {{ translate('header_text1') }}<br>
                {{ translate('header_text2') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center">
                    <div class="col-md-3">
                        <div class="border"
                            style="border-radius: 20px; background-color: white;">
                            <div>
                                <img src="../assets/img/time.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                    translate('element_title1')
                                }}</span>
                                <br>
                                <span v-if="!expandedIndex1">{{ translate('element_contenu1') }}</span>
                                <span v-if="expandedIndex1"
                                    :class="['expandable-content', { 'expanded': expandedIndex1 }]">{{
                                        translate('element_drop_contenu1') }}</span>
                            </div>
                            <div class="mt-auto">
                                <template v-if="expandedIndex1">
                                    <img src="../assets/img/open_text.svg"
                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                        @click="expand_element1">
                                </template>
                                <template v-else>
                                    <img src="../assets/img/open_text.svg" style="margin-bottom: 15px; cursor: pointer;"
                                        @click="expand_element1">
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="border"
                            style="border-radius: 20px; background-color: white;">
                            <div>
                                <img src="../assets/img/quality.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                    translate('element_title2')
                                }}</span>
                                <br>
                                <span v-if="!expandedIndex2">{{ translate('element_contenu2') }}</span>
                                <span v-if="expandedIndex2"
                                    :class="['expandable-content', { 'expanded': expandedIndex2 }]">{{
                                        translate('element_drop_contenu2') }}</span>
                            </div>
                            <div class="mt-auto">
                                <template v-if="expandedIndex2">
                                    <img src="../assets/img/open_text.svg"
                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                        @click="expand_element2">
                                </template>
                                <template v-else>
                                    <img src="../assets/img/open_text.svg" style="margin-bottom: 15px; cursor: pointer;"
                                        @click="expand_element2">
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="border"
                            style="border-radius: 20px; background-color: white;">
                            <div>
                                <img src="../assets/img/price.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                    translate('element_title3')
                                }}</span>
                                <br>
                                <span v-if="!expandedIndex3">{{ translate('element_contenu3') }}</span>
                                <span v-if="expandedIndex3"
                                    :class="['expandable-content', { 'expanded': expandedIndex3 }]">{{
                                        translate('element_drop_contenu3') }}</span>
                            </div>
                            <div class="mt-auto">
                                <template v-if="expandedIndex3">
                                    <img src="../assets/img/open_text.svg"
                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                        @click="expand_element3">
                                </template>
                                <template v-else>
                                    <img src="../assets/img/open_text.svg" style="margin-bottom: 15px; cursor: pointer;"
                                        @click="expand_element3">
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="border"
                            style="border-radius: 20px; background-color: white;">
                            <div>
                                <img src="../assets/img/services.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                    translate('element_title4')
                                }}</span>
                                <br>
                                <span v-if="!expandedIndex4">{{ translate('element_contenu4') }}</span>
                                <span v-if="expandedIndex4"
                                    :class="['expandable-content', { 'expanded': expandedIndex4 }]">{{
                                        translate('element_drop_contenu4') }}</span>
                            </div>
                            <div class="mt-auto">
                                <template v-if="expandedIndex4">
                                    <img src="../assets/img/open_text.svg"
                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                        @click="expand_element4">
                                </template>
                                <template v-else>
                                    <img src="../assets/img/open_text.svg" style="margin-bottom: 15px; cursor: pointer;"
                                        @click="expand_element4">
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 70px;">
            <div class="container" style="color: black; text-align: center; font-size: x-large; font-weight: 600;">
                {{ translate('header_text3') }}<br>
                {{ translate('header_text4') }}
            </div>


            <div class="container-fluid"
                style="display: flex; justify-content: center; align-items: center; margin-top: 35px; padding: 0; min-height: 700px;"
                :style="{ backgroundImage: 'url(' + backgroundImage3 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
                <div class="container-fluid" style="padding-right: 100px;">
                    <div class="row text-center justify-content-end">
                        <div class="col-md-2 d-flex">
                            <div class="border d-flex flex-column solution-box">
                                <div>
                                    <img src="../assets/img/one_icon.svg" style="margin-top: -80px;">
                                </div>
                                <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                    <p style="font-weight: bold;">{{ translate('solution_title1') }}</p>
                                    <img style="margin-top: 15px; margin-bottom: 15px;"
                                        src="../assets/img/solution_icon1.svg">
                                    <p>{{ translate('solution_contenu1') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex">
                            <div class="border d-flex flex-column solution-box">
                                <div>
                                    <img src="../assets/img/one_icon.svg" style="margin-top: -80px;">
                                </div>
                                <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                    <p style="font-weight: bold;">{{ translate('solution_title2') }}</p>
                                    <img style="margin-top: 15px; margin-bottom: 15px;"
                                        src="../assets/img/solution_icon2.svg">
                                    <p>{{ translate('solution_contenu2') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex">
                            <div class="border d-flex flex-column solution-box">
                                <div>
                                    <img src="../assets/img/one_icon.svg" style="margin-top: -80px;">
                                </div>
                                <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                    <p style="font-weight: bold;">{{ translate('solution_title3') }}</p>
                                    <img style="margin-top: 15px; margin-bottom: 15px;"
                                        src="../assets/img/solution_icon3.svg">
                                    <p>{{ translate('solution_contenu3') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex">
                            <div class="border d-flex flex-column solution-box">
                                <div>
                                    <img src="../assets/img/one_icon.svg" style="margin-top: -80px;">
                                </div>
                                <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                    <p style="font-weight: bold;">{{ translate('solution_title4') }}</p>
                                    <img style="margin-top: 15px; margin-bottom: 15px;"
                                        src="../assets/img/solution_icon4.svg">
                                    <p>{{ translate('solution_contenu4') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 60px; padding-bottom: 60px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('eurekali_name') }} <br>
                            {{ translate('eurekali_name_description') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('eurekali_presentation') }}
                        </p>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <img src="../assets/img/eurekali_map.png">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 50px; margin-bottom: 120px;"
            :style="{ backgroundImage: 'url(' + backgroundImage4 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-4"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('satisfied_clients') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('satisfied_clients_description') }} <br>
                            {{ translate('satisfied_clients_description2') }}
                        </p>
                        <router-link to="/give-review">
                        <button class="custom-btn4">{{ translate('button_text2') }}</button>
                    </router-link>
                    </div>
                    <div class="col-md-8" style="text-align: right;">
                        <div class="row text-center justify-content-end">
                            <div class="col-md-4" style="margin-top: -70px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image1.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review1') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 100px; margin-left: 30px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image2.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review2') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center justify-content-center">
                            <div class="col-md-4" style="margin-left: 60px">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image3.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review3') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 100px; margin-bottom: -100px; margin-left: 30px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image4.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review4') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <img src="../assets/img/background_home_mobile.png" class="d-block w-100">

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px;">
                    <p style="font-weight: bold; font-size: 1.7em; padding-left: 10px; padding-right: 10px;">{{
                        translate('text1_home') }}</p>
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 10px; padding-right: 10px;">{{
                        translate('text2_home') }}</p>
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 10px; padding-right: 10px;">{{
                        translate('text3_home') }}</p>
                    <p style="font-size: 18px; font-weight: 1.3; padding-left: 10px; padding-right: 10px;">{{
                        translate('text4_home') }}</p>
                </div>
            </header>
        </div>
        <div class="row text-center justify-content-center" style="margin-top: 30px;">
            <div class="col-md-4" style="width: auto;">
                <div class="service-box-mobile" @click="changeFirstButton">
                    <img src="../assets/img/home_icon3_pink.svg">
                    <p style="margin-top: 5px; color: black;">{{ translate('make_shipment') }}</p>
                </div>
                <template v-if="first_button_mobile">
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-6" style="text-align: center;">
                            <div style="background-color: #E5097F; border-radius: 15px">
                                <router-link to="/b2b" style="color: white;">{{ translate('business') }}</router-link>
                            </div>
                        </div>
                        <div class="col-6" style="text-align: center;">
                            <div style="background-color: #E5097F; border-radius: 15px">
                                <a href="https://demo.eurekali-point.com" style="color: white;">{{
                                    translate('particular') }}</a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-md-4" style="width: auto;">
                <router-link to="/quote">
                    <div class="service-box-mobile">
                        <img src="../assets/img/home_icon2_pink.svg">
                        <p style="margin-top: 5px; color: black;">{{ translate('ask_quote') }}</p>
                    </div>
                </router-link>
            </div>
            <div class="col-md-4" style="width: auto;">
                <div class="service-box-mobile" @click="changeSecondButton">
                    <img src="../assets/img/home_icon1_pink.svg">
                    <p style="margin-top: 5px; color: black;">{{ translate('become_partner') }}</p>
                </div>
                <template v-if="second_button_mobile">
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-6" style="text-align: center; margin-top: 10px;">
                            <div style="background-color: #E5097F; border-radius: 15px">
                                <router-link to="/coworking" style="color: white;">{{ translate('coworking')
                                    }}</router-link>
                            </div>
                        </div>
                        <div class="col-6" style="text-align: center; margin-top: 10px;">
                            <div style="background-color: #E5097F; border-radius: 15px">
                                <router-link to="/transporters" style="color: white;">{{ translate('transporter')
                                    }}</router-link>
                            </div>
                        </div>
                        <div class="col-6" style="text-align: center; margin-top: 10px;">
                            <div style="background-color: #E5097F; border-radius: 15px">
                                <a href="https://demo.eurekali-point.com/agent" style="color: white;">{{
                                    translate('agent') }}</a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <section style="padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6"
                        style="color: black; font-size: x-large; font-weight: 600; margin-bottom: 20px; height: 200px;">
                        {{ translate('why_question1') }}
                        <div style="height: 75px; padding: 15px;">
                            <img :src="currentImage" style="max-width: 180px;">
                        </div>
                        {{ translate('why_question2') }}
                    </div>
                    <div class="col-md-6">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item"
                                style="width: 95%; height: 250px; border-radius: 20px;"
                                src="https://www.youtube.com/embed/nrstTmxF1io?si=SB3-o57N2d8l59sL&amp;controls=0"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 50px; min-height: 650px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: #E5097F;">
                {{ translate('header_text1') }}<br>
                {{ translate('header_text2') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/time.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                translate('element_title1')
                                            }}</span>
                                            <br>
                                            <span v-if="!expandedIndex1">{{ translate('element_contenu1') }}</span>
                                            <span v-if="expandedIndex1"
                                                :class="['expandable-content', { 'expanded': expandedIndex1 }]">{{
                                                translate('element_drop_contenu1') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <template v-if="expandedIndex1">
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                    @click="expand_element1">
                                            </template>
                                            <template v-else>
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer;"
                                                    @click="expand_element1">
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/quality.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                translate('element_title2')
                                            }}</span>
                                            <br>
                                            <span v-if="!expandedIndex2">{{ translate('element_contenu2') }}</span>
                                            <span v-if="expandedIndex2"
                                                :class="['expandable-content', { 'expanded': expandedIndex2 }]">{{
                                                translate('element_drop_contenu2') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <template v-if="expandedIndex2">
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                    @click="expand_element2">
                                            </template>
                                            <template v-else>
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer;"
                                                    @click="expand_element2">
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/price.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                translate('element_title3')
                                            }}</span>
                                            <br>
                                            <span v-if="!expandedIndex3">{{ translate('element_contenu3') }}</span>
                                            <span v-if="expandedIndex3"
                                                :class="['expandable-content', { 'expanded': expandedIndex3 }]">{{
                                                translate('element_drop_contenu3') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <template v-if="expandedIndex3">
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                    @click="expand_element3">
                                            </template>
                                            <template v-else>
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer;"
                                                    @click="expand_element3">
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/services.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                translate('element_title4')
                                            }}</span>
                                            <br>
                                            <span v-if="!expandedIndex1">{{ translate('element_contenu4') }}</span>
                                            <span v-if="expandedIndex4"
                                                :class="['expandable-content', { 'expanded': expandedIndex4 }]">{{
                                                translate('element_drop_contenu4') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <template v-if="expandedIndex4">
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                    @click="expand_element4">
                                            </template>
                                            <template v-else>
                                                <img src="../assets/img/open_text.svg"
                                                    style="margin-bottom: 15px; cursor: pointer;"
                                                    @click="expand_element4">
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="container"
            style="color: black; text-align: center; font-size: x-large; font-weight: 600; margin-top: 20px; margin-bottom: 30px;">
            {{ translate('header_text3') }}<br>
            {{ translate('header_text4') }}
        </div>
        <section style="padding-top: 80px; padding-bottom: 100px; height: 700px;"
            :style="{ backgroundImage: 'url(' + backgroundImage6 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container" style="margin-top: 100px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title1') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon1.svg">
                                            <p>{{ translate('solution_contenu1') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title2') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon2.svg">
                                            <p>{{ translate('solution_contenu2') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title3') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon3.svg">
                                            <p>{{ translate('solution_contenu3') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title4') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon4.svg">
                                            <p>{{ translate('solution_contenu4') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px;">
            <div class="container" style="text-align: center;">
                <img src="../assets/img/eurekali_map.png">
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 20px;"
            :style="{ backgroundImage: 'url(' + backgroundImage7 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
            <div class="container" style="text-align: center;">
                <p
                    style="padding-left: 40px; padding-right: 40px; color: #E5097F; font-size: x-large; font-weight: 600;">
                    {{ translate('eurekali_name') }}<br>
                    {{ translate('eurekali_name_description') }}
                </p>
                <br>
                <p style="color: black;">
                    {{ translate('eurekali_presentation') }}
                </p>
            </div>
        </section>

        <section style="padding-top: 40px; padding-bottom: 100px; margin-top: 40px; margin-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage8 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container" style="color: black; text-align: center;">
                <p style="font-size: x-large; font-weight: 600;">
                    {{ translate('satisfied_clients') }}
                </p>
                {{ translate('satisfied_clients_description') }}
                {{ translate('satisfied_clients_description2') }}
                <br>
                <router-link to="/give-review">
                        <button class="custom-btn4" style="margin-top: 30px;">{{ translate('button_text2') }}</button>
                    </router-link>
            </div>

            <div class="container" style="margin-top: 60px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel3" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image1.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review1') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image2.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review2') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image3.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review3') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image4.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review4') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <img src="../assets/img/background_home_ipad.png" class="d-block w-100">

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px; padding: 15px;">
                    <span
                        style="color: white; font-size: 2.5em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                        {{ translate('text1_home') }}
                    </span><br>
                    <span
                        style="color: white; font-size: 2.5em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                        {{ translate('text2_home') }}
                    </span><br>
                    <span
                        style="color: white; font-size: 2.5em; font-weight: bold; font-family: 'YourBoldestFont'; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                        {{ translate('text3_home') }}
                    </span><br>
                    <span
                        style="color: white; font-size: 1.2em; font-weight: 500; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
                        {{ translate('text4_home') }}
                    </span>
                </div>
            </header>
        </div>

        <section style="padding-top: 5px; padding-bottom: 20px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="row text-center justify-content-center"
                style="margin-top: 30px; padding-left: 15px; padding-right: 15px;">
                <div class="col-4">
                    <div class="service-box" @click="changeFirstButton"
                        style="background-color: white; color: black; border: 1px solid #E5097F;">
                        <img src="../assets/img/home_icon3_pink.svg">
                        <p style="margin-top: 5px; color: black;">{{ translate('make_shipment') }}</p>
                    </div>
                    <template v-if="first_button_mobile">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-6" style="text-align: center;">
                                <div style="background-color: #E5097F; border-radius: 15px">
                                    <router-link to="/b2b" style="color: white;">{{ translate('business')
                                        }}</router-link>
                                </div>
                            </div>
                            <div class="col-6" style="text-align: center;">
                                <div style="background-color: #E5097F; border-radius: 15px">
                                    <a href="https://demo.eurekali-point.com" style="color: white;">{{
                                        translate('particular')
                                    }}</a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="col-4">
                    <router-link to="/quote">
                        <div class="service-box"
                            style="background-color: white; color: black; border: 1px solid #E5097F;">
                            <img src="../assets/img/home_icon2_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('ask_quote') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-4">
                    <div class="service-box" @click="changeSecondButton"
                        style="background-color: white; color: black; border: 1px solid #E5097F;">
                        <img src="../assets/img/home_icon1_pink.svg">
                        <p style="margin-top: 5px; color: black;">{{ translate('become_partner') }}</p>
                    </div>
                    <template v-if="second_button_mobile">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-6" style="text-align: center; margin-top: 10px;">
                                <div style="background-color: #E5097F; border-radius: 15px">
                                    <router-link to="/coworking" style="color: white;">{{ translate('coworking')
                                        }}</router-link>
                                </div>
                            </div>
                            <div class="col-6" style="text-align: center; margin-top: 10px;">
                                <div style="background-color: #E5097F; border-radius: 15px">
                                    <router-link to="/transporters" style="color: white;">{{
                                        translate('transporter')
                                    }}</router-link>
                                </div>
                            </div>
                            <div class="col-6" style="text-align: center; margin-top: 10px;">
                                <div style="background-color: #E5097F; border-radius: 15px">
                                    <a href="https://demo.eurekali-point.com/agent" style="color: white;">{{
                                        translate('agent') }}</a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12 text-center"
                        style="height: 200px; color : black; font-weight: 600; font-size: x-large;">
                        {{ translate('why_question1') }}
                        <br>
                        <img :src="currentImage"
                            style="height: 30%; width: 200px; object-fit: contain; margin-left: 15px; margin-right: 15px; margin-top: 15px; margin-bottom: 15px;">
                        <br>
                        {{ translate('why_question2') }}
                    </div>

                    <div class="col-12 text-center" style="margin-top: 10px;">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item"
                                style="width: 500px; height: 250px; border-radius: 20px;"
                                src="https://www.youtube.com/embed/nrstTmxF1io?si=SB3-o57N2d8l59sL&amp;controls=0"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 30px; min-height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: #E5097F;">
                {{ translate('header_text1') }}<br>
                {{ translate('header_text2') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel4" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="6000" style="text-align: center;">
                                <div class="row">
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/time.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                    translate('element_title1')
                                                }}</span>
                                                <br>
                                                <span v-if="!expandedIndex1">{{ translate('element_contenu1') }}</span>
                                                <span v-if="expandedIndex1"
                                                    :class="['expandable-content', { 'expanded': expandedIndex1 }]">{{
                                                    translate('element_drop_contenu1') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <template v-if="expandedIndex1">
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                        @click="expand_element1">
                                                </template>
                                                <template v-else>
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer;"
                                                        @click="expand_element1">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/quality.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                    translate('element_title2')
                                                }}</span>
                                                <br>
                                                <span v-if="!expandedIndex2">{{ translate('element_contenu2') }}</span>
                                                <span v-if="expandedIndex2"
                                                    :class="['expandable-content', { 'expanded': expandedIndex2 }]">{{
                                                    translate('element_drop_contenu2') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <template v-if="expandedIndex2">
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                        @click="expand_element2">
                                                </template>
                                                <template v-else>
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer;"
                                                        @click="expand_element2">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="row">
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/price.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                    translate('element_title3')
                                                }}</span>
                                                <br>
                                                <span v-if="!expandedIndex3">{{ translate('element_contenu3') }}</span>
                                                <span v-if="expandedIndex3"
                                                    :class="['expandable-content', { 'expanded': expandedIndex3 }]">{{
                                                    translate('element_drop_contenu3') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <template v-if="expandedIndex3">
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                        @click="expand_element3">
                                                </template>
                                                <template v-else>
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer;"
                                                        @click="expand_element3">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/services.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #E5097F; font-weight: 600; font-size: large;">{{
                                                    translate('element_title4')
                                                }}</span>
                                                <br>
                                                <span v-if="!expandedIndex4">{{ translate('element_contenu4') }}</span>
                                                <span v-if="expandedIndex4"
                                                    :class="['expandable-content', { 'expanded': expandedIndex4 }]">{{
                                                    translate('element_drop_contenu4') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <template v-if="expandedIndex4">
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer; transform: rotate(180deg);"
                                                        @click="expand_element4">
                                                </template>
                                                <template v-else>
                                                    <img src="../assets/img/open_text.svg"
                                                        style="margin-bottom: 15px; cursor: pointer;"
                                                        @click="expand_element4">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="container"
            style="color: black; text-align: center; font-size: x-large; font-weight: 600; margin-top: 20px; margin-bottom: 30px;">
            {{ translate('header_text3') }}<br>
            {{ translate('header_text4') }}
        </div>
        <section style="padding-top: 80px; padding-bottom: 200px; height: 750px"
            :style="{ backgroundImage: 'url(' + backgroundImage9 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container" style="margin-top: 50px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel5" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title1') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon1.svg">
                                            <p>{{ translate('solution_contenu1') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title2') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon2.svg">
                                            <p>{{ translate('solution_contenu2') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title3') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon3.svg">
                                            <p>{{ translate('solution_contenu3') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="6000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <img src="../assets/img/one_icon.svg" style="margin-bottom: -50px;">
                                    <div class="border d-flex flex-column solution-box">
                                        <div style="padding: 10px; text-align: center; flex-grow: 1;">
                                            <p style="font-weight: bold;">{{ translate('solution_title4') }}</p>
                                            <img style="margin-top: 15px; margin-bottom: 15px;"
                                                src="../assets/img/solution_icon4.svg">
                                            <p>{{ translate('solution_contenu4') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 60px; padding-bottom: 60px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('eurekali_name') }} <br>
                            {{ translate('eurekali_name_description') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('eurekali_presentation') }}
                        </p>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <img src="../assets/img/eurekali_map.png">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 50px; margin-bottom: 120px; height: 400px;"
            :style="{ backgroundImage: 'url(' + backgroundImage10 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('satisfied_clients') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('satisfied_clients_description') }} <br>
                            {{ translate('satisfied_clients_description2') }}
                        </p>
                        <router-link to="/give-review">
                        <button class="custom-btn4">{{ translate('button_text2') }}</button>
                    </router-link>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <div class="row text-center justify-content-center">
                            <div id="headerCarousel6" class="carousel slide" data-bs-ride="carousel"
                                data-bs-pause="false">
                                <div class="carousel-inner">
                                    <div class="carousel-item active" data-bs-interval="6000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="col-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image1.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review1') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="6000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image2.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review2') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="6000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image3.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review3') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="6000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image4.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review4') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </section>
</template>





<script>
import MainNav from '@/components/MainNav.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/background_grey.png';
import BackgroundImage2 from '../assets/img/background_grey_bottom.png';
import BackgroundImage3 from '../assets/img/all-one-service.svg';
import BackgroundImage4 from '../assets/img/background_reviews.png';
import BackgroundImage5 from '../assets/img/background_mobile_section.png';
import BackgroundImage6 from '../assets/img/all-one-service-mobile.svg';
import BackgroundImage7 from '../assets/img/background_grey_mobile.png';
import BackgroundImage8 from '../assets/img/background_reviews-mobile.png';
import BackgroundImage9 from '../assets/img/all-one-service-ipad.png';
import BackgroundImage10 from '../assets/img/background_reviews-ipad.png';
import BackgroundImage11 from '../assets/img/background_home_dark.png';
import companyImage2 from '../assets/img/2-company.png';
import companyImage3 from '../assets/img/3-company.png';
import companyImage4 from '../assets/img/4-company.png';
import companyImage5 from '../assets/img/5-company.png';
import companyImage6 from '../assets/img/6-company.png';
import companyImage8 from '../assets/img/8-company.png';
import companyImage9 from '../assets/img/9-company.png';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
            backgroundImage4: BackgroundImage4,
            backgroundImage5: BackgroundImage5,
            backgroundImage6: BackgroundImage6,
            backgroundImage7: BackgroundImage7,
            backgroundImage8: BackgroundImage8,
            backgroundImage9: BackgroundImage9,
            backgroundImage10: BackgroundImage10,
            backgroundImage11: BackgroundImage11,
            imageUrls: [
                companyImage2,
                companyImage3,
                companyImage4,
                companyImage5,
                companyImage6,
                companyImage8,
                companyImage9
            ],
            currentImageIndex: 0,
            first_button_mobile: false,
            second_button_mobile: false,
            expandedIndex1: false,
            expandedIndex2: false,
            expandedIndex3: false,
            expandedIndex4: false,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
        currentImage() {
            return this.imageUrls[this.currentImageIndex];
        },
    },
    mounted() {
        setInterval(this.changeImage, 1500);
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel1'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel2'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel3'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel4'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel5'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel6'), {
                ride: 'carousel',
                interval: 6000,
                pause: false
            });
        });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        changeImage() {
            this.currentImageIndex = (this.currentImageIndex + 1) % this.imageUrls.length;
        },
        changeFirstButton() {
            this.first_button_mobile = !this.first_button_mobile
        },
        changeSecondButton() {
            this.second_button_mobile = !this.second_button_mobile
        },
        expand_element1() {
            this.expandedIndex1 = !this.expandedIndex1;
        },
        expand_element2() {
            this.expandedIndex2 = !this.expandedIndex2;
        },
        expand_element3() {
            this.expandedIndex3 = !this.expandedIndex3;
        },
        expand_element4() {
            this.expandedIndex4 = !this.expandedIndex4;
        }
    }
}
</script>

<style>
.service-box {
    background-color: rgba(155, 6, 86, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    border: 2px solid rgba(207, 0, 32, 0.5);
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #E5097F;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #E5097F;
    color: #E5097F;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #E5097F;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #E5097F;
    color: #E5097F;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #E5097F;
    color: white;
}

.custom-btn3 {
    background-color: #E5097F;
    border: 1px solid #E5097F;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #E5097F;
    color: #E5097F;
}

.custom-btn4 {
    background-color: #E5097F;
    border: 1px solid #E5097F;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 200px;
}

.custom-btn4:hover {
    background-color: white;
    border: 1px solid #E5097F;
    color: #E5097F;
}

.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}



.dropdown-menu-custom {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(155, 6, 86, 0.5);
    padding: 10px;
    border: none;
    border-radius: 4px;
    z-index: 1000;
    border-radius: 20px;
    width: 95%;
}


.service-box:hover .dropdown-menu-custom {
    display: block;
}

.dropdown-menu-custom ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu-custom li {
    margin: 0;
}

.dropdown-menu-custom li:hover {
    background-color: #E5097F;
    border-radius: 15px;
}

.expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
}

.expandable-content.expanded {
    max-height: 600px;
}
</style>
