<template>
    <div ref="scrollTarget"></div>

    <MainNav />
    <section style="padding-top: 65px;">
        <section>
            <header class="header-container-laptop position-relative laptop-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/transporters_header.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-50 start-50">
                    <div class="row gx-5" style="margin-top: -65px;">
                        <div class="col-md-10">
                            <p style="color: white; font-size: 1.7em; font-weight: 600;">{{
                                translate('transporters_title') }}</p>
                            <p style="color: white; font-size: 1.3em; margin-top: 10px;">{{
                                translate('transporters_sub_title') }}</p>
                            <br>
                            <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;"><button
                                    class="btn btn-primary join-us-btn">
                                    <i style="font-size: 20px;" class="fa fa-user-plus" aria-hidden="true"></i> {{
                                        translate('join_us') }}</button></router-link>
                        </div>
                    </div>
                </div>
            </header>

            <!-- Mobile -->
            <header class="header-container-laptop position-relative mobile-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/transporters_header_mobile.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                    style="width: 100%; height: 100%; margin-top: 150px; padding: 25px;">
                    <div class="text-center" style="color: white;">
                        <p style="font-size: 1.5em; font-weight: 600;">{{ translate('transporters_title') }}</p>
                        <p style="font-size: 1.3em; margin-top: 10px;">{{ translate('transporters_sub_title') }}</p>
                        <br>
                        <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;">
                            <button class="btn btn-primary join-us-btn">
                                <i style="font-size: 20px;" class="fa fa-user-plus" aria-hidden="true"></i> {{
                                translate('join_us') }}
                            </button>
                        </router-link>
                    </div>
                </div>
            </header>

            <!-- Ipad -->
            <header class="header-container-laptop position-relative ipad-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/transporters_header_ipad.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                    style="width: 100%; height: 100%; margin-top: 150px; padding: 20px;">
                    <div class="text-center" style="color: white;">
                        <p style="font-size: 1.7em; font-weight: 600;">{{ translate('transporters_title') }}</p>
                        <p style="font-size: 1.3em; margin-top: 10px;">{{ translate('transporters_sub_title') }}</p>
                        <br>
                        <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;">
                            <button class="btn btn-primary join-us-btn">
                                <i style="font-size: 20px;" class="fa fa-user-plus" aria-hidden="true"></i> {{
                                translate('join_us') }}
                            </button>
                        </router-link>
                    </div>
                </div>
            </header>
        </section>

        <section class="container-fluid mt-md-5 laptop-section" style="margin-top: 200px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: black; font-weight: 600; font-size: 1.7em; max-width: 600px; margin: auto;">
                            {{ translate('transporters_partners') }}
                        </p>
                    </div>
                </div>
                <div class="row w-100 align-items-center" style="margin-top: 20px;">
                    <div class="col-12 text-center" style="padding: 0;">
                        <img src="../assets/img/transporters_partnerns.jpg" class="img-fluid"
                            alt="Transporters Partners">
                    </div>
                </div>
            </div>
        </section>


        <!-- Mobile -->
        <section class="container mt-md-5 mobile-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100" style="padding: 0;">
                    <div class="col-12 text-center">
                        <p style="color: black; font-weight: 600; font-size: 1.5em; max-width: 600px; margin: auto;">
                            {{ translate('transporters_partners') }}
                        </p>
                    </div>
                </div>
                <div class="row w-100 align-items-center" style="margin-top: 20px;">
                    <div class="col-12 text-center" style="padding: 0;">
                        <div id="transporterCarousel" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="../assets/img/cit_transporter.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                                <div class="carousel-item">
                                    <img src="../assets/img/fedex_transporter.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                                <div class="carousel-item">
                                    <img src="../assets/img/ups_transporter.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                                <div class="carousel-item">
                                    <img src="../assets/img/tnt_transporter.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                                <div class="carousel-item">
                                    <img src="../assets/img/n1_transporter.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#transporterCarousel"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#transporterCarousel"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Ipad -->
        <section class="container mt-md-5 ipad-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: black; font-weight: 600; font-size: 1.7em; max-width: 600px; margin: auto;">
                            {{ translate('transporters_partners') }}
                        </p>
                    </div>
                </div>
                <div class="row w-100 align-items-center" style="margin-top: 20px;">
                    <div class="col-12 text-center" style="padding: 0;">
                        <div id="transporterCarouselIpad" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="../assets/img/transporters_part1_ipad.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                                <div class="carousel-item">
                                    <img src="../assets/img/transporters_part2_ipad.jpg" class="d-block w-100 img-fluid"
                                        alt="Transporters Partners">
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button"
                                data-bs-target="#transporterCarouselIpad" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                data-bs-target="#transporterCarouselIpad" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section class="container-fluid mt-md-5 laptop-section" style="margin-top: 200px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; font-size: 1.7em; margin: auto;">
                            {{ translate('join_our_marketplace') }}
                        </p>
                        <p style="color: #000; font-weight: 400; font-size: 1.5em; margin: auto;">
                            {{ translate('new_opport') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Ipad -->
        <section class="container mt-md-5 ipad-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; font-size: 1.5em; margin: auto;">
                            {{ translate('join_our_marketplace') }}
                        </p>
                        <p style="color: #000; font-weight: 400; font-size: 1.3em; margin: auto;">
                            {{ translate('new_opport') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Mobile -->
        <section class="container mt-md-5 mobile-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; font-size: 1.4em; margin: auto;">
                            {{ translate('join_our_marketplace') }}
                        </p>
                        <p style="color: #000; font-weight: 400; font-size: 1.2em; margin: auto;">
                            {{ translate('new_opport') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section class="container d-flex justify-content-center align-items-center"
            style="margin-top: 20px; margin-bottom: 35px;">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-4" style="margin-top: 10px;">
                    <div class="service-card-laptop text-center card">
                        <h5 class="card-title">{{ translate('opp1_title') }}</h5>
                        <p class="card-text">
                            {{ translate('opp1_contenu') }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4" style="margin-top: 20px;">
                    <div class="service-card-laptop text-center card">
                        <h5 class="card-title">{{ translate('opp2_title') }}</h5>
                        <p class="card-text">
                            {{ translate('opp2_contenu') }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4" style="margin-top: 20px;">
                    <div class="service-card-laptop text-center card">
                        <h5 class="card-title">{{ translate('opp3_title') }}</h5>
                        <p class="card-text">
                            {{ translate('opp3_contenu') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section class="container-fluid mt-md-5 laptop-section" style="margin-top: 200px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; max-width: 700px; font-size: 1.7em; margin: auto;">
                            {{ translate('transporter_join_now') }}
                        </p>
                        <p style="margin-top: 15px;">
                            <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;"><button
                                    class="btn btn-primary join-us-btn"
                                    style="background-color: #E5097F; color: white;"> <i style="font-size: 20px;"
                                        class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('join_us')
                                    }}</button></router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Ipad -->
        <section class="container mt-md-5 ipad-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; max-width: 700px; font-size: 1.5em; margin: auto;">
                            {{ translate('transporter_join_now') }}
                        </p>
                        <p style="margin-top: 10px;">
                            <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;"><button
                                    class="btn btn-primary join-us-btn"
                                    style="background-color: #E5097F; color: white;"> <i style="font-size: 20px;"
                                        class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('join_us')
                                    }}</button></router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Mobile -->
        <section class="container mt-md-5 mobile-section" style="margin-top: 30px; padding: 0;">
            <div class="container-fluid d-flex flex-column align-items-center" style="padding: 0;">
                <div class="row w-100">
                    <div class="col-12 text-center">
                        <p style="color: #E5097F; font-weight: 600; max-width: 700px; font-size: 1.5em; margin: auto;">
                            {{ translate('transporter_join_now') }}
                        </p>
                        <p style="margin-top: 15px;">
                            <router-link to="/transporter-form" style="color: #E5097F; text-decoration: none;"><button
                                    class="btn btn-primary join-us-btn"
                                    style="background-color: #E5097F; color: white;"> <i style="font-size: 20px;"
                                        class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('join_us')
                                    }}</button></router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section class="laptop-section">
            <section style="margin-top: 30px;">
                <div class="container"
                    style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                    {{ translate('clients_reviews_text1') }}<br>
                    {{ translate('clients_reviews_text2') }}
                </div>
            </section>

            <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 10px;">
                    <div class="row justify-content-center">
                        <div class="col-7"
                            style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                    translate('write_review') }} </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <input style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                    :placeholder="translate('nom')" v-model="name" />
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <textarea style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                    :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                            </div>

                            <div style="display: flex; justify-content: flex-end;">
                                <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                    translate('give_review') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                    <div class="row justify-content-center">
                        <div class="col-7"
                            style="text-align: center; background-color: #FCF5D1; padding: 30px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                        src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                                </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star"></i>
                                    </template>
                                    <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star-o"></i>
                                    </template>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{
                                    review.comment
                                    }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="mobile-section">
            <section style="margin-top: 30px;">
                <div class="container"
                    style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                    {{ translate('clients_reviews_text1') }}<br>
                    {{ translate('clients_reviews_text2') }}
                </div>
            </section>

            <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 10px;">
                    <div class="row justify-content-center">
                        <div class="col-11"
                            style="text-align: center; background-color: #FFE3F2; padding: 20px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                    translate('write_review')
                                    }} </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <input style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                    :placeholder="translate('nom')" v-model="name" />
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <textarea style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                    :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                            </div>

                            <div style="display: flex; justify-content: flex-end;">
                                <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                    translate('give_review') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                    <div class="row justify-content-center">
                        <div class="col-11"
                            style="text-align: center; background-color: #FCF5D1; padding: 20px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                        src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                                </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star"></i>
                                    </template>
                                    <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star-o"></i>
                                    </template>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{
                                    review.comment
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </section>

        <section class="ipad-section">
            <section style="margin-top: 30px;">
                <div class="container"
                    style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: black;">
                    {{ translate('clients_reviews_text1') }}<br>
                    {{ translate('clients_reviews_text2') }}
                </div>
            </section>

            <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 10px;">
                    <div class="row justify-content-center">
                        <div class="col-10"
                            style="text-align: center; background-color: #FFE3F2; padding: 50px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{
                                    translate('write_review')
                                    }} </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                                    <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                                    <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <input style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }"
                                    :placeholder="translate('nom')" v-model="name" />
                            </div>

                            <div class="form-group mb-3">
                                <label class="form-label"></label>
                                <textarea style="border-radius: 15px;" class="form-control"
                                    :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="4"
                                    :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                            </div>

                            <div style="display: flex; justify-content: flex-end;">
                                <button class="btn btn-send-review" style="margin-top: 20px;" @click="sendfeedback">{{
                                    translate('give_review') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 30px; padding-top: 10px; padding-bottom: 20px;">
                <div class="container" style="margin-bottom: 30px;" v-for="(review, index) in reviews" :key="index">
                    <div class="row justify-content-center">
                        <div class="col-10"
                            style="text-align: center; background-color: #FCF5D1; padding: 30px; border-radius: 20px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> <img
                                        src="../assets/img/user_review_icon.svg" style="width: 40px;"> {{ review.name }}
                                </span>
                                <span style="color: #FFAC00; font-size: 25px; cursor: pointer;">
                                    <template v-for="(star, index2) in parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star"></i>
                                    </template>
                                    <template v-for="(star, index2) in 5 - parseInt(review.stars_number)" :key="index2">
                                        <i class="fa fa-star-o"></i>
                                    </template>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <p style="color: black; padding: 10px; text-align: left; font-size: 18px;">{{
                                    review.comment
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>


    </section>
</template>


<script>
import MainNav from '@/components/MainNav2.vue';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import BackgroundImage1 from '../assets/img/background-eurekali.png';
import BackgroundImage2 from '../assets/img/background_gray.jpg';
import Swal from 'sweetalert2'
import { toast } from 'bulma-toast';
import axios from 'axios';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            stars_number: 0,
            feedback: "",
            name: "",
            reviews_star_totals: "",
            reviews_totals: "",
            starCounts: {},
            totalReviews: 30,
            reviews: []
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    async mounted() {
        document.title = 'Transporters | Eurekali';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#transporterCarousel'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });
        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#transporterCarouselIpad'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });

        this.getreviews();

    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        showAlertSuccess() {
            Swal.fire({
                title: this.translate('thanks_feedback'),
                text: "",
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#4BB543',
            }).then(() => {
                // Redirect to /new_order
                //this.$router.push('/new_order/');
                this.feedback = "",
                    this.name = "",
                    this.stars_number = 0
            });
        },
        async sendfeedback() {
            if (!this.name) {
                toast({
                    message: this.translate('give_review_alert1'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                if (this.stars_number == 0) {
                    toast({
                        message: this.translate('give_review_alert2'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-right',
                    })
                } else {
                    if (this.feedback) {
                        this.$store.commit('setIsLoading', true)

                        const data = {
                            "name": this.name,
                            "comment": this.feedback,
                            "stars_number": this.stars_number
                        }
                        await axios.post(`/api/v1/create-user-review/`, data)
                            .then(response => {
                                this.showAlertSuccess()
                            })
                            .catch(error => {
                                console.log(error)
                            })

                        this.$store.commit('setIsLoading', false)
                    } else {
                        toast({
                            message: this.translate('give_feedback'),
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    }
                }
            }
        },
        async getreviews() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/get-reviews/')
                .then(response => {
                    this.reviews = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getReviewsStat() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/get-reviews-stat/')
                .then(response => {
                    this.reviews_star_totals = response.data['avg_stars']
                    this.reviews_totals = response.data['total_reviews']
                    this.starCounts = {
                        5: response.data['total_starts5'],
                        4: response.data['total_starts4'],
                        3: response.data['total_starts3'],
                        2: response.data['total_starts2'],
                        1: response.data['total_starts1']
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        set_stars_number_1() {
            this.stars_number = 1
        },
        set_stars_number_2() {
            this.stars_number = 2
        },
        set_stars_number_3() {
            this.stars_number = 3
        },
        set_stars_number_4() {
            this.stars_number = 4
        },
        set_stars_number_5() {
            this.stars_number = 5
        },
        getPercentage(count) {
            return (count / this.totalReviews) * 100;
        }
    }
}
</script>


<style scoped>
.btn-login {
    color: white;
    background-color: #E5097F;
    font-weight: 400;
    border-color: #E5097F;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 158px;
    border: none;
    border-radius: 20px;
}

.btn-login:hover {
    color: white;
    background-color: #9B0656;
}

.btn-signup {
    color: black;
    background-color: white;
    font-weight: 600;
    border-color: white;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}


.btn-logout {
    color: white;
    background-color: #dc3545;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    border: none;
    border-radius: 20px;
}

.btn-logout:hover {
    color: white;
    background-color: rgb(175, 12, 12);
}



.header-container-laptop {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.header-image-laptop {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.service-card-laptop2 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin: 15px 0;
    margin-top: 5px;
    padding: 25px;
    padding-bottom: 20px;
    cursor: pointer;
}

.service-card-laptop2 h5 {
    color: #E5097F;
    font-weight: bold;
    margin-top: 10px;
}

.service-card-laptop2 p {
    margin-top: 5px;
    text-align: center;
    color: black;
}

.join-us-btn {
    margin-top: 15px;
    background-color: white;
    color: #E5097F;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid white;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.join-us-btn:hover {
    background-color: #E5097F;
    border: 1px solid white;
    color: white;
}


.custom-input {
    border-radius: 20px;
    margin-right: 10px;
    width: 250px;
}

.custom-button {
    background-color: white;
    color: #E5097F;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid white;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-button:hover {
    background-color: #E5097F;
    border: 1px solid white;
    color: white;
}

.btn-read-more {
    background-color: #E5097F;
    color: white;
    border-radius: 20px;
    font-weight: 600;
}

.btn-read-more:hover {
    background-color: #9B0656;
    color: white;
}


.service-image {
    height: 350px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    position: relative;
    color: white;
}

.overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .mobile-section {
        display: block;
    }

    .laptop-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.service-card-laptop {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 15px 0;
    margin-top: 5px;
    padding: 25px;
    padding-bottom: 20px;
    cursor: pointer;
    height: 100%;
}

.service-card-laptop h5 {
    color: #e5097f;
    font-weight: bold;
    margin-top: 10px;
}

.service-card-laptop p {
    margin-top: 5px;
    text-align: center;
    color: black;
}
</style>
