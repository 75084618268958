<template>
    <div ref="scrollTarget"></div>
    <MainNav />


    <section style="margin-top: 30px; margin-bottom: 100px; padding-top: 65px">
        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-7 col-sm-12 mb-4">
                    <div class="p-4 rounded border" style="border-radius: 20px;">
                        <div class="text-center">
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">

                            <div
                                style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 30px; margin-bottom: 10px; font-size: 1.3em;">
                                {{ translate('company_info') }}
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_name_trans') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="company_name"
                                    :class="{ 'fill-out': company_name, 'not-fill-out': !company_name }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_address') }}</label>
                                <input id="autocomplete" type="text" class="form-control custom-input" required
                                    v-model="company_address"
                                    :class="{ 'fill-out': company_address, 'not-fill-out': !company_address }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_year') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="company_year"
                                    :class="{ 'fill-out': company_year, 'not-fill-out': !company_year }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_contact') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="contact_name"
                                    :class="{ 'fill-out': contact_name, 'not-fill-out': !contact_name }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_address_trans') }}</label>
                                <input type="email" class="form-control custom-input" required v-model="email_address"
                                    :class="{ 'fill-out': email_address, 'not-fill-out': !email_address }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_phone_trans') }}</label><br>
                                <input type="text" class="form-control custom-input" ref="phoneInput" required
                                    v-model="phone" :class="{ 'fill-out': phone, 'not-fill-out': !phone }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('company_structure') }}</label>
                                <input type="text" class="form-control custom-input"
                                    :placeholder="translate('placeholder1')" required v-model="company_structure"
                                    :class="{ 'fill-out': company_structure, 'not-fill-out': !company_structure }">
                            </div>


                            <div
                                style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 70px; margin-bottom: 10px; font-size: 1.3em;">
                                {{ translate('company_capacity') }}
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('vehicles_number') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="vehicles_number"
                                    :class="{ 'fill-out': vehicles_number, 'not-fill-out': !vehicles_number }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('vehicles_type') }}</label>
                                <input type="text" class="form-control custom-input"
                                    :placeholder="translate('placeholder2')" required v-model="vehicles_type"
                                    :class="{ 'fill-out': vehicles_type, 'not-fill-out': !vehicles_type }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{ translate('max_load')
                                }}</label>
                                <input type="text" class="form-control custom-input" required v-model="max_load"
                                    :class="{ 'fill-out': max_load, 'not-fill-out': !max_load }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('service_zone') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="service_zone"
                                    :class="{ 'fill-out': service_zone, 'not-fill-out': !service_zone }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('service_availability') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="service_availability"
                                    :class="{ 'fill-out': service_availability, 'not-fill-out': !service_availability }">
                            </div>



                            <div
                                style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 70px; margin-bottom: 10px; font-size: 1.3em;">
                                {{ translate('experience_certification') }}
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('pricipe_sectors') }}</label>
                                <input type="text" class="form-control custom-input"
                                    :placeholder="translate('placeholder3')" required v-model="pricipe_sectors"
                                    :class="{ 'fill-out': pricipe_sectors, 'not-fill-out': !pricipe_sectors }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('experience_transport') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="experience_transport"
                                    :class="{ 'fill-out': experience_transport, 'not-fill-out': !experience_transport }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('certifications') }}</label>
                                <input type="text" class="form-control custom-input"
                                    :placeholder="translate('placeholder4')" required v-model="certifications"
                                    :class="{ 'fill-out': certifications, 'not-fill-out': !certifications }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{ translate('references')
                                }}</label>
                                <input type="text" class="form-control custom-input" required v-model="references"
                                    :class="{ 'fill-out': references, 'not-fill-out': !references }">
                            </div>



                            <div
                                style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 70px; margin-bottom: 10px; font-size: 1.3em;">
                                {{ translate('services_offers') }}
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('propossed_services') }}</label>
                                <input type="text" class="form-control custom-input"
                                    :placeholder="translate('placeholder5')" required v-model="propossed_services"
                                    :class="{ 'fill-out': propossed_services, 'not-fill-out': !propossed_services }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('flexibility') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="flexibility"
                                    :class="{ 'fill-out': flexibility, 'not-fill-out': !flexibility }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('pricing_cost') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="pricing_cost"
                                    :class="{ 'fill-out': pricing_cost, 'not-fill-out': !pricing_cost }">
                            </div>


                            <div
                                style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 70px; margin-bottom: 10px; font-size: 1.3em;">
                                {{ translate('other_information') }}
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('why_choose_us_trans') }}</label>
                                <input type="text" class="form-control custom-input" required v-model="why_choose_us_trans"
                                    :class="{ 'fill-out': why_choose_us_trans, 'not-fill-out': !why_choose_us_trans }">
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label" style="color: black; font-weight: 600;">{{
                                    translate('what_your_expectation') }}</label>
                                <input type="text" class="form-control custom-input" required
                                    v-model="what_your_expectation"
                                    :class="{ 'fill-out': what_your_expectation, 'not-fill-out': !what_your_expectation }">
                            </div>


                            <div class="text-center mt-3">
                                <button type="submit" class="btn btn-login2 me-2">{{ translate('sign_up') }}</button>
                                <br>
                                <div class="notification is-danger" style="margin-top: 15px;" v-if="errors.length">
                                    <p v-for="error in errors" :key="error">{{ error }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from 'axios';
import MainNav from '@/components/MainNav2.vue';
import { toast } from 'bulma-toast'
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'Partner',
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            company_name: '',
            company_address: '',
            company_year: '',
            contact_name: '',
            email_address: '',
            phone: '',
            company_structure: '',
            vehicles_number: '',
            vehicles_type: '',
            max_load: '',
            service_zone: '',
            service_availability: '',
            pricipe_sectors: '',
            experience_transport: '',
            certifications: '',
            references: '',
            propossed_services: '',
            flexibility: '',
            pricing_cost: '',
            why_choose_us_trans: '',
            what_your_expectation: '',
            errors: []
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Become partner | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: ["be"] });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            this.company_address = this.formatAddress(place.address_components);
        });


        //---------Country code phone
        const phoneInput = this.$refs.phoneInput;
        intlTelInput(phoneInput, {
            separateDialCode: true,
            preferredCountries: ["be"],
        });
        //------------------------
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        formatAddress(addressComponents) {
            return (
                (addressComponents[0] && addressComponents[0].long_name || '') + ' ' +
                (addressComponents[1] && addressComponents[1].long_name || '') + ', ' +
                (addressComponents[2] && addressComponents[2].long_name || '')
            );
        },
        async submitForm() {
            this.errors = []

            const phoneInput = this.$refs.phoneInput;
            const iti = intlTelInputGlobals.getInstance(phoneInput);
            const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;
            const extention = selectedCountryCode

            const formData = {
                company_name: this.company_name,
                company_address: this.company_address,
                company_year: this.company_year,
                contact_name: this.contact_name,
                email_address: this.email_address,
                phone: extention + this.phone,
                company_structure: this.company_structure,
                vehicles_number: this.vehicles_number,
                vehicles_type: this.vehicles_type,
                max_load: this.max_load,
                service_zone: this.service_zone,
                service_availability: this.service_availability,
                pricipe_sectors: this.pricipe_sectors,
                experience_transport: this.experience_transport,
                certifications: this.certifications,
                references: this.references,
                propossed_services: this.propossed_services,
                flexibility: this.flexibility,
                pricing_cost: this.pricing_cost,
                why_choose_us_trans: this.why_choose_us_trans,
                what_your_expectation: this.what_your_expectation,
            }
            await axios
                .post("/api/v1/transporter-partner/", formData)
                .then(async response => {

                    toast({
                        message: this.translate('thanks_transporter'),
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 5000,
                        position: 'top-right',
                    })
                    this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })

        },
    }
}
</script>

<style scoped>.btn-login2 {
    background-color: #E5097F;
    color: white;
    margin-left: 15px;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-login2:hover {
    background-color: #9b0656;
    color: white;
}

.custom-input {
    border: 1px solid #E5097F;
    border-radius: 15px;
}

.custom-input:focus {
    border: 2px solid #9b0656;
    border-radius: 20px;
    box-shadow: none;
}

.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}</style>