<template>
    <div ref="scrollTarget"></div>

    <MainNav />
<section style="padding-top: 65px;">
<div class="header-container-proudcts position-relative">
    <div class="background-image2-conditions-laptop img-fluid w-100"></div>
    <div class="background-image2-conditions-ipad img-fluid w-100"></div>
    <div class="background-image2-conditions-mobile img-fluid w-100"></div>

    <div class="phrase-laptop">
        <div class="position-absolute top-50 start-50 translate-middle text-white">

            <div class="phrase-laptop">
                <div class="row align-items-center">
                    <div class="col-md-2" style="text-align: right;">
                        <img src="../assets/img/conditions-icon.png" class="img-fluid mx-auto d-block">
                    </div>
                    <div class="col-md-10">
                        <p style="font-size: 30px; font-weight: 600; color: #E5097F;">
                            Mentions Légales de Eurêkali
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="phrase-mobile">
        <div class="position-absolute top-50 start-50 translate-middle text-white w-100">

            <div class="phrase-mobile">
                <p style="font-size: 20px; font-weight: 600; color: #E5097F; text-align: center; padding: 10px;">
                    <img src="../assets/img/conditions-icon.png" style="transform: scale(0.7);"> <br> Mentions Légales de Eurêkali
                </p>
            </div>
        </div>
    </div>
</div>
</section>

    <div class="container" style="margin-top: 50px; padding: 10px; padding-bottom: 100px;">

        
        <div style="margin-top: 50px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">Activité</span>
            <br>
            <br>
            <span style="color: black;">Eurêkali, à travers son site internet www.eurekali.com, permet à l'utilisateur de consulter et de sélectionner gratuitement et en totale transparence les offres de prestation des transporteurs.</span>
            <br>
            <span style="color: black;">La plateforme donne accès à une comparaison immédiate d'offres de différents acteurs du secteur du transport, pour un départ et une arrivée dans les zones qu'ils desservent.</span>
            <br>
            <span style="color: black;">Eurêkali reçoit de l'utilisateur le mandat de faire effectuer une prestation de livraison auprès d'un transporteur désigné par ses soins.</span>
            <br>
            <span style="color: black;">A ce titre, Eurêkali est mandataire du client utilisateur et n'est pas commissionnaire de transport.</span>
            <br>
            <span style="color: black;">La relation contractuelle avec le client est régie par les Conditions Générales de vente et d'utilisation du site.</span>
        </div>

        <div style="margin-top: 20px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">Mentions légales</span>
            <br>
            <br>
            <span style="color: black;">Dénomination sociale : Eurêkali</span>
            <br>
            <span style="color: black;">Société à responsabilité limitée</span>
            <br>
            <span style="color: black;">Capital social : 18.550 EUR</span>
            <br>
            <span style="color: black;">Numéro TVA: BE0537.251.722</span>
            <br>
            <span style="color: black;">Adresse du siège social : 40C, Rue Egide Van Ophem à 1180 Bruxelles, Belgique</span>
        </div>

        <div style="margin-top: 50px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">Assurance</span>
            <br>
            <br>
            <span style="color: black;">Eurêkali est assurée auprès de AIG Europ Limited sous la police BF33001454 en matière de responsabilité civile professionnelle.</span>
        </div>
        
    </div>
</template>



<script>
import MainNav from "@/components/MainNav2.vue";
import axios from 'axios';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'Success',
    mixins: [en, fr, nl],
    components: {
      MainNav,
    },
    data() {
        return {
            tracking_number: "",
            slides: [],
            stars_number: 5,
            feedback: "",
            phonenumber: "",
            sent_code: false,
            callme_option: false
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async mounted() {
        document.title = 'Legal Notice | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);


    },
    created() {
        this.tracking_number = this.$route.params.shipment_id
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async printConditions() {
      const url = `http://localhost:8000/api/v1/conditions/print/`;
      window.open(url, '_blank');
    },

    }

}
</script>

<style>
.h1-text {
    margin-top: 20px;
    color: black;
    font-weight: 600;
    font-size: 22px;
}

.h1-text-s {
    margin-top: 20px;
    font-size: 20px;
}

.h1-text-ss {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}

.h1-text-sss {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #E5097F;
    font-size: 18px;
    font-weight: bold;
}

.desc {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
}

.btn-track {
    margin-top: 10px;
    background-color: #FFFFFF;
    color: #E5097F;
    border-color: #FFFFFF;
    font-weight: 600;
    border-radius: 20px;
}

.btn-track:hover {
    background-color: #E5097F;
    color: #FEEEF6;
    border-color: #E5097F;
    font-weight: 600;
}

.btn-track-2 {
    margin-top: 25px;
    background-color: #E5097F;
    color: white;
    border-color: #E5097F;
    font-weight: 600;
    width: 50%;
    border-radius: 20px;
}

.btn-track-2:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border-color: #E5097F;
    font-weight: 600;
}

.div-opt {
    border-radius: 15px;
    padding: 30px;
    margin: 0 15px;
    margin-bottom: 15px;
    height: 100%;
}

.header-container-proudcts {
    height: 300px;
}

.header-container img {
    object-fit: cover;
    height: 100%;
}

.background-image2-conditions-laptop {
    object-fit: cover;
    height: 100%;
    background-image: url('../assets/img/conditions-header.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center;
}

.background-image2-conditions-mobile {
    height: 100%;
    background-image: url('../assets/img/conditions-header-mobile.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center;
}

.background-image2-conditions-ipad {
    height: 100%;
    background-image: url('../assets/img/conditions-header.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: right;
}



.background-image2-conditions-mobile {
    display: none;
}

.background-image2-conditions-ipad {
    display: none;
}

.phrase-mobile {
    display: none;
}

@media (max-width: 767px) {
    .background-image2-conditions-laptop {
        display: none;
    }

    .background-image2-conditions-mobile {
        display: block;
    }

    .background-image2-conditions-ipad {
        display: none;
    }

    .phrase-mobile {
        display: block;
    }

    .phrase-laptop {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .background-image2-conditions-laptop {
        display: none;
    }

    .background-image2-conditions-mobile {
        display: none;
    }

    .background-image2-conditions-ipad {
        display: block;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');




.box-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.boarddd {
    max-width: 987px;
    width: 100%;
    background-color: #FCF5D5;
    text-align: center;
    border-radius: 20px;
    padding-bottom: 50px;
    padding-top: 30px;
}

.text-lightv2 {
    color: var(--color-light);
}

.btn-editphone {
    background-color: #FFFFFF;
    color: #E5097F;
    border: 1px solid #E5097F;
    border-radius: 20px;
}

.btn-editphone:hover {
    background-color: #9b0656;
    color: #FFFFFF;
}

.btn-sendcode {
    background-color: #E5097F;
    color: #FFFFFF;
    border-radius: 20px;
}

.btn-sendcode:hover {
    background-color: #9b0656;
    border: 1px solid #9b0656;
    color: #FFFFFF;
}</style>
