<template>
  <div ref="scrollTarget"></div>

  <MainNav />
  <section style="padding-top: 65px">
    <section>
      <header class="header-container-laptop position-relative laptop-section">
        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <img src="../assets/img/b2b-home.png" class="d-block w-100" alt="Image 3" />
            </div>
          </div>
        </div>
        <div class="position-absolute top-50 start-50">
          <div class="row gx-5" style="margin-top: -150px">
            <div class="col-md-10">
              <span style="color: white; font-size: x-large">{{
                translate("b2b_title1")
              }}</span>
              <br />
              <br />
              <span style="color: white; font-size: x-large">{{
                translate("b2b_title2")
              }}</span>
              <br />
              <br />
              <span style="color: white; font-size: x-large; font-weight: bold">"{{ translate("b2b_title3") }}"</span>
              <br />
              <br />
              <a href="https://www.eurekali.com/homepageForSegment?segment=b2b" style="color: #e5097f; text-decoration: none; margin-right: 7px"><button
                  class="btn btn-primary join-us-btn">
                  <i style="font-size: 20px" class="fa fa-user" aria-hidden="true"></i>
                  {{ translate("login") }}
                </button></a>
              <router-link to="/quote" style="color: #e5097f; text-decoration: none"><button
                  class="btn btn-primary join-us-btn">
                  <i style="font-size: 20px" class="fa fa-user-plus" aria-hidden="true"></i>
                  {{ translate("join_us") }}
                </button></router-link>
            </div>
          </div>
        </div>
      </header>

      <!-- Mobile -->
      <header class="header-container-laptop position-relative mobile-section">
        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <img src="../assets/img/b2b-home-mobile.png" class="d-block w-100" alt="Image 3" />
            </div>
          </div>
        </div>
        <div class="position-absolute bottom-0 text-white mobile-section text-center"
          style="width: 100%; margin-bottom: 20px">
          <p style="font-weight: 400; font-size: 18px; padding: 10px">
            {{ translate("b2b_title1") }}
          </p>
        </div>
      </header>
      <div class="mobile-section" style="
          background-color: #e5097f;
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 35px;
        ">
        <p style="color: white; font-size: 18px">
          {{ translate("b2b_title2") }}
        </p>
        <p style="color: white; font-weight: bold; font-size: 18px">
          "{{ translate("b2b_title3") }}"
        </p>
        <button class="btn btn-primary join-us-btn">
          <i style="font-size: 18px" class="fa fa-user" aria-hidden="true"></i>
          <a href="https://www.eurekali.com/homepageForSegment?segment=b2b" style="color: #e5097f; text-decoration: none">
            {{ translate("login") }}</a></button><br />
        <button class="btn btn-primary join-us-btn">
          <i style="font-size: 18px" class="fa fa-user-plus" aria-hidden="true"></i>
          <router-link to="/quote" style="color: #e5097f; text-decoration: none">
            {{ translate("join_us") }}</router-link>
        </button>
      </div>

      <!-- Ipad -->
      <header class="header-container-laptop position-relative ipad-section">
        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <img src="../assets/img/b2b-home-ipad.png" class="d-block w-100" alt="Image 3" />
            </div>
          </div>
        </div>
        <div class="position-absolute bottom-0 text-white ipad-section text-center"
          style="width: 100%; margin-bottom: 20px">
          <p style="font-weight: 400; font-size: 18px; font-weight: bold">
            {{ translate("b2b_title1") }}
          </p>
          <p style="color: white; font-size: 18px; font-weight: bold">
            {{ translate("b2b_title2") }}
          </p>
          <p style="color: white; font-weight: bold; font-size: 20px">
            "{{ translate("b2b_title3") }}"
          </p>
          <button class="btn btn-primary join-us-btn">
            <i style="font-size: 18px" class="fa fa-user" aria-hidden="true"></i>
            <a href="https://www.eurekali.com/homepageForSegment?segment=b2b" style="color: #e5097f; text-decoration: none">
              {{ translate("login") }}</a>
          </button>
          <button class="btn btn-primary join-us-btn" style="margin-left: 10px">
            <i style="font-size: 18px" class="fa fa-user-plus" aria-hidden="true"></i>
            <router-link to="/quote" style="color: #e5097f; text-decoration: none">
              {{ translate("join_us") }}</router-link>
          </button>
        </div>
      </header>
    </section>

    <!-- Mobile -->
    <section class="container mt-md-5 mobile-section" style="margin-top: 30px">
      <div class="container-fluid d-flex justify-content-center align-items-center">
        <div class="row align-items-center">
          <div class="col-12 col-md-auto text-center">
            <div style="width: auto; border-radius: 15px">
              <span style="color: #e5097f; font-size: 22; font-weight: 600">{{
                translate("who_are_we")
              }}</span>
              <br />
              <a href="https://www.youtube.com/watch?v=nrstTmxF1io">
                <img src="../assets/img/video-image.png" style="transform: scale(0.8); cursor: pointer" />
              </a>
            </div>
          </div>
          <div class="col-12 col-md-auto text-center" style="margin-top: 25px">
            <img src="../assets/img/question-icon.svg" alt="Description Icon" class="img-fluid" />
          </div>
          <div class="col-12 col-md" style="text-align: center; padding: 25px">
            <p style="color: #e5097f; font-weight: 600; font-size: 22px">
              {{ translate("eurekali_name") }}: <br />{{
                translate("b2b_title4")
              }}
              <br />{{ translate("b2b_title5") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Ipad -->
    <section class="container mt-md-5 ipad-section" style="margin-top: 30px">
      <div class="container-fluid d-flex justify-content-center align-items-center">
        <div class="row align-items-center">
          <div class="col-12 text-center">
            <div style="width: auto; border-radius: 15px">
              <span style="color: #e5097f; font-size: 22; font-weight: 600">{{
                translate("who_are_we")
              }}</span>
              <br />
              <a href="https://www.youtube.com/watch?v=nrstTmxF1io">
                <img src="../assets/img/video-image.png" style="transform: scale(0.8); cursor: pointer" />
              </a>
            </div>
          </div>
          <div class="col-12 text-center text-md-center">
            <img src="../assets/img/question-icon.svg" alt="Description Icon" class="img-fluid" />
          </div>
          <div class="col-12" style="text-align: center">
            <p style="color: #e5097f; font-weight: 600; font-size: 22px">
              {{ translate("eurekali_name") }}: <br />{{
                translate("b2b_title4")
              }}
              <br />{{ translate("b2b_title5") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid mt-md-5 laptop-section" style="margin-top: 200px">
      <div class="container-fluid d-flex justify-content-center align-items-center">
        <div class="row align-items-center">
          <div class="col-12 col-md-auto text-center text-md-left">
            <img src="../assets/img/question-icon.svg" alt="Description Icon" class="img-fluid" />
          </div>
          <div class="col-12 col-md" style="text-align: left">
            <p style="color: #e5097f; font-weight: 600; font-size: 22px">
              {{ translate("eurekali_name") }}: <br />{{
                translate("b2b_title4")
              }}
              <br />{{ translate("b2b_title5") }}
            </p>
          </div>
          <div class="col-12 col-md-auto text-md-left" style="position: absolute; right: 0; padding: 0">
            <div style="
                background-color: #f6e7f0;
                width: 350px;
                padding: 15px;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                padding-bottom: 10px;
              ">
              <span style="color: #e5097f; font-size: 22; font-weight: 600">{{
                translate("who_are_we")
              }}</span>
              <br />
              <br />
              <a href="https://www.youtube.com/watch?v=nrstTmxF1io">
                <img src="../assets/img/video-image.png" style="width: 280px; height: 130px; cursor: pointer" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container" style="margin-top: 70px">
        <div class="row text-center">
          <div class="col-lg-6 d-flex" style="margin-top: 20px">
            <div class="d-flex flex-column" style="border-radius: 20px; height: 100%; background-color: white">
              <div style="position: relative">
                <img src="../assets/img/sender-b2b.png" style="width: 100%; height: auto" alt="Image Description" />
                <div style="
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding-left: 15px;
                    color: #e5097f;
                    padding-bottom: 10px;
                    text-align: left;
                  ">
                  <span style="font-weight: 600">Eric K. </span><br />
                  <span>{{ translate("sender") }}</span>
                </div>
              </div>
              <div style="
                  margin-top: 7px;
                  padding: 20px;
                  text-align: left;
                  flex-grow: 1;
                  background-color: #f6e7f0;
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                ">
                <span style="color: #e5097f; font-weight: 600; font-size: large">{{ translate("sender_title") }}</span>
                <br />
                <span>{{ translate("sender_contenu") }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex" style="margin-top: 20px">
            <div class="d-flex flex-column" style="border-radius: 20px; height: 100%; background-color: white">
              <div style="position: relative">
                <img src="../assets/img/mail-room.png" style="width: 100%; height: auto" alt="Image Description" />
                <div style="
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding-left: 15px;
                    color: #e5097f;
                    padding-bottom: 10px;
                    text-align: left;
                  ">
                  <span style="font-weight: 600">Calorine O. / Ali B.</span><br />
                  <span>{{ translate("mail_room") }}</span>
                </div>
              </div>
              <div style="
                  margin-top: 7px;
                  padding: 20px;
                  text-align: left;
                  flex-grow: 1;
                  background-color: #f6e7f0;
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                ">
                <span style="color: #e5097f; font-weight: 600; font-size: large">{{ translate("mail_room_title")
                  }}</span>
                <br />
                <span>{{ translate("mail_room_contenu") }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row text-center" style="margin-top: 30px">
          <div class="col-lg-6 d-flex" style="margin-top: 20px">
            <div class="d-flex flex-column" style="border-radius: 20px; height: 100%; background-color: white">
              <div style="position: relative">
                <img src="../assets/img/accounting-image.png" style="width: 100%; height: auto"
                  alt="Image Description" />
                <div style="
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding-left: 15px;
                    color: #e5097f;
                    padding-bottom: 10px;
                    text-align: left;
                  ">
                  <span style="font-weight: 600">Jhon M.</span><br />
                  <span>{{ translate("accountant") }}</span>
                </div>
              </div>
              <div style="
                  margin-top: 7px;
                  padding: 20px;
                  text-align: left;
                  flex-grow: 1;
                  background-color: #f6e7f0;
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                ">
                <span style="color: #e5097f; font-weight: 600; font-size: large">{{ translate("accountant_title")
                  }}</span>
                <br />
                <span>{{ translate("accountant_contenu") }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex" style="margin-top: 20px">
            <div class="d-flex flex-column" style="border-radius: 20px; height: 100%; background-color: white">
              <div style="position: relative">
                <img src="../assets/img/ceo-image.png" style="width: 100%; height: auto" alt="Image Description" />
                <div style="
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding-left: 15px;
                    color: #e5097f;
                    padding-bottom: 10px;
                    text-align: left;
                  ">
                  <span style="font-weight: 600">David P.</span><br />
                  <span>{{ translate("ceo") }}</span>
                </div>
              </div>
              <div style="
                  margin-top: 7px;
                  padding: 20px;
                  text-align: left;
                  flex-grow: 1;
                  background-color: #f6e7f0;
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                ">
                <span style="color: #e5097f; font-weight: 600; font-size: large">{{ translate("ceo_title") }}</span>
                <br />
                <span>{{ translate("ceo_contenu") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Laptop -->
    <section class="laptop-section" style="margin-top: 70px">
      <div class="container">
        <p style="
            color: #e5097f;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 90px;
          ">
          {{ translate("partners_trust_us") }}
        </p>

        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/1-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/2-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/3-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/4-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/5-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/6-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/7-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/8-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/9-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Ipad -->
    <section class="ipad-section">
      <div class="container">
        <p style="
            color: #e5097f;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin-top: 80px;
            margin-bottom: 30px;
          ">
          {{ translate("partners_trust_us") }}
        </p>

        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false"
          style="height: 150px">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/1-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/2-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/3-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/4-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/5-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/6-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/7-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/8-company.png" class="img-fluid" />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center text-center">
                  <img src="../assets/img/9-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Mobile -->
    <section class="mobile-section">
      <div class="container">
        <p style="
            color: #e5097f;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin-top: 50px;
            margin-bottom: 30px;
          ">
          {{ translate("partners_trust_us") }}
        </p>

        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/1-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/2-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/3-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/4-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/5-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/6-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/7-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/8-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="../assets/img/9-company.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <p style="
            color: #e5097f;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin-top: 50px;
            margin-bottom: 30px;
          ">
          {{ translate("say_about_us") }}
        </p>

        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <div class="row">
                <div class="col-md-4" style="margin-top: 15px">
                  <div style="
                      background-color: #fdf6ce;
                      text-align: left;
                      padding: 15px;
                      border-radius: 15px;
                      height: 100%;
                    ">
                    <span style="color: #e5097f; font-weight: 600">Mazzucco Mitia</span>
                    <br />
                    <span style="color: #e5097f">Administrative Assistant, Fluxys</span> <br />
                    <span>I appreciate that Eurekali answers quickly to my demands.<br>
I also appreciate that with some years of work together, we have accumulated a kind of work experience together. That makes some things easier.<br>
Finally, I also appreciate the fact that some drivers have become “regulars” drivers and they know how we work.</span>
                  </div>
                </div>
                <div class="col-md-4" style="margin-top: 15px">
                  <div style="
                      background-color: #fdf6ce;
                      text-align: left;
                      padding: 15px;
                      border-radius: 15px;
                      height: 100%;
                    ">
                    <span style="color: #e5097f; font-weight: 600">Ms. Sola Y. Ibrahim</span>
                    <br />
                    <span style="color: #e5097f">Delka</span> <br />
                    <span>Eurekali is an easy, reliable platform with good deals to send packages all around the world. Packages arrive in timely manners, if delays occur or any other issues, Eurekali staff is always available and ready to wholeheartedly follow up and resolve.</span>
                  </div>
                </div>
                <div class="col-md-4" style="margin-top: 15px">
                  <div style="
                      background-color: #fdf6ce;
                      text-align: left;
                      padding: 15px;
                      border-radius: 15px;
                      height: 100%;
                    ">
                    <span style="color: #e5097f; font-weight: 600">Michel Verhoeven </span>
                    <br />
                    <span style="color: #e5097f">PWC</span> <br />
                    <span>Grote algemene tevredenheid over de nationale en internationale dienstverlening van iedereen...!!! <br> Ik hoop dat we nog lang samen zullen werken !!!</span>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <section class="container-fluid mt-md-5" style="margin-top: 200px; padding-top: 90px; padding-bottom: 90px"
        :style="{
          backgroundImage: 'url(' + backgroundImage1 + ')',
          'background-position': 'center',
          'background-size': 'cover',
        }">
        <div class="container d-flex justify-content-center align-items-center">
          <div class="row align-items-center">
            <div class="col-12 col-md-auto text-center text-md-left">
              <img src="../assets/img/logo-icon.svg" alt="Description Icon" class="img-fluid" />
            </div>
            <div class="col-12 col-md" style="text-align: left">
              <p style="
                  color: #e5097f;
                  font-weight: bold;
                  font-size: 22px;
                  margin-top: 30px;
                ">
                {{ translate("eurekali_name") }} <br />{{
                  translate("b2b_title6")
                }}
              </p>
              <p style="color: black; font-size: 18px">
                {{ translate("b2b_title7") }}
              </p>
              <router-link to="/quote">
                <button class="btn btn-read-more" style="margin-top: 40px">
                  <i style="font-size: 18px" class="fa fa-user-plus" aria-hidden="true"></i>
                  <router-link to="/quote" style="color: white; text-decoration: none">{{ translate("join_us")
                    }}</router-link>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import MainNav from "@/components/MainNav2.vue";
import en from "../en.js";
import fr from "../fr.js";
import nl from "../nl.js";
import BackgroundImage1 from "../assets/img/background-eurekali.png";

export default {
  mixins: [en, fr, nl],
  components: {
    MainNav,
  },
  data() {
    return {
      lang: this.$store.state.lang,
      backgroundImage1: BackgroundImage1,
    };
  },
  computed: {
    lang2() {
      return this.$store.state.lang;
    },
  },
  async mounted() {
    document.title = "B2B | Eurekali";
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: "smooth" });
  },
  methods: {
    translate(prop) {
      return this[this.lang2][prop];
    },
  },
};
</script>

<style scoped>
.btn-login {
  color: white;
  background-color: #e5097f;
  font-weight: 400;
  border-color: #e5097f;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  width: 158px;
  border: none;
  border-radius: 20px;
}

.btn-login:hover {
  color: white;
  background-color: #9b0656;
}

.btn-signup {
  color: black;
  background-color: white;
  font-weight: 600;
  border-color: white;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  width: 128px;
  border: none;
  border-radius: 20px;
}

.btn-logout {
  color: white;
  background-color: #dc3545;
  font-weight: 600;
  border-color: #9b0656;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  border: none;
  border-radius: 20px;
}

.btn-logout:hover {
  color: white;
  background-color: rgb(175, 12, 12);
}

.header-container-laptop {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.header-image-laptop {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.service-card-laptop {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 15px 0;
  margin-top: 5px;
  padding: 25px;
  padding-bottom: 20px;
  cursor: pointer;
}

.service-card-laptop h5 {
  color: #e5097f;
  font-weight: bold;
  margin-top: 10px;
}

.service-card-laptop p {
  margin-top: 5px;
  text-align: center;
  color: black;
}

.join-us-btn {
  margin-top: 15px;
  background-color: white;
  color: #e5097f;
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid white;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.join-us-btn:hover {
  background-color: #e5097f;
  border: 1px solid white;
  color: white;
}

.custom-input {
  border-radius: 20px;
  margin-right: 10px;
  width: 250px;
}

.custom-button {
  background-color: white;
  color: #e5097f;
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid white;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-button:hover {
  background-color: #e5097f;
  border: 1px solid white;
  color: white;
}

.btn-read-more {
  background-color: #e5097f;
  color: white;
  border-radius: 20px;
  font-weight: 600;
}

.btn-read-more:hover {
  background-color: #9b0656;
  color: white;
}

.service-image {
  height: 350px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  position: relative;
  color: white;
}

.overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}

.mobile-section {
  display: none;
}

.ipad-section {
  display: none;
}

@media (max-width: 767px) {
  .mobile-section {
    display: block;
  }

  .laptop-section {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .laptop-section {
    display: none;
  }

  .mobile-section {
    display: none;
  }

  .ipad-section {
    display: block;
  }
}
</style>
