<template>
    <div>
      <footer class="text-center text-lg-start text-white" style="background-color: #E5097F;">
  
        <section class="container py-4">
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-5 col-md-12 mb-4 mb-md-0">
              <h6 class="text-uppercase fw-bold">
                <img src="../assets/img/logo-white.svg" class="img-fluid" alt="Logo" />
              </h6>
              <p style="margin-top: 10px; font-weight: 600;">
                <i class="fa fa-map-marker" style="margin-right: 5px;"></i> 40C Rue Egide Van Ophem, B-1180 Bruxelles, Belgique
              </p>
              <p style="margin-top: 10px; font-weight: 600;">
                <i class="fa fa-map-marker" style="margin-right: 5px;"></i> Bir Anzarane, Idrissia Offices, 4e étage, Bureau n° 16 - Fès, Maroc
              </p>
            </div>
  
            <div class="col-lg-2 col-md-4 mb-4 mb-md-0 text-center">
              <p>
                <router-link to="/" class="text-white">{{ translate('home') }}</router-link>
              </p>
              <p>
                <router-link to="/contact" class="text-white">{{ translate('contact') }}</router-link>
              </p>
            </div>
  
            <div class="col-lg-2 col-md-4 mb-4 mb-md-0 text-center">
              <p>
                <router-link to="/about" class="text-white">{{ translate('about_us') }}</router-link>
              </p>
              <p>
                <router-link to="/faqs" class="text-white">FAQ</router-link>
              </p>
            </div>
  
            <div class="col-lg-3 col-md-4 mb-4 mb-md-0 text-center">
              <div class="mt-3">
                <a href="https://www.facebook.com/eurekalii/" class="text-white me-4">
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a href="https://www.facebook.com/eurekalii/" class="text-white me-4">
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="https://www.facebook.com/eurekalii/" class="text-white me-4">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a href="https://www.linkedin.com/company/eur%C3%AAkali/" class="text-white">
                  <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
  
        <!-- Terms & Privacy Section -->
        <div class="text-center p-3" style="background-color: #9b0656">
          <a href="https://demo.eurekali-point.com/conditions" class="text-white d-inline-block ms-2 me-2">{{ translate('terms_of_use') }}</a> |
          <router-link to="/legal-notice" class="text-white d-inline-block ms-2 me-2" href="">{{ translate('privacy_policy') }}</router-link> |
          <a class="text-white d-inline-block ms-2 me-2" href="">{{ translate('accessibility_statement') }}</a>
        </div>
  
        <!-- Copyright Section -->
        <div class="text-center p-3" style="background-color: #9b0656">
          &copy; 2024
          <a class="text-white" href="">Designed and developed by Alistair & Adam</a>
        </div>
      </footer>
    </div>
  </template>
  
  
  
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import en from '../en.js';
  import fr from '../fr.js';
  import nl from '../nl.js';
  
  
  export default {
    name: 'Footer',
    mixins: [en, fr, nl],
    computed: {
      lang() {
        return this.$store.state.lang
      },
    },
    methods: {
      translate(prop) {
        return this[this.lang][prop];
      },
    }
  }
  </script>
