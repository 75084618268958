import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Quote from '../views/Quote.vue'
import B2B from '../views/B2B.vue'
import TransportForm from '../views/TransportForm.vue'
import Coworking from '../views/Coworking.vue'
import ContactUs from '../views/ContactUs.vue'
import AboutUs from '../views/AboutUs.vue'
import GiveReview from '@/views/GiveReview.vue'
import Transporters from '../views/Transporters.vue'
import FAQ from '../views/FAQ.vue'
import Legal_Notice from '../views/Legal_Notice.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quote',
    name: 'Quote',
    component: Quote
  },
  {
    path: '/b2b',
    name: 'B2B',
    component: B2B
  },
  {
    path: '/transporter-form',
    name: 'TransportForm',
    component: TransportForm
  },
  {
    path: '/transporters',
    name: 'Transporters',
    component: Transporters
  },
  {
    path: '/coworking',
    name: 'Coworking',
    component: Coworking
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/give-review',
    name: 'GiveReview',
    component: GiveReview
  },
  {
    path: '/faqs',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/legal-notice',
    name: 'Legal_Notice',
    component: Legal_Notice
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
