export default {
    data() {
        return {
            fr: {
                shipping_route: "Itinéraire d'expédition",
                ship_from: "Expédier depuis",
                ship_to: "Expédier vers",
                postal_code: "Code postal",
                choose_country: "Choisissez un pays",
                city: "Ville",
                shipping_date: "Date d'expédition",
                choose_package: "Choisissez la taille de votre colis",
                belgium: "Belgique",
                other_size: "Autre taille",
                weight: "Poids (KG)",
                width: "Largeur (cm)",
                height: "Hauteur (cm)",
                length: "Longueur (cm)",
                rates_times: "Tarifs et horaires",
                delivery_date: "Date de livraison",
                package_type: "Type de colis",
                price: "Prix (EUR)",
                order: "Commande",


                alert1: 'Veuillez choisir le pays de livraison',
                alert2: 'Veuillez choisir le code postal de livraison',
                alert3: 'Veuillez choisir la ville de livraison',
                alert4: 'Veuillez choisir le code postal de pickup',
                alert5: 'Veuillez choisir la ville de pickup',
                alert6: "Veuillez choisir la date d'expédition",
                alert7: "n'a pas de système de code postal",
                alert8: 'Veuillez remplir le prénom',
                alert9: 'Veuillez remplir le nom de famille',
                alert10: "Veuillez remplir le numéro de l'adresse",
                alert11: 'Veuillez remplir la rue',
                alert12: 'Veuillez remplir le code postal',
                alert13: 'Veuillez remplir la ville',
                alert14: "Veuillez remplir l'État",
                alert15: "Veuillez remplir l'email",
                alert16: 'Veuillez entrer une adresse email valide',
                alert17: 'Veuillez remplir le numéro de téléphone',
                alert18: 'Veuillez remplir la description de votre colis',
                alert19: 'Veuillez choisir un point de service',
                alert20: "Veuillez confirmer l'emballage sécurisé avant de continuer",
                alert21: 'Veuillez remplir les champs nécessaires pour la douane !',
                alert22: 'Veuillez remplir les champs nécessaires !',
                alert23: 'Code promo valide, montant : ',
                alert24: "Le code promo n'est pas valide !",
                alert25: "Impossible d'obtenir des statistiques !!",
                alert26: "Les dimensions sont trop grandes!",
                alert27: 'Choisissez votre option de ramassage',

                summary: "Résumé",
                next: "Suivant",
                total_amount: "Montant total",
                including_vat: "TVA incluse",

                sender_information: "Informations de l'expéditeur",
                first_name: "Prénom",
                last_name: "Nom de famille",
                email: "Email",
                phone: "Téléphone",
                search_address: "Rechercher une adresse",
                number: "Numéro",
                street: "Rue",
                state: "État",
                shipping_route_2: "Itinéraire d'expédition (À",
                no_insurance: "Pas d'assurance",
                assurance: "Assurance",

                receiver_information: "Informations du destinataire",

                //shipment Information
                shipment_info: "Informations sur l'expédition",
                address: "Adresse",
                search: "Valider",
                enter_address: "Veuillez entrer votre adresse",
                choose_service_point: "Les point relais les plus proches de chez vous",
                placeholder_service_point: "Choisissez un point relais pour déposer votre colis",
                description: "Description",
                placeholder_description: "Écrivez une description de votre colis ici...",
                text: "Attention : En cochant ceci, je confirme avoir sécurisé l'emballage pour un transit sûr et je reconnais ma responsabilité.",
                assurance_text1: "Assurer mon colis.",
                assurance_text2: " (Veuillez noter que la valeur maximale assurable des envois est de 1000€)",
                commercial_text: "Avez-vous quelque chose à déclarer à la douane?",
                nature: "Nature",
                n_items: "N. articles",
                unit_price: "Prix unitaire (€)",
                hs_code: "HS code (facultatif)",
                remark: "Remarque",


                //validation
                validate_info: "Valider les informations",
                max_weight: "Poids maximal",
                service_point: "Point relais",
                pickup_address: "Adresse de ramassage",
                insure_my_parcel: "Assurance colis",
                commercial_value: "Valeur commerciale",
                shipment_price: "Prix de l'expédition",
                nature_package: "Nature du colis",
                edit: "Modifier",
                number_items: "Nombre d'articles",
                country: "Pays",
                vat: "TVA",
                proceed_payment: "Procéder au paiement",


                //payment
                payment_method: "Méthode de paiement",
                pay_credit_card: "Payer par carte de crédit",
                pay_bancontact: "Payer par Bancontact",
                pay_cash: "Payer en espèces",
                payment_success: "Paiement réussi",
                payment_success_cong: "Félicitations ! Votre paiement a été traité avec succès",
                payment_failed: "Paiement échoué",
                payment_failed_txt: "Oops ! Il y a eu un problème lors du traitement de votre paiement. Veuillez réessayer ultérieurement.",


                //payment credit card
                credit_card: "Paiement par carte de crédit",
                enter_promotion_code: "Entrer le code promotionnel",
                promo_code: "Code promo",
                enter_promotion_code_place: "Veuillez entrer le code promo",
                validate_code: "Valider le code",
                card_inf: "Informations sur la carte",
                discount: "Remise",

                bancontact: "Paiement Bancontact",

                //Cash Payment
                cash: "Paiement en espèces",
                verification_code: "Code de vérification",
                verification_code_place: "Veuillez entrer le code de vérification",
                validate_payment: "Valider le paiement",
                verification_code_failed: "Le code de vérification n'est pas valide !",
                verification_code_txt: "Le code a été envoyé à l'email du point relais que vous avez choisi. Veuillez les contacter pour recevoir le code !",


                //Main nav
                home: "Accueil",
                new_shipment: "Nouvelle expédition",
                track_shipment: "Suivre l'expédition",
                login: "Connexion",
                sign_up: "S'inscrire",
                myprofile: "Mon profil",
                sign_out: "Déconnexion",
                english: "Anglais",
                french: "Français",
                dutch: "Néerlandais",

                //agent nav
                dashboard: "Tableau de bord",
                //part nav
                my_orders: "Mes commandes",



                //Dashboard
                hello: "Bonjour",
                welcome_back: "Bienvenue de retour !",
                tracking_number: "Numéro de suivi",
                order_date: "Date de commande",
                without_vat: "hors TVA",
                payment: "Paiement",
                commissions: "Commissions",
                status: "Statut",
                not_paid: "Non payé",
                paid: "Payé",
                collected: "Collecté",
                cashed: "Encaissé",
                credit_card_s: "Carte de crédit",
                cash_s: "Espèces",
                bancontact_s: "Bancontact",
                orders: "Commandes",
                my_company: "Ma société",
                support: "Support et assistance",
                company_name: "Nom de l'entreprise",
                vat_number: "Numéro de TVA",
                commission: "Commission",
                today_shipment: "Envois du jour",
                shipment_progress: "Envois en cours",
                shipment_deleivred: "Envois livrés",
                all_shipment: "Tous les envois",
                shipments: "Envois",
                orders_overview: "Aperçu des commandes :",
                profit_this_mounth: "Votre profit ce mois-ci",
                shipments_amount: "Argent encaissé",
                total: "Total",
                pay_bank: "Payer par virement bancaire",
                pay_cash: "Payer en espèces",
                see_more: "Voir plus",
                withdraw_money: "Retirer de l'argent",
                in_progress: "En cours",
                delivered: "Livré",
                my_commissions: "Mes commissions",
                bank_alert: "Paiement par virement bancaire",
                bank_alert_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",
                cash_alert: "Paiement en espèces",
                cash_alert_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",
                get_money: "Retirer de l'argent",
                get_money_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",


                //login page
                forgot_password: "Mot de passe oublié ?",
                dont_have_account: "Vous n'avez pas de compte ?",
                have_account: "Vous avez déjà un compte ?",
                reset_password: "Réinitialiser le mot de passe",
                login_error: "E-mail ou mot de passe incorrect",
                password: "Mot de passe",
                confirm_password: "Confirmez le mot de passe",

                no_orders: "Actuellement, il n'y a aucune commande sur votre compte",

                Yes: "Oui",
                No: "Non",

                //profile
                title: "Titre",
                last_login: "Dernière connexion",
                mr: "M",
                mrs: "Mme",
                ms: "Mlle",
                update: "Mise à jour",
                update_alert: "Mise à jour réussie !",
                error: "Quelque chose s'est mal passé. Veuillez réessayer.",
                update_title: "Mettre à jour les informations",
                update_txt: "Êtes-vous sûr de vouloir mettre à jour vos informations ?",
                update_now: "Mettez à jour",
                cancel: "Annuler",

                sender: "Expéditeur",
                shipping_label: "Bon de livraison",

                new_password: "Nouveau mot de passe",
                confirm_new_password: "Confirmer le nouveau mot de passe",

                reset_password_alert: "Mot de passe mis à jour!",

                send_email: "Envoyer un e-mail",
                send_email_alert: 'E-mail Réinitialiser le mot de passe envoyé!',

                track: 'Suivre',
                nom: "Nom",
                shipping_history: "Historique d'expédition",
                receiver: "Destinataire",
                date: 'Date',
                location: "Emplacement",
                track_title: 'Numéro de suivi incorrect',
                track_txt: 'Numéro de suivi invalide. Veuillez réessayer. Merci!',


                sender_information2: "Expéditeur",
                receiver_information2: "Récepteur",
                shipment_info2: "Expédition",


                register_mssg: "Compte créé, veuillez vous connecter !",

                //alerte assurance
                important_notice: "Avis Important",
                alert_assurance_message: "Si vous ne choisissez pas d'assurance, vous ne serez pas éligible à un remboursement en cas de perte ou de dommage.",
                add_assurance: "Ajouter une assurance",
                without_assurance: "Continuer sans assurance",



                //alerte envoi instance d'expédition
                alert_shipmentinstance: "Nous avons envoyé le lien de paiement au client",
                alert_shipmentinstance_message: "Veuillez vérifier l'e-mail suivant pour procéder au paiement : ",
                send_payment_link: "Envoyer le lien de paiement",

                create_new_meeting: "Créer une nouvelle réunion",
                join_your_meeting: "Rejoignez votre réunion",

                //success
                cong: "Félicitations !",
                package_safe_hands: "Votre colis est désormais entre de bonnes mains.",
                once_your_package: "Vous pouvez suivre votre colis en cliquant sur le bouton ci-dessous:",
                track_your_package: "Suivez votre colis avec trois options pratiques :",
                thr_whatsapp: "Via WhatsApp",
                thr_sms: "Via SMS",
                thr_website: "Sur notre site web",
                whts_desc: "Veuillez nous envoyer votre numéro de suivi par WhatsApp à :",
                provide_infor: "Nous vous fournirons des mises à jour en temps réel sur le statut de votre colis.",
                sms_desc: "Veuillez nous envoyer votre numéro de suivi par SMS à :",
                website_desc: "Pour suivre votre colis, veuillez saisir le numéro de suivi sur notre page de suivi.",
                your_satistfaction: "N'hésitez pas à nous contacter si vous avez besoin d'une assistance supplémentaire. Votre satisfaction est notre priorité !",

                business_day: "jour ouvrable",
                delivery_time: "Délai de livraison",

                your_address: "Votre adresse",
                service_point_address: "Adresse du point relais",
                open_google_maps: "Ouvrir dans Google Maps",

                welcome_message: "Le monde à votre porte en un clic!",
                sous_welcome_message: "Bienvenue dans la nouvelle ère de la livraison avec Eurêkali",

                change_myAddresse: "Pas de point relais proche? Modifiez l'adresse",

                //footer
                about_us: "À propos de nous",
                terms_of_use: "Conditions d'utilisation",
                privacy_policy: "Mentions Légales",
                accessibility_statement: "Déclaration d'accessibilité",
                all_rights_resereved: "Tous droits réservés",

                home_collection: "Collecte à domicile",
                servicepoint_collection: "Point relais Eurêkali",
                servicepoint_collection_text: "Déposez dans un point relais Eurekali",
                home_collection_text: "Récupérez le colis chez moi",
                drop_off_option: "Choisissez le lieu d'enlèvement :",

                your_feedback: "Veuillez écrire vos commentaires...",
                send: "Envoyer",
                give_feedback: "Veuillez nous donner un feedback",
                thanks_feedback: "Merci pour vos commentaires",
                how_was_your_experience: "Comment était votre expérience avec le nouveau système Eurêkali ?",

                call_us: "Appelez-nous",
                click_here: "Cliquez ici si vous avez besoin d'aide",

                // Page d'accueil de l'agent
                join_us: "Rejoignez-nous",
                become_agent: "Devenez un agent Eurekali aujourd'hui et découvrez comment votre partenariat peut propulser votre entreprise vers le succès !",
                why_agent: "Pourquoi nos agents sont-ils heureux d'être un Point Eurekali ?",
                parg1_title: "1 - Attirez plus de clients",
                parg1: "Devenez un point de service Eurekali et transformez votre magasin en un lieu d'activité pour ceux qui ont besoin d'envoyer. C'est une chance incroyable d'augmenter le trafic et de présenter de nouvelles personnes à votre entreprise.",
                parg2_title: "2 - Augmentez vos revenus",
                parg2: "En tant que partenaire Eurêkali, pour chaque colis que vous gérez, vous pourriez obtenir une commission de 10 % (de 1,8 € à 32 € par envoi). C'est un moyen simple et efficace d'ajouter une nouvelle source de revenus à votre entreprise.",
                parg3_title: "3 - Améliorez votre service client",
                parg3: "Offrir les services d'Eurêkali signifie faciliter la vie de vos clients. Qu'ils souhaitent envoyer ou recevoir des colis nationaux ou internationaux, ils pourront le faire sans quitter votre magasin. Un service client de qualité signifie souvent des clients fidèles !",
                parg4_title: "4 - Boostez votre réputation",
                parg4: "Un partenariat avec Eurekali, une marque respectée dans l'industrie de l'expédition, peut augmenter la crédibilité de votre magasin et instiller la confiance chez vos clients. Affichez fièrement ce partenariat et profitez des avantages qu'il peut apporter à votre image.",
                register: "Inscrivez-vous",

                //home page
                with_our_network: "Avec notre vaste réseau de points de service, vous pouvez expédier vos colis vous-même en toute simplicité",
                become_success_agnt: "Devenez agent Eurekali dès aujourd'hui et découvrez comment votre partenariat peut propulser votre entreprise vers le succès!",
                company_text: "Avec Eurekali, optimisez la performance de votre logistique de colis grâce à un service unique dédié à votre activité",
                particular: "Particulier",
                particular_box: "Envoyez votre colis n'importe où dans le monde en quelques secondes",
                service_point_box: "Devenez Point Eurekali et propulsez votre entreprise vers le succès",
                business: "Entreprise",
                business_box: "Optimisez votre logistique de colis avec un service dédié",
                start: "Commencer",
                track_your_shipment: "Suivez votre envoi",
                eurekali_resume: "Eurekali, en tant qu'experts de la livraison, s'engage à fournir un service sur mesure pour tous les profils (individuels, points de service, entreprises). Notre objectif est de sélectionner la solution idéale pour l'expédition de vos colis en tenant compte des critères suivants",
                unbeatable_low_prices: "Prix imbattables",
                fast_delivery_times: "Délais de livraison rapides",
                privileged_contact: "Contact privilégié",
                guarantee_against_delays: "Garantie contre les retards",
                eurekali_reliable: "Eurekali : Votre solution d'expédition fiable et abordable",
                eurekali_resume2: "Eurekali est le partenaire idéal pour répondre à tous vos besoins en matière d'expédition. Que vous soyez une entreprise à la recherche de solutions logistiques efficaces ou un particulier souhaitant envoyer un colis, nous sommes là pour vous. Notre service de livraison offre des solutions d'expédition économiques et fiables adaptées à tous types d'envois.",
                users_review_text1: "Les avis de nos clients sur Eurekali",
                users_review_text2: "Confiance et satisfaction !",

                contact: "Contact",
                text1_home: "Professionnel ou personnel",
                text2_home: "Ne perdez plus votre temps",
                text3_home: "à chercher un transporteur",
                text4_home: "Choisissez Eurêkali pour une alliance de transporteurs fiables et une gestion logistique simplifiée",
                make_shipment: "EFFECTUER UN ENVOI",
                ask_quote: "DEMANDEZ UN DEVIS",
                become_partner: "DEVENIR UN PARTENAIRE",
                header_text1: "Eurêkali transforme vos attentes en réalité,",
                header_text2: "adaptant ses solutions à vos exigences",
                element_title1: "Temps Précieux",
                element_contenu1: "Gagnez du temps en trouvant la livraison adaptée à vos besoins, avec la liberté de changer à tout moment...",
                button_text: "Voir plus",
                element_title2: "Qualité Exigée",
                element_contenu2: "L'union fait la force Mutualisant les besoins de nos clients, nous influençons les transporteurs vers des services de haute qualité...",
                element_title3: "Tarif Négocié",
                element_contenu3: "Regroupement des commandes pour des tarifs avantageux. Profitez de la puissance du groupe pour réduire vos dépenses...",
                element_title4: "Multiservice Flexi",
                element_contenu4: "Choisir parmi plus de 10 000 transporteurs en Belgique est complexe. Nous sélectionnons les meilleurs pour vous...",
                header_text3: "Libérez le Pouvoir avec Eurêkali",
                header_text4: "Votre Solution Tout-en-Un",
                solution_title1: "Un contrat",
                solution_contenu1: "Proposez un contrat simple et révocable pour collaborer avec tous nos partenaires logistiques.",
                solution_title2: "Une platforme",
                solution_contenu2: "Centralisez vos commandes, transports et suivis sur une seule plateforme conviviale.",
                solution_title3: "Un contact",
                solution_contenu3: "Bénéficiez d’un contact VIP dédié pour vous guider et superviser tous vos envois.",
                solution_title4: "Une facture",
                solution_contenu4: "Simplifiez votre comptabilité avec une facturation regroupée pour toutes vos transactions.",
                eurekali_name: "Eurêkali",
                eurekali_name_description: "leader en Belgique depuis 2013",
                eurekali_presentation: "Eurêkali, leader en Belgique depuis 2013, offre des solutions logistiques sur mesure pour répondre à vos besoins d'expédition, que vous soyez particulier, e-commerçant, petite entreprise ou multinationale.",
                satisfied_clients: "Des milliers de clients satisfaits",
                satisfied_clients_description: "Plusieurs d'entre eux, nous leur avons demandé à quel point ils étaient satisfaits de nos services.",
                satisfied_clients_description2: "Voici leurs déclarations",
                button_text2: "DONNER UN AVIS",
                user_name1: "Marc B. B2B",
                user_review1: "Auparavant, le suivi était compliqué avec un seul transporteur. Aujourd'hui, grâce à Eurêkali qui gère l'ensemble de nos transporteurs, tout est pris en charge de manière fluide et efficace.",
                user_name2: "Amina R. B2B",
                user_review2: "Chercher un transporteur à la hauteur de nos exigences est quasi impossible. Eurekali a réussi le pari.",
                user_name3: "Alan D. C2C",
                user_review3: "Dans une situation délicate, Eurêkali a assuré une livraison rapide et sécurisée de documents importants, me sauvant ainsi. Un service professionnel que je recommande.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Collaborant avec plusieurs transporteurs, cela implique de nombreux contacts et diverses factures. Avec Eurêkali, nous économisons un temps précieux en consolidant ces processus.",

                suivant: "Suivant",
                nom_entreprise: "Nom de l'entreprise?",
                secteur_activite: "Secteur d'activité?",
                contact_principale: "Contact principal (Nom et poste)?",
                address_email: "Adresse e-mail?",
                phone_number: "Numéro de téléphone",
                repondez_ici: "Répondez ici...",
                question1: "Quelle est la fréquence de vos besoins en livraison?",
                question1_choice1: "Quotidienne",
                question1_choice2: "Hebdomadaire",
                question1_choice3: "Mensuelle",
                question1_choice4: "Autre",
                question2: "Quel est le volume moyen de vos envois? (Nombre de colis ou poids approximatif par envoi)",
                question3: "Quelles sont les destinations principales de vos envois?",
                question3_choice1: "National",
                question3_choice2: "International",
                question3_choice3: "Les deux",
                question4: "Quel type de marchandises expédiez-vous?",
                question4_choice1: "Documents",
                question4_choice2: "Colis",
                question4_choice3: "Palettes",
                question4_choice4: "Marchandises spéciales",
                question5: "Y a-t-il des exigences particulières concernant vos marchandises? (Fragilité, température contrôlée, dangereuses, etc.)",
                question5_choice1: "Oui",
                question5_choice2: "Non",
                question6: "Quels services de livraison recherchez-vous?",
                question6_choice1: "Livraison standard",
                question6_choice2: "Express",
                question6_choice3: "Jour même",
                question7: "Avez-vous besoin de services supplémentaires? (Assurance, suivi en temps réel, livraison contre signature, etc.)",
                question7_choice1: "Oui",
                question7_choice2: "Non",
                question8: "Avez-vous déjà utilisé des services de livraison express?",
                question8_choice1: "Oui",
                question8_choice2: "Non",
                question9: "Quels sont vos critères principaux pour choisir un service de livraison? (Coût, fiabilité, vitesse, service client, etc.)",
                question10: "Avez-vous des attentes ou des préoccupations spécifiques concernant le service de livraison?",
                question11: "Quel est votre budget approximatif pour les services de livraison?",
                question12: "Quelles sont vos préférences en matière de facturation et de paiement?",
                thanks_message1: "Merci d'avoir demandé un devis.",
                thanks_message2: "Un de nos experts métiers traite votre demande. Vous serez contacté dans les plus brefs délais pour discuter de vos besoins de livraison et de nos solutions.",
                agent: "Agent",
                transporter: "Transporteur",
                why_question1: "Pourquoi",
                why_question2: "choisissent d'utiliser Eurêkali?",

                //B2B page
                b2b_title1: "Ne perdez pas votre temps à chercher un transporteur pour votre entreprise.",
                b2b_title2: "Choisissez Eurêkali pour une alliance fiable de transporteurs et une gestion logistique simplifiée.",
                b2b_title3: "Cliquez, Comparez, Envoyez et Souriez!",
                who_are_we: "Qui sommes-nous?",
                b2b_title4: "Approuvé par les Grandes Entreprises",
                b2b_title5: "Découvrez les Secrets de leur Satisfaction",
                sender: "Expéditeur",
                sender_title: "Expédition Simplifiée : Trouvez le Transporteur Idéal",
                sender_contenu: "Nos clients apprécient un accès facile à une large gamme de transporteurs via notre plateforme Eurekali. En offrant des options personnalisées pour chaque envoi et en assurant une livraison rapide, nous rendons l'expérience d'expédition sans tracas, renforçant la fidélité du client grâce à notre flexibilité et fiabilité.",
                read_more: "Lire la suite",
                mail_room: "Réceptionniste / Courrier",
                mail_room_title: "Gestion Optimale des Envois : Choix et Efficacité",
                mail_room_contenu: "La gestion des envois est rationalisée avec Eurekali. Nos réceptionnistes et le personnel de la salle du courrier utilisent notre plateforme pour choisir parmi une variété de transporteurs, répondant efficacement aux demandes spécifiques de chaque client. Cette approche personnalisée conduit à une satisfaction client accrue.",
                accountant: "Comptable",
                accountant_title: "Gestion Financière du Transport : Clarté et Flexibilité",
                accountant_contenu: "Notre plateforme Eurekali aide les services comptables à gérer efficacement les coûts de transport. Avec une visibilité claire sur différentes options de transporteurs et une facturation transparente, nous aidons les clients à maintenir un contrôle financier rigoureux, renforçant leur confiance et satisfaction.",
                ceo: "PDG",
                ceo_title: "Stratégie de Transport Innovante : Partenaire Préféré",
                ceo_contenu: "Les dirigeants trouvent en Eurekali un partenaire stratégique pour optimiser les services de transport. En offrant un large choix de transporteurs et en se concentrant sur l'efficacité opérationnelle et la gestion des coûts, notre plateforme garantit une expérience exceptionnelle, renforçant la fidélité et la satisfaction du client.",
                partners_trust_us: "Les partenaires qui ont confiance en nos services.",
                say_about_us: "Que disent nos partenaires à notre sujet?",
                b2b_title6: "Votre domaine, votre succès. Transport, notre expertise.",
                b2b_title7: "Rejoignez-nous aujourd'hui et transformez votre expérience d'expédition!",

                company_info: "Informations sur l'Entreprise",
                company_name_trans: "Nom de la Société de Transport:",
                company_address: "Adresse du Siège Social:",
                company_year: "Année de Fondation:",
                company_contact: "Nom du Contact Principal et Poste:",
                company_address_trans: "Adresse E-mail de Contact:",
                company_phone_trans: "Numéro de Téléphone de Contact:",
                company_structure: "Structure de l'Entreprise:",
                company_capacity: "Capacités et Ressources",
                vehicles_number: "Nombre de Véhicules de Transport:",
                vehicles_type: "Types de Véhicules Disponibles:",
                max_load: "Capacité de Charge Maximale par Véhicule:",
                service_zone: "Rayon Géographique de Service:",
                service_availability: "Disponibilité pour des Services Nationaux/Internationaux:",
                placeholder1: "Indépendant, Société...",
                placeholder2: "Camion, Fourgonnette, etc..",
                experience_certification: "Expérience et Certification",
                pricipe_sectors: "Principaux Secteurs Servis:",
                placeholder3: "ex. : médical, e-commerce, industriel",
                experience_transport: "Expérience dans le Transport Express ou Standard:",
                certifications: "Certifications ou Accréditations Spécifiques:",
                placeholder4: "ex. : ISO, ADR pour transport de matières dangereuses",
                references: "Références de Clients ou Partenaires Précédents:",
                services_offers: "Services et Offres Spécifiques",
                propossed_services: "Services Spéciaux Proposés:",
                placeholder5: "ex. : livraison le jour même, température contrôlée",
                flexibility: "Flexibilité et Adaptabilité des Services:",
                pricing_cost: "Tarification et Structure de Coûts:",
                other_information: "Autres Informations et Commentaires",
                why_choose_us_trans: "Pourquoi souhaitez-vous rejoindre notre regroupement de sociétés de transport?",
                what_your_expectation: "Quelles sont vos attentes en termes de collaboration?",
                thanks_transporter: "Merci d'avoir complété le formulaire. Nous avons bien reçu votre demande et l'un de nos membres d'équipe vous contactera bientôt.",

                coworking_header: "Élargissez les horizons de votre espace de coworking avec Eurêkali",
                coworking_sheader: "Expédition Simplifiée pour Toute la Communauté",
                coworking_section1_title: "Comment Eurekali peut-il améliorer l’expérience de votre espace de coworking?",
                coworking_section1_par1_title: "Faites passer votre espace de coworking au niveau supérieur",
                coworking_section1_par1_desc: "Avec Eurekali comme extension naturelle de vos services de location d'espaces de bureaux, vous offrez à votre communauté bien plus qu'un simple lieu de travail. Avec notre plateforme d'expédition d'envoi intégrée, vous pouvez offrir à vos clients la commodité de gérer leurs envois directement depuis votre espace, ajoutant ainsi une valeur inestimable à votre offre de services.",
                coworking_section1_par2_title: "Partenariat exclusif avec les leaders du transport",
                coworking_section1_par2_desc: "En choisissant Eurekali comme partenaire d'expédition, vous accédez automatiquement à notre réseau de partenaires de transport de renommée mondiale. Nos relations stratégiques avec les plus grandes sociétés de transport garantissent des solutions d'expédition fiables et efficaces pour vos clients, où qu'ils se trouvent dans le monde.",
                coworking_section2_title: "Avantages pour votre communauté de coworking",
                coworking_advn1_title: "Confort et Accessibilité",
                coworking_advn1_desc: "Offrez à vos membres la commodité d'expédier leurs colis directement depuis leur espace de travail, éliminant ainsi les tracas liés aux déplacements vers des centres d'expédition externes",
                coworking_advn2_title: "Tarifs Privilégiés",
                coworking_advn2_desc: "En tant que partenaire d'Eurekali, votre communauté bénéficie de tarifs préférentiels sur les services d'expédition, ce qui les encourage à utiliser régulièrement cette facilité.",
                coworking_advn3_title: "Suivi Simplifié",
                coworking_advn3_desc: "Grâce à notre plateforme conviviale, vos membres peuvent suivre leurs colis en temps réel, offrant une tranquillité d'esprit totale quant à la sécurité de leurs envois.",
                coworking_advn4_title: "Flexibilité et Choix",
                coworking_advn4_desc: "Qu'il s'agisse d'envois nationaux ou internationaux, de documents importants ou de colis volumineux, Eurekali offre une gamme complète de services d'expédition pour répondre à tous les besoins de votre communauté.",
                coworking_joinus_par1: "Rejoignez-nous dès maintenant!",
                coworking_joinus_par2: "Incorporez Eurekali dans votre offre de services et offrez à votre communauté une expérience de coworking inégalée.",
                coworking_joinus_par3: "Notre équipe est prête à collaborer avec vous pour faire passer votre espace de coworking au niveau supérieur.",
                coworking: "Coworking",

                contactus_header: "Contactez-nous",
                contactus_sheader1: "Bienvenue chez Eurekali, experts de la livraison express",
                contactus_sheader2: "Besoin d'aide ? Contactez-nous !",
                contactus_form_input1: "Nom",
                contactus_form_input2: "Société",
                contactus_form_input3: "Téléphone",
                contactus_form_input4: "Email",
                contactus_form_input5: "Sujet",
                contactus_form_input6: "Message",
                contactus_form_button: "Envoyer",
                contactus_text1: "Numéro de téléphone dédié, pour une assistance immédiate :",
                contactus_text2: "Notre équipe est disponible",
                contactus_text3: "Pour répondre rapidement à vos préoccupations urgentes.",
                contactus_message: "Merci, nous vous contacterons bientôt",

                about_us_title: "Historique de l’entreprise",
                about_us_sous_title: "Depuis notre création en 2013, nous avons franchi de nombreuses étapes importantes :",
                about_us_2013: "Fondation d’Eurêkali, avec une vision de révolutionner la logistique en Belgique.",
                about_us_2015: "Introduction de notre plateforme intégrée de prise de commande, suivi et facturation.",
                about_us_2018: "Expansion de notre réseau de transporteurs pour offrir plus de choix de services.",
                about_us_2020: "Lancement de notre application mobile pour une gestion logistique encore plus accessible.",
                about_us_2023: "Reconnaissance en tant que leader du marché belge et européen de la logistique.",
                about_us_2024: "Expansion stratégique de notre présence sur le marché africain.",
                mission_and_vision: "Mission et vision",
                mission: "Mission",
                mission_desc: "Offrir des solutions logistiques sur mesure pour répondre à vos besoins d’expédition dans le monde B2B, B2C et C2C, tout en assurant un service de qualité et une satisfaction totale.",
                vision: "Vision",
                vision_desc: "Devenir la référence incontournable pour les solutions logistiques en Europe et les autres continents, en innovant constamment pour répondre aux besoins évolutifs de nos clients.",
                about_eurekali: "À propos de Eurêkali",
                about_eurekali_title: "Eurêkali, votre solution intégrée depuis 2013",
                about_eurekali_title_desc: "Bienvenue chez Eurêkali, le leader de la logistique en Belgique et en Europe depuis 2013. Nous sommes une marketplace innovante réunissant les principaux acteurs de la logistique sur une seule plateforme. Notre objectif est de simplifier et optimiser vos opérations logistiques, que vous soyez particulier, e-commerçant, petite entreprise ou multinationale.",
                about_us_question: "Contactez-nous si vous avez besoin d'aide ou de réponses à vos questions",

                element_drop_contenu1: "Gagnez du temps en trouvant la livraison adaptée à vos besoins, avec la liberté de changer à tout moment. La recherche d'un transporteur, la négociation et la mise en route prennent un délai considérable, sans compter le risque d'être déçu par le service rendu. Avec notre solution, évitez ces tracas et bénéficiez d'une livraison efficace et fiable, en profitant d'un large éventail de transporteurs sélectionnés avec soin pour répondre à vos besoins.",
                element_drop_contenu2: "L'union fait la force. Mutualisant les besoins de nos clients, nous influençons les transporteurs vers des services de haute qualité. L'effet de masse nous permet d'avoir un accès direct aux opérateurs des différents transporteurs. Ils nous considèrent comme des VIP lorsque nous les contactons, que ce soit pour des demandes spécifiques ou pour la résolution de problèmes. Vous profitez ainsi pleinement de cet effet de masse.",
                element_drop_contenu3: "Regroupement des commandes pour des tarifs avantageux. Profitez de la puissance du groupe pour réduire vos dépenses. Grâce à notre volume de commandes, nous négocions des tarifs préférentiels avec les transporteurs, vous permettant ainsi de bénéficier de prix réduits sans compromis sur la qualité du service.",
                element_drop_contenu4: "Choisir parmi plus de 10 000 transporteurs en Belgique est complexe. Nous sélectionnons les meilleurs pour vous, en tenant compte de vos besoins spécifiques. Avec notre service flexible, vous avez accès à une vaste gamme d'options et la liberté de changer de transporteur à tout moment, garantissant ainsi une solution sur mesure et sans souci.",

                give_review: "Donner un avis",
                write_review: "Écrire un avis",
                reviews: "Avis",
                stars: "Étoiles",
                clients_reviews_text1: "Les avis de nos clients sur Eurêkali",
                clients_reviews_text2: "Confiance et satisfaction !",

                give_review_alert1: "Veuillez entrer votre nom",
                give_review_alert2: "Veuillez choisir au moins une étoile",

                contact_us_alert1: "Veuillez entrer le nom",
                contact_us_alert2: "Veuillez entrer la société",
                contact_us_alert3: "Veuillez entrer le numéro de téléphone",
                contact_us_alert4: "Veuillez entrer l'adresse e-mail",
                contact_us_alert5: "Veuillez entrer le sujet",
                contact_us_alert6: "Veuillez entrer le message",

                transporters_title: "Bienvenue sur notre plateforme dédiée aux transporteurs!",
                transporters_sub_title: "Que vous soyez spécialisé dans le transport de marchandises, de personnes, ou dans des services de livraison, notre marketplace est faite pour vous. En rejoignant notre réseau, vous accédez à une vaste base de clients à la recherche de vos services.",
                transporters_partners: "Nos partenariats de confiance :",
                join_our_marketplace: "Rejoignez Notre Marketplace des Transporteurs",
                new_opport: "Profitez de Nouvelles Opportunités et Développez Votre Activité",
                opp1_title: "1 - Visibilité Accrue :",
                opp1_contenu: "Augmentez votre visibilité en ligne et atteignez plus de clients potentiels.",
                opp2_title: "2 - Gestion Simplifiée :",
                opp2_contenu: "Utilisez notre interface intuitive pour gérer vos réservations et votre planning.",
                opp3_title: "3 - Soutien et Assistance :",
                opp3_contenu: "Bénéficiez de notre support technique et commercial pour optimiser votre activité.",
                transporter_join_now: "Inscrivez-vous dès maintenant et commencez à recevoir des demandes de transport !",

                faqs_title: "Questions fréquemment posées",
                faq_question1: "Comment et quand vais-je recevoir mes documents d'expédition ?",
                faq_answer1: "Une fois la commande validée et payée sur notre site www.eurekali.com, les documents d'expédition nécessaires seront disponibles en téléchargement directement en format PDF depuis votre e-mail de confirmation de commande. Cela inclut un bon de livraison pour les expéditions européennes, et pour les expéditions internationales hors UE avec une valeur commerciale, un bon de livraison et une facture pro forma. De plus, un certificat d'assurance Ad Valorem peut être acheté s'il est sélectionné lors du processus de commande. Vous pouvez également télécharger la facture de transport en format PDF depuis votre e-mail de confirmation de commande. Enfin, si vous avez sélectionné les options appropriées, des notifications seront envoyées par e-mail ou par SMS à la personne désignée dans la section adresse de destination.",
                faq_question2: "Pourquoi vos tarifs sont-ils plus bas que sur les sites des transporteurs ?",
                faq_answer2: "Eurêkali peut offrir à ses clients de meilleures offres car elle regroupe les flux de transport de ses transporteurs. Et plus nous expédions, meilleurs seront les tarifs que nous offrirons.",
                faq_question3: "Mon colis n'a pas été expédié, mon colis est endommagé, comment puis-je faire une réclamation ?",
                faq_answer3: "Si le colis n'a pas été expédié, vous devez contacter le numéro de téléphone indiqué dans l'e-mail de confirmation de commande dès que possible, expliquer la situation et attendre un retour. Si la réponse est insatisfaisante, remplissez le formulaire de réclamation en cliquant ici. Nous ouvrirons une enquête avec le transporteur que vous avez sélectionné pour nous assurer que toutes les actions nécessaires sont prises pour vous satisfaire. Par précaution, envoyez une lettre recommandée avec votre réclamation au transporteur le 15ème jour après la date de livraison initialement prévue, en joignant à votre lettre de réclamation la facture originale des biens expédiés ou leur facture de remplacement ou de réparation. En cas de livraison partielle ou de dommage au colis, contrôlez le nombre d'articles dans le colis et à l'intérieur de l'emballage tout en étant devant le livreur, et signez le bon de livraison uniquement après avoir décrit tout dommage ou perte éventuelle d'articles. Contactez le numéro de téléphone indiqué dans l'e-mail de confirmation de commande dès que possible, expliquez la situation et attendez un retour. Si la réponse est insatisfaisante, remplissez le formulaire de réclamation en cliquant ici. Envoyez une lettre recommandée au transporteur dans les 48 heures suivant la livraison, en joignant à votre lettre de réclamation la facture originale des biens expédiés ou leur facture de remplacement ou de réparation. Par précaution, conservez le colis et son emballage.",
                faq_question4: "Comment puis-je demander une indemnisation en cas de retard de livraison de mon envoi ?",
                faq_answer4: "Par précaution, envoyez une lettre recommandée au transporteur. L'indemnisation s'applique exclusivement au coût du transport et ne couvre pas le coût des marchandises. Nous vous conseillons de vous référer aux conditions générales de vente du transporteur choisi pour connaître les exigences précises pour réclamer une indemnisation pour tout retard encouru.",
                faq_question5: "Pouvons-nous déménager dans notre nouvelle maison avec Eurêkali ?",
                faq_answer5: "Vous pouvez déménager dans un nouveau lieu avec Eurêkali, à condition que l'expédition soit correctement documentée avec le nombre exact d'articles prêts à être collectés par le déménageur.",
                faq_question6: "Comment puis-je commander plusieurs expéditions simultanément ?",
                faq_answer6: "Si vous devez envoyer plusieurs colis à la même destination, vous pouvez regrouper vos colis. Sur la page de démarrage, vous devrez simplement créer les lignes supplémentaires nécessaires. Si les adresses de destination de vos colis sont différentes, vous devrez passer des commandes distinctes.",
                faq_question7: "Est-il préférable d'envoyer un gros colis ou plusieurs petits ?",
                faq_answer7: "Il est toujours préférable de laisser les transporteurs s'occuper de plusieurs petits colis de poids moyen, plutôt que d'un gros colis dont la manipulation nécessiterait deux personnes ou l'utilisation d'un chariot. Le prix est souvent équivalent mais le risque de dommage aux colis est moindre si vous les divisez, car ils sont plus faciles à manipuler.",
                faq_question8: "Quelles sont les trois choses à faire au moment du ramassage et de la livraison avant de remettre un colis à un transporteur ou de le récupérer de celui-ci ?",
                faq_answer8: "Vérifiez que le poids et les dimensions du colis sont corrects. Si des informations sont incorrectes, l'expédition pourrait être bloquée dans l'entrepôt du transporteur, et son rappel sera à votre charge sans aucune indemnisation pour le service payé. Vérifiez que le contenu de votre colis est autorisé à l'expédition et bien emballé. Le contenu ne doit pas être interdit ; consultez la liste 'Que ne puis-je pas envoyer ?' ainsi que les conditions générales d'Eurêkali et du transporteur. Le colis doit être fermement emballé pour garantir que sa manipulation ne causera pas de dommages au contenu. Si l'expéditeur ne respecte pas les instructions d'emballage, le colis sera bloqué dans l'entrepôt du transporteur, et son rappel sera à votre charge sans aucune indemnisation pour le service payé. Vérifiez que les adresses de ramassage et de destination sont complètes et correctes. Elles doivent être communiquées de manière exhaustive. Si le transporteur ne peut pas accéder facilement aux adresses mentionnées, il peut considérer que sa mission de livraison a été accomplie, et votre colis sera conservé dans un entrepôt avec le rappel facturé à l'expéditeur. Les retards encourus en raison d'une personne manquante à la destination ou d'une adresse incomplète ne seront pas considérés comme la responsabilité du transporteur. Pour éviter tout retard ou toute livraison manquée, l'utilisateur doit indiquer les numéros de téléphone des personnes de contact pour le ramassage et la destination et communiquer toute information utile pour la collecte ou la livraison.",
                faq_question9: "Quels sont les modes de paiement acceptés ?",
                faq_answer9: "Vous pouvez payer vos expéditions avec VISA ou MasterCard via le système de paiement en ligne Ogone. Si vous créez un compte utilisateur, vos données de facturation seront enregistrées et vous pourrez utiliser le paiement par prélèvement automatique. En tant qu'entreprise, vous pouvez avoir accès à un système de facturation mensuelle entièrement intégré qui se connecte à votre propre système de paiement et à vos comptes, à condition que notre service financier approuve votre demande.",
                faq_question10: "Avec quels transporteurs Eurêkali travaille-t-elle ?",
                faq_answer10: "Nous attachons une importance primordiale à la satisfaction de nos clients. Dans cet objectif, nous avons sélectionné des transporteurs renommés pour la qualité de leur service : TNT, GLS, IPS, DHL, FEDEX, Alpha, PMC, Number One, Ecopostale. La liste est constamment mise à jour. Le service de contrôle qualité d'Eurêkali analyse soigneusement toute nouvelle possibilité de collaboration avec des entreprises d'autres secteurs, afin de vous offrir des conditions comparatives dans autant de domaines d'expertise spécialisés que possible."











            }
        }
    }
}
